import { Box, IconButton, MenuItem, Select, TextField, Tooltip, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import axios from '../../../../common/AxiosConfig';
import { AppContext } from '../../../../../RoutesWithAuth';
import ListOfItems from '../ListOfItems';
import { Clear, Edit, Save } from '@material-ui/icons';
import ResponseJsonContent from './ResponseJsonContent';
import GuidDetails from './GuidDetails';
import { getFormatedDate } from '../../../../common/commons';
import { RESPONSE_ACTION_MAPPING } from '../applications/constants';
import { APPIDS } from '../../../../common/constants';
import Attachments from './attachments';
import { useRef } from 'react';


const useStyles = makeStyles(() => ({
    root: {
        '& .MuiButtonBase-root': {
            padding: '5px !important'
        },
        '& .customizeIconButton': {
            width: '27px',
            height: '27px'
        },
        '& .customLink': {
            textDecoration: "underline",
            // color : "#007bff"
        },
        '& .customselectInput .MuiSelect-root': {
            paddingTop: '10.5px',
            paddingBottom: '10.5px'

        }
    },
    "attachmentsWrapper": {
        '& .header': {
            background: '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        },
    }
}));


const ViewResponse = ({ tabInfo, setDisplayErrMsg, setLoading, reloadInformation, requestStatus }) => {
    const context = useContext(AppContext);
    const authToken = context.authToken.get;
    const classes = useStyles();
    const attachmentRef = useRef();
    const jsonContentRef = useRef([]);

    const [responseData, setResponseData] = useState({});
    const [guidDetails, setGuidDetails] = useState([]);
    const [displayAttachments, setDisplayAttachments] = useState(false);
    const [editMode, setEditMode] = useState(false);
    
    useEffect(() => {
        fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[tabInfo.tabtype, tabInfo.app_id, requestStatus]);

    const fetchTicketResponseDetails = () => {
        return axios.get(`ticket/response/${tabInfo.dsarRequestId}/${tabInfo.app_id}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            const responseData = res.data;
            const expectedAttachments = responseData["expected_attachments"].toString();
            const existingAttachments = responseData["existing_attachments"];
            const pendingAttachments = expectedAttachments - existingAttachments;
            responseData["expected_attachments_count"] = Number(expectedAttachments).toString();
            responseData["existing_attachments_count"] = Number(existingAttachments).toString();
            responseData["pending_attachments_count"] = pendingAttachments >= 0 ? Number(pendingAttachments).toString() : 0;
            responseData["attachements_formated"] = responseData["attachment_names"] || [];
            responseData["attachements_formated"] = responseData["attachements_formated"].sort((a, b) => a.attachment_type.localeCompare(b.attachment_type) || a.filename.localeCompare(b.filename));
            responseData["attachements_formated"] = responseData["attachements_formated"]?.map((attachment, index) => {
                return {
                    ...attachment,
                    id: index + 1,
                    appId: tabInfo.app_id,
                    dsarRequestId: tabInfo.dsarRequestId
                }
            })
            responseData["ticketStatus"] = responseData["status"];
            responseData["status"] = responseData["response_is_open"] ? "Open" : "Closed";
            setResponseData(responseData);

        }).catch(err => {
            if (err?.response?.data?.message) {
                setDisplayErrMsg(err.response.data.message)
            }
        })
    }
    const fetchGuidDetails = () => {
        return axios.get(`ticket/${tabInfo.dsarRequestId}/guids`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            let formatedGuidDetails = res.data;
            formatedGuidDetails = formatedGuidDetails.map((formatedGuidDetail) => {
                return {
                    created_date_local: getFormatedDate(formatedGuidDetail.created_date),
                    created_date_utc: getFormatedDate(formatedGuidDetail.created_date, true),
                    updated_date_utc: getFormatedDate(formatedGuidDetail.updated_date, true),
                    updated_date_local: getFormatedDate(formatedGuidDetail.updated_date),
                    response_json: formatedGuidDetail.json_response,
                    ...formatedGuidDetail
                }
            })
            setGuidDetails(formatedGuidDetails);
        }).catch(err => {
            if (err?.response?.status === 404) {
                setGuidDetails("no guids")
            }
        })
    }
    const fetchData = () => {
        setLoading(true);
        const allRequests = [];
        allRequests.push(fetchTicketResponseDetails());
        if (tabInfo.app_id === APPIDS.APP_ID_ZUORA) {
            allRequests.push(fetchGuidDetails());
        }
        Promise.all(allRequests).then(() => {
            setLoading(false);
        }).catch(() => {
            setLoading(false);
        })

    };
    const getEditedGuidJson = ()=>{
        return guidDetails.map((guidDetail)=>{
            return {
                guid:guidDetail.guid,
                edit_response_json : jsonContentRef?.current?.[guidDetail.guid]?.getJsonValue()
            }
        })
    }

    const getEditedJson = ()=>{
        if (tabInfo.app_id === APPIDS.APP_ID_ZUORA) {
            return getEditedGuidJson();
        }
        const jsonContentRefValue = jsonContentRef?.current;
        let jsonValue;
        if(jsonContentRefValue){
            jsonValue = jsonContentRefValue.getJsonValue();
        }
        return jsonValue;
    }

    return (<Box className={`${classes.root} requestDetailsWrapper`} width={"100%"} height={"100%"} >
        <ListOfItems
            enableEdit={editMode}
            items={[{
                display: "Ticket Status",
                mapping: "status"
            }, {
                display: "Response Action",
                mapping: "response_action",
                optionset: RESPONSE_ACTION_MAPPING,
                editable: true,
                renderEditField: () => {
                    return <>
                        <Select
                            variant="outlined"
                            size={"small"}
                            className='customselectInput'
                            id={"response_action"}
                            multiple={false}
                            fullWidth
                            onChange={(event) => {
                                responseData["response_action_edit"] = event.target.value;
                                setResponseData({
                                    ...responseData,
                                    edit_response_json : getEditedJson()
                                });
                            }}
                            value={(responseData["response_action_edit"] || responseData["response_action"])}
                        >
                            {Object.keys(RESPONSE_ACTION_MAPPING).map((_option) => (
                                <MenuItem key={_option} value={_option}>
                                    {RESPONSE_ACTION_MAPPING[_option]}
                                </MenuItem>
                            ))}
                        </Select>
                    </>
                }
            }, {
                display: "Expected Attachments",
                mapping: "expected_attachments_count",
                editable: true,
                renderEditField: () => {
                    return <>
                        <TextField
                            size={"small"}
                            type={"number"}
                            onChange={(event) => {
                                let count = parseInt(event.target.value);
                                if (count > 99) {
                                    count = 99;
                                } else if (count < 0) {
                                    count = 0;
                                } else if (isNaN(count)) {
                                    count = 0;
                                }
                                responseData["expected_attachments_count_edit"] = count;
                                setResponseData({
                                    ...responseData,
                                    edit_response_json : getEditedJson()
                                });
                            }}
                            value={(responseData["expected_attachments_count_edit"] || responseData["expected_attachments_count"])}
                            fullWidth
                            id={"expected_attachments_count"}
                            label=""
                            variant="outlined"

                        />
                    </>
                }
            }, {
                display: "Received Attachments",
                mapping: "existing_attachments_count"
            }, {
                display: "Pending Attachments",
                mapping: "pending_attachments_count"
            }, {
                display: "File Upload",
                type: "custom",
                renderCustom: () => {
                    return (
                        <a
                            href="void(0)"
                            className='customLink'
                            data-testid={"fileUploadLink"}
                            onClick={(event) => {
                                event.preventDefault();
                                setDisplayAttachments(!displayAttachments);
                            }}
                        >
                            View / Upload Document(s)
                        </a>
                    )
                }
            }, {
                display: "Edit Response",
                type: "custom",
                renderCustom: () => {
                    if(editMode) {
                        return (
                            <>
                                <IconButton
                                    onClick={() => {
                                        const attachmenntComponent = attachmentRef.current;
                                        let jsonValue = getEditedJson();
                                        const jsonEditEnabled = editMode;
                                        attachmenntComponent.submitAttachments(jsonValue , jsonEditEnabled);
                                    }}
                                >
                                    <Tooltip title={"Save"}>
                                        <Save fontSize="small" />
                                    </Tooltip>
                                </IconButton>
                                <IconButton
                                    onClick={() => {
                                        attachmentRef.current.clearAttachments();
                                    }}
                                >
                                    <Tooltip title={"Clear"}>
                                        <Clear fontSize="small" color="error" />
                                    </Tooltip>
                                </IconButton>
                            </>
                        )
                    }
                    return (
                        <IconButton
                            label={"edit"}
                            onClick={() => {
                                setDisplayAttachments(true);
                                setEditMode(true);
                            }}
                            disabled={responseData.ticketStatus === "Uploaded" || editMode}
                        >
                            <Tooltip title={"Edit"}>
                                <Edit />
                            </Tooltip>
                        </IconButton>)
                }
            }]}
            data={responseData}
        />
        <Box>
            <Attachments
                ref={attachmentRef}
                displayAttachments={displayAttachments}      
                attachments={responseData.attachements_formated || []}
                setLoading={setLoading}
                setDisplayAttachments={setDisplayAttachments}
                editEnabled={editMode}
                responseData={responseData}
                tabInfo={tabInfo}
                setDisplayErrMsg={setDisplayErrMsg}
                handleAttachmentSuccess={() => {
                    setEditMode(false);
                    fetchData();
                    reloadInformation()
                }}
                handleAttachmentsReset={() => {
                    if ("response_action_edit" in responseData) {
                        delete responseData["response_action_edit"];
                    }
                    if ("expected_attachments_count_edit" in responseData) {
                        delete responseData["expected_attachments_count_edit"];
                    }
                    if("edit_response_json" in responseData){
                        delete responseData["edit_response_json"];
                    }
                    setResponseData({
                        ...responseData
                    })
                }}
            />
        </Box>
        {tabInfo.app_id === APPIDS.APP_ID_ZUORA ? (
            <GuidDetails
                guidDetails={guidDetails}
                responseData={responseData}
                isEditEnabled={editMode}
                tabInfo={tabInfo}
                jsonContentRef={jsonContentRef}
            />
        ) : (
            <Box borderRight={"1px solid rgb(0 0 0 / 8%)"} borderLeft={"1px solid rgb(0 0 0 / 8%)"} paddingBottom={"5px"}>
                <ResponseJsonContent
                    ref={jsonContentRef}
                    isEditEnabled={editMode}
                    responseData={responseData}
                    tabInfo={tabInfo}
                />
            </Box>
        )}

    </Box>)
}
export default React.memo(ViewResponse);

