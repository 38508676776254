import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import { CSSTransition } from 'react-transition-group';
import { withNavbar } from '../../common/Navbar';
import PageHeader from '../../common/PageHeader';
import { removeFeedback, feedbackTimeout } from '../../common/commons';
import axios from '../../common/AxiosConfig';
import UserRow from './UserRow';


function Users() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get

    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState("All");

    useEffect(() => {
        if (authToken !== null && isAdmin === true) {
            axios.get('users', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setUsers(res.data)
                setFilteredUsers(res.data);
            }).catch(err => {
                console.log('There was an error fetching users!', err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken, isAdmin])

    const setDisabledStatus = (e) => {
        const confirmBtn = document.getElementById("setDisabledBtn")
        const feedback = document.getElementById("changeStatusFeedback")

        axios.post('users/set_disabled', {
            is_disabled: e.target.getAttribute('isDisabled') === "false" ? false : true,
            user_id: e.target.getAttribute('userId'),
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Fetch the user data again after it has been updated
            axios.get('users', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setUsers(res.data);
                setFilteredUsers(res.data);
            }).catch(err => {
                console.log('There was an error fetching users!', err)
            })

            // Hide the modal
            hideModal()
        }).catch(err => {
            console.log(err)
            feedback.innerHTML = err.response.data.message
            setTimeout(() => {
                hideModal()
            }, feedbackTimeout)
        }).finally(() => {
            // Reset the submit attributes
            confirmBtn.setAttribute("isDisabled", null)
            confirmBtn.setAttribute("userId", null)
            removeFeedback("changeStatusFeedback")
        })
    }

    const hideModal = () => {
        document.getElementById('confirm-change-user-status').classList.remove('show')
        const backdrop = document.getElementsByClassName('modal-backdrop')
        if (backdrop[0]) {
            document.body.removeChild(backdrop[0])
        }
    }

    const handleChange=(e)=> {
        if(!e.target.value){
            return void 0;
        }
        const filter = e.target.value;
        setSelectedFilter(filter);
        if(filter==='All'){
            setFilteredUsers(users);
        }
        if(filter==='Disabled'){
            const newUsers = users.filter((p)=>!p.user_status || p.user_status === 'N');
            setFilteredUsers(newUsers);
        }
        else if(filter === 'Admin' || filter ==='User'){
            const newUsers = users.filter((p)=>p.role === filter && p.user_status === 'Y');
            setFilteredUsers(newUsers);
        }
      }


    return (
        <div className="container mb-5">

            <PageHeader header="Users" newUIHref={"/admin/systemsettings/users"}/>
            <div className="mb-3">
                <div className="form-inline form-check-inline">
                    <label className="small mr-1" htmlFor="keyFilter">Filter:</label>
                    <select className="form-control form-control-sm" id="keyFilter" onChange={handleChange}>
                        <option value='All'>All</option>
                        <option value='Admin'>Active - Admins</option>
                        <option value='User'>Active - Users</option>
                        <option value='Disabled'>Disabled</option>
                    </select>
                    <div style={{ flex:1,display: 'flex',justifyContent: 'end' }}>
                        <a href={`${process.env.REACT_APP_SAIL_POINT_URL}`} rel="noopener noreferrer" className="btn btn-sm btn-success mr-2" target="_blank" >
						    Launch SailPoint
						</a>
                    </div>
                </div>  
            </div>
            <CSSTransition in={filteredUsers.length > 0} appear={true} timeout={125} classNames="swipeR">
                <div>
                    {filteredUsers.map((user, i) => (
                        <UserRow key={user?.user_id} user={user} 
                        selectedFilter={selectedFilter}
                        isAdmin={isAdmin} 
                        authToken={authToken} 
                        setFilteredUsers={setFilteredUsers} 
                        filteredUsers={filteredUsers}
                        users={users}
                        setUsers={setUsers}/>
                    ))}
                </div>
            </CSSTransition>

            {/* Modal */}
            <button className="d-none" id="triggerModal" data-toggle="modal" data-target="#confirm-change-user-status"></button>
            <div className="modal fade" id="confirm-change-user-status" tabIndex="-1" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Change User Status</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" id="disable-user-text">
                            Are you sure you want to change this user's disabled status?
                        </div>
                        <div className="modal-footer pb-0">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" id="setDisabledBtn" onClick={setDisabledStatus}>Confirm</button>
                        </div>
                        <small className="text-right px-3 py-2 text-danger" id="changeStatusFeedback"></small>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withNavbar(Users, 4);
