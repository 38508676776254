import React, { useState, useContext, useEffect, useRef } from 'react';
import { AppContext } from '../../../../../RoutesWithAuth';
import axios from '../../../../common/AxiosConfig';
import DataGrid from '../../grid';
import DeleteDialog from './DeleteDialog';
import { makeStyles } from "@material-ui/core/styles";
import RowEditActions from '../../grid/RowEditActions';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import { GridEditInputCell } from '../../grid/EditInputCell';
import { TextField } from '@material-ui/core';
import { LoadingIndicator } from '../../reports/reconcilationreport/MuiStyled';


const COLUMNS = [{
    name: "#",
    mapping: "rownum",
    width: 40,
    disableColumnMenu: true
}, {
    name: "VIN",
    mapping: "vin",
    flex: 1,
    onlyEditForNew: true,
    editable: true,
    disableColumnMenu: true,
    renderEditCell: (params) => {
        return <GridEditInputCell {...params} />
    }
},
{
    type: 'date',
    name: "Start Date",
    mapping: "start_date",
    editable: true,
    disableColumnMenu: true,
    renderCell: (params) => (
        <span>
            {params.row["start_date_local"]}
        </span>
    ),
}, {
    type: 'date',
    name: "End Date",
    mapping: "end_date",
    editable: true,
    disableColumnMenu: true,
    renderCell: (params) => (
        <span>
            {params.row["end_date_local"]}
        </span>
    ),
}, {
    name: "Is Active",
    mapping: "is_active",
    editable: true,
    type: 'singleSelect',
    valueOptions: ['True', 'False']
}, {
    name: "Requested By",
    mapping: "requested_by",
    editable: true,
    disableColumnMenu: true,
    renderEditCell: (params) => {
        return <GridEditInputCell {...params} />
    }
}, {
    name: "Requested By Email",
    mapping: "requested_by_email",
    editable: true,
    disableColumnMenu: true,
    renderEditCell: (params) => {
        return <GridEditInputCell {...params} />
    }
}, {
    name: "Created By",
    mapping: "created_by",
    disableColumnMenu: true
}, {
    type: 'date',
    name: "Updated On",
    mapping: "updated_at",
    disableColumnMenu: true,
    renderCell: (params) => (
        <Tooltip title={params.row["updated_at_local"] || ""}>
            <span>
                {params.row["updated_at_utc"] || ""}
            </span>
        </Tooltip>
    ),
}, {
    name: "Edit",
    mapping: "actions",
    width: 80,
    disableColumnMenu: true,
    adminEnabled: true,
    editable: false,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => {
        return <RowEditActions
            {...params}
            customValidation={(row) => {
                // Adding the Custom Date Validation to verify the Start Date and End Date
                return dateValidation(row?.start_date?.value, row?.end_date?.value);
            }}
            validationColumns={[
                "vin",
                "requested_by",
                "requested_by_email"
            ]} />
    }
}, {
    name: "Delete",
    mapping: "actions_delete",
    adminEnabled: true,
    width: 80,
    disableColumnMenu: true,
    editable: false,
    align: 'center',
    headerAlign: 'center',
}];
/**
 * Validates the Start Date and the End Date 
 * @param {Start Date} r_start_date 
 * @param {End Date} r_end_date 
 * @returns Boolean
 */
const dateValidation = (r_start_date, r_end_date) => {
    const is_valid = true;
    if (!r_start_date && !r_end_date) {
        return false
    }
    if (r_start_date && r_end_date) {
        const start_date = moment(r_start_date);
        const end_date = moment(r_end_date);
        // return false when the start date is greater than the End Date
        if (start_date.diff(end_date, 'days') > 0) {
            return false;
        }
    }
    return is_valid;
}
const useStyles = makeStyles(() => ({
    contactsWrapper: {
        marginTop: '4px',
        marginRight: '15px',
        height: 'calc( 100% - 100px )'
    },
    deleteDialogContext: {
        width: '300px',
        height: '90px',
        '& .errorMessage': {
            fontSize: '11px',
            color: 'red'
        },
        '& .MuiFormControl-root': {
            height: '100%',
            width: '100%',
        },
        '& .MuiInputLabel-root': {
            marginLeft: '10px',
            color: 'black',
            '& .MuiFormLabel-asterisk': {
                float: 'left',
                paddingRight: '2px',
                color: 'red'
            }
        },
        '& .MuiInputBase-root': {
            border: '1px solid #95a3bd',
            borderRadius: '10px',
            marginTop: '21px',
        },
        '& .MuiInput-underline:before,.MuiInput-underline:after,.MuiInput-underline:hover:not(.Mui-disabled):before': {
            border: '0px'
        }
    }
}));
function Litigation(props) {

    const [appsData, setAppsData] = useState([]);
    const [editRowsModel, setEditRowsModel] = useState({});
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [dialogErrorStatus, setDialogErrorStatus] = useState(false);
    const [deleteDialogRowInfo, setDeleteDialogRowInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get
    const userId = context.userId.get

    const classes = useStyles()
    const deleteTextInputRef = useRef();

    useEffect(() => {

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])

    const handleDeleteRow = (delete_id, rowInfo) => {
        setDeleteDialogRowInfo(rowInfo)
        setDialogErrorStatus(false);
        setOpenDeleteDialog(true);
    }
    const handleDeleteDialogClose = () => {
        setOpenDeleteDialog(false);
    }
    const handleDeleteDialogSubmit = () => {
        if (deleteTextInputRef.current.value.length < 25) {
            setDialogErrorStatus(true);
            return;
        }
        setDialogErrorStatus(false);
        const { vin } = deleteDialogRowInfo;
        setIsLoading(true);

        axios.delete(`/litigation_vins/${vin}`, {
            headers: {
                Authorization: authToken
            },
            data: {
                comments: deleteTextInputRef.current.value.trim()
            }
        }).then(res => {
            setIsLoading(false);

            setOpenDeleteDialog(false);
            fetchData();
        }).catch(err => {
            setIsLoading(false);

            console.log(err)
        })
    }
    const fetchData = () => {
        setIsLoading(true);
        axios.get(`/litigation_vins`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setAppsData(res.data);
            setIsLoading(false);
        }).catch(err => {
            console.log(err);
            setIsLoading(false);
        })
    }
    const createNewVin = (rowInfo, handleCommitCallBack, callbackRowId) => {
        setIsLoading(true);

        axios.post(`/litigation_vins`, {
            vin: rowInfo.vin.trim(),
            start_date: rowInfo.start_date || "",
            end_date: rowInfo.end_date || "",
            is_active: rowInfo.is_active === "True",
            requested_by: rowInfo.requested_by,
            requested_by_email: rowInfo.requested_by_email,
            userId: userId,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setIsLoading(false);

            fetchData();
        }).catch(err => {
            setIsLoading(false);
            if (err.response) {
                setAppsData(appsData.map((appsDat) => {
                    if (appsDat.id === callbackRowId) {
                        return {
                            ...rowInfo,
                            is_active: rowInfo.is_active === "True",
                            updated_at: '',
                        }
                    }
                    return {
                        ...appsDat
                    };
                }))
                setTimeout(() => {
                    handleCommitCallBack(callbackRowId);
                }, 300);
                props.toggleError(err.response.data.message);
            }
        })
    }
    const updateVin = (rowInfo) => {
        setIsLoading(true);
        axios.put(`/litigation_vins/${rowInfo.vin}`, {
            start_date: rowInfo.start_date || "",
            end_date: rowInfo.end_date || "",
            is_active: rowInfo.is_active === "True",
            requested_by: rowInfo.requested_by,
            requested_by_email: rowInfo.requested_by_email,
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setIsLoading(false);
            fetchData();
        }).catch(err => {
            setIsLoading(false);
            if (err.response) {
                props.toggleError(err.response.data.message);
            }
        })
    }
    const updateApp = (rowInfo, { handleCommitCallBack }, callbackRowId) => {
        if (rowInfo.isNewRecord) {
            createNewVin(rowInfo, handleCommitCallBack, callbackRowId)
        } else {
            updateVin(rowInfo)
        }
    }

    const dataGridColumns = () => {
        return COLUMNS.map((column) => {
            if (column.mapping === "actions_delete") {
                column.renderCell = (params) => {
                    return <RowEditActions {...params} deleteActionCallBack={handleDeleteRow} enableDeleteAction={true} />
                }
            }
            return column
        });
    }
    const timeFormater = (date, time_key, local_f = "MMM Do, YYYY HH:mm:ss [(local)]", default_df = "MM-DD-YYYY") => {
        return {
            [time_key]: date ? moment(date).format(default_df) : '',
            [`${time_key}_local`]: date ? moment(date).local().format(local_f) : '',
            [`${time_key}_utc`]: date ? moment(date).utc().format("MMM Do, YYYY HH:mm:ss (UTC)") : '',
        }
    }
    const appsGridData = () => {

        return appsData?.map((row, i) => {
            return {
                rownum: (i + 1),
                id: row.id || row.vin,
                ...row,
                ...timeFormater(row.start_date, 'start_date', "MMM Do, YYYY", "YYYY-MM-DD"),
                ...timeFormater(row.end_date, 'end_date', 'MMM Do, YYYY', "YYYY-MM-DD"),
                ...timeFormater(row.updated_at, 'updated_at'),
                is_active: row.is_active ? 'True' : 'False'
            }
        })
    }
    const handleRowsModelChange = React.useCallback((newModel) => {
        const editModel = Object.keys(newModel);
        let isNewRecord = false, recordId;
        editModel.forEach((model) => {
            isNewRecord = model.startsWith('editNewRecord-')
            recordId = model;
            appsData.forEach((appDat) => {
                if (appDat.id === recordId) {
                    isNewRecord = false;
                }
            })
        });
        if (isNewRecord) {
            setAppsData([
                {
                    id: recordId,
                    isNewRecord: true,
                    is_active: false
                },
                ...appsData,
            ])
        }
        const updatedModel = { ...newModel }
        setEditRowsModel(updatedModel)
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[appsData]);
    const updateDeleteApp = ({ id }) => {
        const newAppsData = [];
        appsData.forEach(appsDat => {
            appsDat.id !== id && newAppsData.push(appsDat)
        });
        setAppsData(newAppsData)
    }
    return (
        <div className={classes.contactsWrapper}>
            {isLoading && (<LoadingIndicator />)}
            <DataGrid
                enableCustomFiltering={true}
                disableSelectionOnClick={false}
                columns={dataGridColumns()}
                customHeight={"100%"}
                rows={appsGridData()}
                editRowsModel={editRowsModel}
                toolbarConfig={{
                    addIconLabel: "Add VIN",
                    isAddIconDisabled: !isAdmin
                }}
                onEditRowsModelChange={handleRowsModelChange}
                isCellEditable={(cellParams) => {

                    if (cellParams.colDef.onlyEditForNew) {
                        return cellParams.row.isNewRecord || false
                    }
                    return cellParams.colDef.editable;
                }}
                onRowEditCommit={(rows, event) => {

                    if (event.action === "rowDelete") {
                        handleDeleteRow(event._id);
                    } else if (event.action === "delete") {
                        updateDeleteApp(event.newRecord)
                    }
                    if (event.rowInfo) {
                        updateApp(event.rowInfo, event, event.rowId);
                    }
                    if (event.invalidRowInfo) {
                        let errorMsg = "All fields are required!";
                        if (!event.invalidRowInfo.start_date && !event.invalidRowInfo.end_date) {
                            errorMsg = "Start Date or End Date is required!"
                        } else if (!dateValidation(event.invalidRowInfo.start_date, event.invalidRowInfo.end_date)) {
                            errorMsg = "Start Date cannot be beyond the End Date"
                        }
                        props.toggleError(errorMsg);

                    }
                }}
            />
            <DeleteDialog
                openDeleteDialog={openDeleteDialog}
                handleDeleteDialogClose={handleDeleteDialogClose}
                title={"Delete VIN Confirmation"}
                handleDeleteDialogSubmit={handleDeleteDialogSubmit}
            >
                <div className={classes.deleteDialogContext}>
                    <TextField
                        required
                        id="standard-multiline-static"
                        label="Please enter valid comments"
                        multiline
                        InputLabelProps={{ shrink: true }}
                        inputProps={{ ref: deleteTextInputRef }}
                        rows={4}
                        defaultValue=""

                    />
                    {dialogErrorStatus && (<div className="errorMessage">{"Message should be at least 25 chars"}</div>)}
                </div>
            </DeleteDialog>
        </div>
    )
}
export default Litigation;