import React from 'react';
import { LoadingIndicator } from '../reports/reconcilationreport/MuiStyled';
import { useState, useContext, useEffect } from 'react';
import axios from '../../../common/AxiosConfig';
import { AppContext } from '../../../../RoutesWithAuth';
import { IconButton, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
const useStyles = makeStyles(() => ({
    headerList: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '20px',
        marginBottom: '15px',

    },
}))
const ListOfFields = [{
    display: 'Selected Date',
    mapping: 'selectedDate'
}, {
    display: "Request File Type",
    mapping: "requestfiletype"
}, {
    display: "Request File Name",
    mapping: "requestfilename",
    isCheck: false
}, {
    display: "Request Check File Name",
    mapping: "requestcheckfile",
    isCheck: true
}]
const ViewFile = ({ documentInfo, is_check, displayErrorMsg }) => {
    const [isInProgress, setIsInProgress] = useState(false);
    const [viewRequestResponse, setViewRequestResponse] = useState({});

    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles();

    useEffect(() => {
        fetchData();
      
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[]);


    const fetchData = () => {
        setIsInProgress(true);
        let apiUrl = "";
        let params = {
            date: documentInfo.selectedDate //moment(documentInfo.selectedDate).format("YYYY-MM-DD")
        }
        if (is_check) {
            apiUrl = `apps/daily-feed/request-check-file`;
        } else {
            apiUrl = `/apps/daily-feed/request-file/${documentInfo.appid}`;
        }
        if (documentInfo.rtd === true) {
            params.rtd = true;
        }

        axios.get(apiUrl, {
            headers: {
                Authorization: authToken
            },
            params: {
                ...params
            }
        }).then(res => {
            const view_resp = res.data;
            setViewRequestResponse(view_resp.content);
            setIsInProgress(false)
        }).catch(err => {
            setIsInProgress(false)
        })
    }
    const handleDownloadView = () => {


        let apiUrl = "";
        let params = {
            date: documentInfo.selectedDate,
            download: true
        }
        if (is_check) {
            apiUrl = `apps/daily-feed/request-check-file`;
        } else {
            apiUrl = `/apps/daily-feed/request-file/${documentInfo.appid}`;
        }
        if (documentInfo.rtd === true) {
            params.rtd = true;
        }
        axios.get(apiUrl, {
            headers: {
                Authorization: authToken
            },
            params: {
                ...params
            }
        }).then(res => {

            const link = document.createElement('a');
            link.href = res.data.message.presignedGet;
            link.setAttribute('download', `${res.data.message.filename}`);
            document.body.appendChild(link);
            link.click();
            link.remove()

        }).catch((error)=>{
            const message = error?.response?.data?.message;
            displayErrorMsg(message || error.message);
        })
    }

    return (
        <div>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <div className={classes.headerList}>
                {ListOfFields.map(({ display, mapping, isCheck }, index) => {
                    if (is_check === true && isCheck === false) {
                        return null;
                    }
                    if (is_check === false && isCheck === true) {
                        return null;
                    }
                    return (
                        <div key={display}>
                            <div><b>{display}</b> </div> {documentInfo[mapping]}
                        </div>
                    )
                })}
                <div>
                    <IconButton onClick={handleDownloadView} aria-label="download">
                        <GetAppIcon />
                    </IconButton>
                </div>
            </div>
            <textarea
                className={`form-control`}
                id="JSON"
                rows="20"
                placeholder=""
                value={viewRequestResponse}

                required
            />
        </div>
    )
}

export default React.memo(ViewFile);