import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';

function CancellationReport(props) {
    const { authToken } = props
    const today = moment()

    const [cancelledRequests, setCancelledRequests] = useState([])

    const [duration, setDuration] = useState("Month")
    const [date, setDate] = useState(today)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(0);
    const [pagination, setPagination] = useState([]);

    useEffect(() => {
        if (authToken) {
            axios.get('/reports/cancellation', {
                params: {
                    duration: duration === "All Requests" ? null : duration,
                    date: duration === "All Requests" ? null : date.format("YYYY-MM"),
                    page: currentPage - 1,
                },
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setCancelledRequests(res.data)
                let totalPage = 0;
                if (res.data.length > 0) {
                    totalPage = Math.ceil(res.data[0].total_results / 20)
                }
                setTotalPages(totalPage)
            }).catch(err => {
                console.log(err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken, date, duration, currentPage])

    useEffect(() => {
        const pages = []
        for (let index = 0; index < totalPages; index++) {
            if (index + 1 === currentPage) {
                pages.push(
                    <li className="page-item disabled" key={index}><button className="page-link">{index + 1}</button></li>
                )
            } else {
                pages.push(

                    <li className="page-item">
                        <button className="page-link" onClick={() => { setCurrentPage(index + 1) }}>{index + 1}</button>
                    </li>
                )
            }
        }

        setPagination(pages)
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[totalPages, currentPage])

    const displayTime = (timeToDisplay, timeName, uniqueId) => {
        const id = `cancellation-report-${timeName}-${uniqueId.replace(/\./g, '_')}`
        if (timeToDisplay) {
            const localTime = moment(timeToDisplay).local().format('MMM Do, YYYY HH:mm:ss [(local)]');
            const utcTime = moment(timeToDisplay).utc().format('MMM Do, YYYY HH:mm:ss (UTC)')
            return <td id={id}>{utcTime}
                <UncontrolledTooltip placement="bottom" target={id}> {localTime}</UncontrolledTooltip>
            </td>
        }
        return <td />
    }

    return (
        <div className="mb-5">

            <form className="form-inline mt-3">
                <div className="form-group mr-2 mb-2">
                    <label className="mr-1" htmlFor="duration">Duration:</label>
                    <select className="form-control" id="duration" value={duration} onChange={e => { setDuration(e.target.value); setCurrentPage(1); }}>
                        <option>All Requests</option>
                        <option>Month</option>
                    </select>
                </div>
                {duration === 'Month' ?
                    <div className="form-group mr-2 mb-2">
                        <input type="month" className="form-control date-form" value={date.format("YYYY-MM")}
                            min="2020-01" max={today.format("YYYY-MM")} onChange={e => { setDate(moment(e.target.value)); setCurrentPage(1); }} required
                        />
                    </div>
                    : null
                }
            </form>

            <h4 className="text-center">Cancellation Report</h4>
            <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                        <th scope="col" className="text-left">#</th>
                        <th scope="col">Request ID</th>
                        <th scope="col">Created Time</th>
                        <th scope="col">Cancelled Time</th>
                        <th scope="col">Reason</th>
                        <th scope="col">Cancelled By</th>
                    </tr>
                </thead>
                <CSSTransition in={cancelledRequests.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody className="text-center">
                        {cancelledRequests.map((request, i) => (
                            <tr key={request.dsar_id}>
                                {/* <td>{i + 1}</td> */}
                                <td>{((currentPage - 1) * 20) + (i + 1)}</td>
                                <td>{request.dsar_id}</td>
                                {displayTime(request.created_date, "created_date", request.dsar_id)}
                                {displayTime(request.cancellation_time, "cancellation_time", request.dsar_id)}
                                {/* <td>{request.created_date ? moment(request.created_date).format("MMM Do, YYYY HH:mm:ss (UTC)") : null}</td> */}
                                {/* <td>{request.cancellation_time ? moment(request.cancellation_time).format("MMM Do, YYYY HH:mm:ss (UTC)") : null}</td> */}
                                <td>{request.cancellation_reason}</td>
                                <td>{request.cancelled_by}</td>
                            </tr>
                        ))}
                    </tbody>
                </CSSTransition>
            </table>
            <nav>
                <ul className="pagination justify-content-center">
                    {(totalPages > 1 && totalPages <= 5) ?
                        pagination.map(element => element)
                        :
                        null
                    }
                    {totalPages > 5 ?
                        <ul className="pagination justify-content-center">
                            <li className="page-item mr-2">
                                <button className="page-link" onClick={() => { setCurrentPage(1) }}>First</button>
                            </li>
                            {pagination.map((element, index) => {
                                if (currentPage > 3 && currentPage <= totalPages - 3) {
                                    if (index >= currentPage - 3 && index <= currentPage + 1) {
                                        return element
                                    }
                                } else if (currentPage <= 3 && index <= 4) {
                                    return element
                                } else if (currentPage >= totalPages - 2 && index >= totalPages - 5) {
                                    return element
                                }
                                return null;
                            })}
                            <li className="page-item ml-2">
                                <button className="page-link" onClick={() => { setCurrentPage(totalPages) }}>Last</button>
                            </li>
                        </ul>
                        : null
                    }
                </ul>
            </nav>
        </div>
    );
}

export default CancellationReport;
