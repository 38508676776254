import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../RoutesWithAuth'
import { CSSTransition } from 'react-transition-group';
import PageHeader from '../../common/PageHeader';
import { withNavbar } from '../../common/Navbar';
import { StatusLight } from '../../common/components';
import CtpAggregatorRow from './CtpAggregatorRow';
import axios from '../../common/AxiosConfig';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';
import { Scope_Not_Applicable } from '../../common/commons';

function CtpAggregator() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get

    const [Dsars, setDsars] = useState([])
    const [ctp17cyFlag, setCtp17cyFlag] = useState([])
    const [ctp21mmADRFlag, setCtp21mmADRFlag] = useState([])
    const [ctp21mmAudioFlag, setCtp21mmAudioFlag] = useState([])
    const [ctp21mmIPAppsFlag, setCtp21mmIPAppsFlag] = useState([])
    const [ctp21mmUPFlag, setCtp21mmUPFlag] = useState([])
    const [ctpmpFlag, setCtpmpFlag] = useState([])
    const [filteredDsars, setFilteredDsars] = useState([])
    const [filter, setFilter] = useState("All")

    const [modalDSAR, setModalDSAR] = useState("")
    const [modalStatus, setModalStatus] = useState("")
    const [modalDownstreams, setModalDownstreams] = useState([])

    useEffect(() => {
        if (authToken !== null) {
            axios.get(`/ctp/status`, {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setDsars(res.data.result)
            }).catch(err => {
                console.log(err)
            })
            axios.get(`/ctp/flags`, {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setCtp17cyFlag(res.data.ctp_17cy_flag)
                setCtp21mmADRFlag(res.data.ctp_21mm_adr_flag)
                setCtp21mmAudioFlag(res.data.ctp_21mm_audio_flag)
                setCtp21mmIPAppsFlag(res.data.ctp_21mm_ip_apps_flag)
                setCtp21mmUPFlag(res.data.ctp_21mm_up_flag)
                setCtpmpFlag(res.data.ctp_mp_flag)
            }).catch(err => {
                console.log(err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken])

    useEffect(() => {
        setFilteredDsars(
            Dsars.filter(dsar => {
                if (filter === "All") { return true }
                else if (filter === "Error - CPS") { return dsar.cps_ack === 'Error' }
                else if (filter === "Error - CTP") { return dsar.status_overall === 'Error' }
                else if (filter === "No Data Found") { return dsar.status_overall === 'No Data' }
                else if (filter === "Pending") { return dsar.status_overall === 'Pending' }
                else if (filter === "Success") { return dsar.status_overall === 'Success' }
                return null
            })
        )
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[filter, Dsars])

    const get21MMDetails = (dsarID, status) => {
        setModalDownstreams([])
        setModalDSAR(dsarID)
        setModalStatus(status)

        // Show the modal
        const showModalBtn = document.getElementById("show-21mm-modal")
        showModalBtn.click()

        axios.get(`/ctp/21mm/${dsarID}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setModalDownstreams(res.data.downstreams)
        }).catch(err => {
            setModalDownstreams(null)
        })
    }

    const downloadResponse = (dsarId, sub_system) => {
        axios.get(`/ctp/responses/${dsarId}/${sub_system}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            const link = document.createElement('a');
            const file = new Blob([JSON.stringify(res['data']['response_json'], null, 2)], { type: 'application/json' })
            link.href = URL.createObjectURL(file);
            link.setAttribute('download', `${dsarId}_CTP_${sub_system === '17CY' ? '17P' : sub_system}.txt`);
            document.body.appendChild(link);
            link.click();
            link.remove()
        }
        )
    }

    const download21MMResponse = (dsarId, sub_system) => {
        const link = document.createElement('a');
        const file = new Blob([JSON.stringify(sub_system.payload, null, 2)], { type: 'application/json' })
        link.href = URL.createObjectURL(file);
        link.setAttribute('download', `${dsarId}_CTP_21mm_${sub_system.name}.txt`);
        document.body.appendChild(link);
        link.click();
        link.remove()
    }
    const istToolTip = (displayTime, domId) => {
        return (
            <span>
                <span id={domId}>
                    {moment(displayTime).utc().format("MMM Do, YYYY HH:mm:ss (UTC)")}

                </span>
                <UncontrolledTooltip placement="bottom" target={domId}>
                    {moment(displayTime).local().format('MMM Do, YYYY HH:mm:ss [(local)]')}
                </UncontrolledTooltip>
            </span>
        );
    }

    return (
        <div className="container mb-5">

            <PageHeader header="Requests Queued in CTP Aggregator" />

            <div className="d-flex align-items-start mb-3 mt-4">
                <div className="form-inline mr-auto">
                    <label className="small mr-1" htmlFor="keyFilter">Filter:</label>
                    <select className="form-control form-control-sm" id="keyFilter" value={filter} onChange={e => { setFilter(e.target.value) }}>
                        <option>All</option>
                        <option>Error - CPS</option>
                        <option>Error - CTP</option>
                        <option>No Data Found</option>
                        <option>Pending</option>
                        <option>Success</option>
                    </select>
                    <label className="ml-4">
                        Total Records: {filteredDsars.length}
                    </label>
                </div>
                <StatusLight isGreen={ctp17cyFlag !== Scope_Not_Applicable} text="17+" />
                <StatusLight isGreen={ctp21mmADRFlag !== Scope_Not_Applicable} text="21MM ADR" />
                <StatusLight isGreen={ctp21mmAudioFlag !== Scope_Not_Applicable} text="21MM Audio" />
                <StatusLight isGreen={ctp21mmIPAppsFlag !== Scope_Not_Applicable} text="21MM IP Apps" />
                <StatusLight isGreen={ctp21mmUPFlag !== Scope_Not_Applicable} text="21MM UP" />
                <StatusLight isGreen={ctpmpFlag !== Scope_Not_Applicable} text="MP" />
            </div>

            <table className="table table-striped table-sm">
                <thead>
                    <tr className="text-center">
                        <th scope="col">#</th>
                        <th scope="col">Request ID</th>
                        <th scope="col">Requestor</th>
                        <th scope="col">Request Type</th>
                        <th scope="col">Age (Days)</th>
                        <th scope="col">17+</th>
                        <th scope="col">21MM</th>
                        <th scope="col">MP</th>
                        <th scope="col">CTP Response</th>
                        <th scope="col">CPS Ack</th>
                    </tr>
                </thead>
                <CSSTransition in={filteredDsars.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody className="text-center">
                        {filteredDsars.map((dsar, i) => (
                            <CtpAggregatorRow key={dsar.dsar_id} serialNumber={i + 1} data={dsar} get21MMDetails={get21MMDetails} downloadResponse={downloadResponse} />
                        ))}
                    </tbody>
                </CSSTransition>
            </table>

            <p className="text-center font-italic">
                Note:<br />
                a. Time when the request was pushed to sub systems is displayed on hovering over Request ID.<br />
                b. Time when the response was received from sub systems is displayed on hovering over individual subsystem status.
            </p>

            <button className="d-none" id="show-21mm-modal" data-toggle="modal" data-target="#modal-21mm"></button>

            <div className="modal fade" id="modal-21mm" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog mb-3" role="document" style={{ maxWidth: "800px" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalDSAR}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {modalDownstreams === null ?
                                <div className="text-center text-danger h6">
                                    Could not get 21MM Data
                                    {modalStatus === "Pending" ? null : <Link className="float-right" onClick={() => downloadResponse(modalDSAR, '21MM')}>Cumulative Response</Link>}
                                </div>
                                :
                                <div>
                                    <table className="table table-striped table-sm">
                                        <thead>
                                            <tr className="text-center">
                                                <th scope="col">#</th>
                                                <th scope="col">21MM System</th>
                                                <th scope="col">Request Sent</th>
                                                <th scope="col">Response Received</th>
                                                <th scope="col">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {modalDownstreams.map((downstream, i) => (
                                                <tr key={`${downstream.name}-${downstream.responseReceived}-${downstream.requestSent}`}>
                                                    <td>{i + 1}</td>
                                                    <td>{downstream.name}</td>
                                                    <td>
                                                        {downstream.responseReceived === "N/A" ?
                                                            "N/A" :
                                                            istToolTip(downstream.requestSent, `response-received-${i + 1}`)
                                                        }
                                                    </td>
                                                    <td>
                                                        {invalidDates.includes(downstream.responseReceived) ?
                                                            downstream.responseReceived :
                                                            istToolTip(downstream.responseReceived, `response-received-invalidDate-${i + 1}`)
                                                        }
                                                    </td>
                                                    <td>
                                                        {downstream.status === "N/A" || downstream.status === "Pending" ?
                                                            downstream.status
                                                            :
                                                            <Link onClick={() => download21MMResponse(modalDSAR, downstream)}>{downstream.status}</Link>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    {modalStatus === "Pending" ? null : <Link className="float-right" onClick={() => downloadResponse(modalDSAR, '21MM')}>Cumulative Response</Link>}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default withNavbar(CtpAggregator, 3);

const invalidDates = ["N/A", ""]
