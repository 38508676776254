import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { feedbackTimeout, RequestTypeMapping, v2UIRoutePath } from '../../../common/commons';
import axios from '../../../common/AxiosConfig';
import { AppContext } from '../../../../RoutesWithAuth';
import DataGrid from '../grid';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import Dialog from '../Dialog';
import GetAppIcon from '@material-ui/icons/GetApp';
import { makeStyles } from "@material-ui/core/styles";
import FormGroup from '../FormGroup';
import ReportPopup from './ReportCommonPopup';
import Papa from 'papaparse';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    dialogheader: {
        textAlign: 'center',
        fontSize: '0.75rem',
        '& span': {
            fontWeight: 'bold'
        },
        '& .modelheader': {
            paddingBottom: '5px',
            fontWeight: 'bold'
        }
    },
    agingReportHeader: {
        padding: '10px 0px 5px 10px',
        fontSize: '1rem',
        fontWeight: 'bold',
        borderBottom: '1px solid #dedfdf',
        marginBottom: '5px',
        marginRight: '10px'
    },
    downstreamMetricsHeader: {
        fontSize: '1rem',
        fontWeight: 'bold',
        padding: '10px 0px 5px 10px',
    },
    requestStatusInput: {
        width: '95px',
    },
    root: {
        '& .btngridcel': {
            background: "transparent",
            border: "0px solid transparent",
            outline: "0px",
            padding: '0px',
            fontSize: 'inherit'
        }
    },
    dialogWrapper: {
        width: '860px'
    }
}))

function AgingReport(props) {

    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles();

    const todayUTC = moment().utc();
    const location = useLocation();
    const routeState = location?.state;

    const [rtdBreakdown, setRtdBreakdown] = useState([])
    const [rtkBreakdown, setRtkBreakdown] = useState([])
    const [rtooBreakdown, setRtooBreakdown] = useState([])
    const [rtcBreakdown, setRtcBreakdown] = useState([])
    const [rttBreakdown, setRttBreakdown] = useState([])
    const [rtcrBreakdown, setRtcrBreakdown] = useState([])
    const [isrBreakdown, setIsrBreakdown] = useState([])
    const [totalBreakdown, setTotalBreakdown] = useState([])
    const [ticket_state, setTicketState] = useState(routeState?.ticket_state || "Open")
    const [reportHealth, setReportHealth] = useState({}) 

    const [openModel, setOpenModel] = useState(false)
    const [modelData, setModelData] = useState([])
    const [dialogAppName, setDialogAppName] = useState("")
    const [noOfDaysSelected, setNoOfDaysSelected] = useState("")

    const [downstreamMetrics, setDownstreamMetrics] = useState([])

    const [age, setAge] = useState("")

    const open = ticket_state === "Open";

    useEffect(() => {
        if (routeState) {
            setTicketState(routeState.ticket_state);
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[routeState?.ticket_state])

    useEffect(() => {
        if (authToken) {
            axios.get('/reports/aging', {
                params: {
                    is_open: open,
                },
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setRtdBreakdown(res.data.age.rtd)
                setRtkBreakdown(res.data.age.rtk)
                setRtooBreakdown(res.data.age.rtoo)
                setRtcBreakdown(res.data.age.rtc)
                setRttBreakdown(res.data.age.rtt)
                setRtcrBreakdown(res.data.age.rtcr)
                setIsrBreakdown(res.data.age.isr)
                setTotalBreakdown(res.data.age.total)
                setReportHealth({
                    days : res.data.oldest_dsar_age,
                    dsar : res.data.oldest_dsar
                })
                // Decide the default age for the downstream metrics
                let i = 3
                if (open) {
                    res.data.age.total.find((num, index) => {
                        if (num > 0) { i = index; return num }
                        return null;
                    })
                    setAge(metricAgeMapping[i])
                }
            }).catch(err => {
                console.log(err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken, open])

    useEffect(() => {
        if (age !== "") {
            axios.get('/reports/aging/metrics', {
                params: {
                    metric_age: age,
                },
                headers: {
                    Authorization: authToken,
                }
            }).then(res => {
                setDownstreamMetrics(res?.data?.metrics)
            }).catch(err => {
                console.log(err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[age, authToken])

    const downloadDsarIds = () => {
        axios.get('/reports/aging/download', {
            params: {
                metric_age: age,
                is_open: open,
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            if (res.data.old_dsar_ids.length) {
                let csv = []
                for (const dsar_id of res.data.old_dsar_ids) {
                    csv.push({ "DSAR ID": dsar_id });
                }
                const downloadLink = document.createElement("a");
                csv = Papa.unparse(csv)
                const blob = new Blob(["\ufeff", csv])
                const url = URL.createObjectURL(blob)
                downloadLink.href = url
                const timestamp = moment().format("YYYYMMDDHHmm")
                downloadLink.download = `Aging_Report_${age}_${ticket_state}_${timestamp}.csv`
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
            } else {
                populateError('There are no old DSAR IDs!')
            }
        }).catch(err => {
            console.log(err)
            populateError(err.response.data.message)
        })
    }

    const downloadQuery = (appName, ticketColIndex) => {
        // Map the ticketType index to the actual value
        const ticketTypeMapping = ['ISR','Right-to-Correct', 'Right-to-Create', 'Right-to-Delete', 'Right-to-Know-Detailed', 'Right-to-OptOut', 'Right-to-Terminate', 'total']
        const ticketType = ticketTypeMapping[ticketColIndex];

        axios.get('/reports/aging/download_query', {
            params: {
                app_name: appName,
                ticket_type: ticketType,
                download_total: true,
                metric_age: age,
                is_open: open,
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setOpenModel(true);
            setModelData(res.data.dsar_ids);
            setDialogAppName(appName);
            setNoOfDaysSelected("");
        }).catch(err => {
            console.log(err)
        })
    }

    const downloadAgingQuery = (ageIndex, ticketType) => {
        // Map the ticketType & age index to the actual value
        const ticketTypeMapping = ['ISR','Right-to-Correct', 'Right-to-Create', 'Right-to-Delete', 'Right-to-Know-Detailed', 'Right-to-OptOut', 'Right-to-Terminate', 'total']
        ticketType = ticketTypeMapping[ticketType]
        const ageMapping = ['11_plus', '7_10', '4_6', '0_3']
        const age = ageMapping[ageIndex]
        const displayDates = [`11+ Days (${todayUTC.clone().subtract(11, "days").format("MM/DD")} & earlier)`,
        `7-10 Days (${todayUTC.clone().subtract(10, "days").format("MM/DD")} - ${todayUTC.clone().subtract(7, "days").format("MM/DD")})`,
        `4-6 Days (${todayUTC.clone().subtract(6, "days").format("MM/DD")} - ${todayUTC.clone().subtract(4, "days").format("MM/DD")})`,
        `0-3 Days (${todayUTC.clone().subtract(3, "days").format("MM/DD")} - ${todayUTC.format("MM/DD")})`
        ]
        const display_age = displayDates[ageIndex]

        axios.get(`/reports/aging/download_${open ? "open" : "closed"}_query`, {
            params: {
                ticket_type: ticketType,
                metric_age: age,
                parse_json: true
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setNoOfDaysSelected(display_age);
            setDialogAppName("");
            setOpenModel(true);
            setModelData(res.data.dsar_ids);
        }).catch(err => {
            console.log(err)
        })
    }

    const populateError = (message) => {
        const downloadError = document.getElementById('downloadError')
        downloadError.innerHTML = message
        removeFeedback('downloadError')
    }

    const removeFeedback = (elementId) => {
        // Remove the feedback after a specified timeout period
        setTimeout(
            function () {
                const field = document.getElementById(elementId)
                if (!field) { return }
                field.innerHTML = ""
            }, feedbackTimeout
        )
    }
    const agingRows = [{
        id: 0,
        days: `11+ Days (${todayUTC.clone().subtract(11, "days").format("MM/DD")} & earlier)`,
        requestType: RequestTypeMapping['Right-to-Correct'],
        isr: isrBreakdown[0],
        rtc: rtcBreakdown[0],
        rtcr: rtcrBreakdown[0],
        rtd: rtdBreakdown[0],
        rtk: rtkBreakdown[0],
        rtoo: rtooBreakdown[0],
        rtt: rttBreakdown[0],
        totals: totalBreakdown[0]
    }, {
        id: 1,
        days: `7-10 Days (${todayUTC.clone().subtract(10, "days").format("MM/DD")} - ${todayUTC.clone().subtract(7, "days").format("MM/DD")})`,
        isr: isrBreakdown[1],
        rtc: rtcBreakdown[1],
        rtcr: rtcrBreakdown[1],
        rtd: rtdBreakdown[1],
        rtk: rtkBreakdown[1],
        rtoo: rtooBreakdown[1],
        rtt: rttBreakdown[1],
        totals: totalBreakdown[1]
    }, {
        id: 2,
        days: `4-6 Days (${todayUTC.clone().subtract(6, "days").format("MM/DD")} - ${todayUTC.clone().subtract(4, "days").format("MM/DD")})`,
        isr: isrBreakdown[2],
        rtc: rtcBreakdown[2],
        rtcr: rtcrBreakdown[2],
        rtd: rtdBreakdown[2],
        rtk: rtkBreakdown[2],
        rtoo: rtooBreakdown[2],
        rtt: rttBreakdown[2],
        totals: totalBreakdown[2]
    }, {
        id: 3,
        days: `0-3 Days (${todayUTC.clone().subtract(3, "days").format("MM/DD")} - ${todayUTC.format("MM/DD")})`,
        isr: isrBreakdown[3],
        rtc: rtcBreakdown[3],
        rtcr: rtcrBreakdown[3],
        rtd: rtdBreakdown[3],
        rtk: rtkBreakdown[3],
        rtoo: rtooBreakdown[3],
        rtt: rttBreakdown[3],
        totals: totalBreakdown[3]
    }];
    const customDownLoadRenderCell = (params) => {
        return params.value > 0 ?
            <button className="btngridcel btn btn-link p-0">{params.value}</button>
            : params.value
    };

    return (
        <div className={`mb-5 ${classes.root}`}>
            <Typography variant="h6" className={`${classes.agingReportHeader}`}>
                REQUESTS AGING REPORT
            </Typography>
            <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box flex={1}>
                    <FormGroup
                        fields={[{
                            type: 'multiselect',
                            name: 'requestStatus',
                            label: 'Request Status',
                            customInputCls: classes.requestStatusInput,
                            removeCheckBox: true,
                            options: ['Open', 'Closed'],
                            size: 2,
                        }]}
                        disableFormActions={true}
                        defaultFormData={{
                            "requestStatus" : ticket_state
                        }}
                        customFormUpdates={(formData) => {
                            setTicketState(formData["requestStatus"]);
                        }}
                    />
                </Box>
                { open && (<Box marginRight={"10px"}>
                    <Box>
                        <b>Max Age:</b> {reportHealth?.days || "--"} days
                    </Box>
                    <Box>
                        <b>Oldest DSAR: </b>
                        <Link to={`${v2UIRoutePath}requestdetails/${reportHealth?.dsar}`}>
                             {reportHealth?.dsar || "--"}
                        </Link>
                        
                    </Box>
                </Box> )}
            </Box>

            <DataGrid
                customHeight={180}
                customRowHeight={30}
                onCellClick={(cellparams) => {
                    if (cellparams.colDef.columnMappingIndex >= 0 && cellparams.formattedValue > 0) {
                        downloadAgingQuery(cellparams.row.id, cellparams.colDef.columnMappingIndex)
                    }
                }}
                columns={[{
                    name: "No. of Days",
                    mapping: "days"
                }, {
                    name: RequestTypeMapping['ISR'],
                    mapping: "isr",
                    align: 'center',
                    columnMappingIndex: 0,
                    renderCell: customDownLoadRenderCell
                },{
                    name: RequestTypeMapping['Right-to-Correct'],
                    mapping: "rtc",
                    align: 'center',
                    columnMappingIndex: 1,
                    renderCell: customDownLoadRenderCell
                },{
                    name: RequestTypeMapping['Right-to-Create'],
                    mapping: "rtcr",
                    align: 'center',
                    columnMappingIndex: 2,
                    renderCell: customDownLoadRenderCell
                }, {
                    name: "Right to Delete",
                    mapping: "rtd",
                    align: 'center',
                    columnMappingIndex: 3,
                    renderCell: customDownLoadRenderCell
                }, {
                    name: "Right to Know",
                    mapping: "rtk",
                    align: 'center',
                    columnMappingIndex: 4,
                    renderCell: customDownLoadRenderCell
                }, {
                    name: "Right to Opt-Out",
                    mapping: "rtoo",
                    align: 'center',
                    columnMappingIndex: 5,
                    renderCell: customDownLoadRenderCell
                },{
                    name: RequestTypeMapping['Right-to-Terminate'],
                    mapping: "rtt",
                    align: 'center',
                    columnMappingIndex: 6,
                    renderCell: customDownLoadRenderCell
                }, {
                    name: "Totals",
                    align: 'center',
                    mapping: "totals",
                    columnMappingIndex: 7,
                    renderCell: customDownLoadRenderCell
                }]}
                rows={agingRows}
            />
            {open && (
                <div>
                    <Typography variant="h6" className={`${classes.downstreamMetricsHeader}`}>
                        <form className="form-inline">
                            Downstream Metrics for
                            <select className="form-control form-control-inline form-inline mx-2"
                                value={age} onChange={e => setAge(e.target.value)}
                            >
                                <option value="0_3">0-3</option>
                                <option value="4_6">4-6</option>
                                <option value="7_10">7-10</option>
                                <option value="11_plus">11+</option>
                            </select>
                            days old Requests
                            <div style={{ flex: 1, display: 'flex', marginRight: '40px', alignItems: 'end', justifyContent: 'end' }}>
                                <Tooltip title="Download Request Details" aria-label="Download Request Details">
                                    <IconButton onClick={() => { downloadDsarIds() }}>
                                        <GetAppIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>

                        </form>

                    </Typography>

                    <DataGrid
                        customRowHeight={30}
                        columns={[{
                            name: "App Name",
                            mapping: "name"
                        }, {
                            name: RequestTypeMapping['ISR'],
                            mapping: "isr",
                            columnMappingIndex: 0,
                            align: 'center',
                            renderCell: customDownLoadRenderCell
                        },{
                            name: RequestTypeMapping['Right-to-Correct'],
                            mapping: "rtc",
                            columnMappingIndex: 1,
                            align: 'center',
                            renderCell: customDownLoadRenderCell
                        },{
                            name: RequestTypeMapping['Right-to-Create'],
                            mapping: "rtcr",
                            columnMappingIndex: 2,
                            align: 'center',
                            renderCell: customDownLoadRenderCell
                        }, {
                            name: "Right to Delete",
                            mapping: "rtd",
                            columnMappingIndex: 3,
                            align: 'center',
                            renderCell: customDownLoadRenderCell
                        }, {
                            name: "Right to Know",
                            mapping: "rtk",
                            columnMappingIndex: 4,
                            align: 'center',
                            renderCell: customDownLoadRenderCell
                        }, {
                            name: "Right to Opt-Out",
                            mapping: "rtoo",
                            columnMappingIndex: 5,
                            align: 'center',
                            renderCell: customDownLoadRenderCell
                        }, {
                            name: RequestTypeMapping['Right-to-Terminate'],
                            mapping: "rtt",
                            columnMappingIndex: 6,
                            align: 'center',
                            renderCell: customDownLoadRenderCell
                        },{
                            name: "Totals",
                            mapping: "totals",
                            align: 'center',
                            columnMappingIndex: 7,
                            renderCell: customDownLoadRenderCell

                        }]}
                        onCellClick={(cellparams) => {
                            if (cellparams.colDef.columnMappingIndex >= 0 && cellparams.formattedValue > 0) {
                                downloadQuery(cellparams.id, cellparams.colDef.columnMappingIndex)
                            }
                        }}
                        customHeight={250}
                        rows={downstreamMetrics?.map((downstreamMetrics) => {
                            const totals = downstreamMetrics.data.reduce((acc, curVal) => acc + curVal)
                            return {
                                id: downstreamMetrics.name,
                                name: downstreamMetrics.name,
                                isr: downstreamMetrics.data[0],
                                rtc: downstreamMetrics.data[1],
                                rtcr: downstreamMetrics.data[2],
                                rtd: downstreamMetrics.data[3],
                                rtk: downstreamMetrics.data[4],
                                rtoo: downstreamMetrics.data[5],
                                rtt: downstreamMetrics.data[6],
                                totals: totals,
                            }
                        })}
                    />
                </div>
            )}
            <>
            {openModel && <Dialog
            isOpen={true}
            updateDialogStatus={() => {
                setOpenModel(false);
            }}
            dialogContent={<ReportPopup modelData={modelData} />}
            title={(
                <div className={classes.dialogheader}>
                <div className='modelheader'>{open ? "Open " : "Closed"} Requests</div>
                {dialogAppName !== "" ? (<>
                    <span> App Name </span> : {dialogAppName}
                </>) : (<>
                    <span> No. of Days </span> : {noOfDaysSelected}
                </>)}

            </div>
            )}
        />}
        
            </>
        </div>
    );
}

export default AgingReport;

const metricAgeMapping = {
    0: "11_plus",
    1: "7_10",
    2: "4_6",
    3: "0_3",
}