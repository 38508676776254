import React, { useContext,useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import axios from '../../../common/AxiosConfig';
import moment from 'moment';
import DataGrid from '../grid';
import { AppContext } from '../../../../RoutesWithAuth';
import { Typography, makeStyles } from '@material-ui/core';
import FormGroup from '../FormGroup';
import ReportPopup from './ReportCommonPopup';
import {RequestorMapping} from '../../../common/commons';
import Dialog from '../Dialog';

const useStyles = makeStyles(() => ({
    dialogheader :{
        textAlign:'center',
        fontSize: '0.75rem',
        '& span' :{
            fontWeight : 'bold'
        },
        '& .modelheader' :{
            paddingBottom : '5px',
            fontWeight : 'bold'
        }
    },
    dialogWrapper : {
        width : '860px'
    },
    customRadioInputCls : {
        display: 'flex',
        alignItems: 'center',
        width : '140px',
    },
    customInputCls : {
        width : '140px',
    },
    formGroupCls : {
        width: '700px',
        minWidth: '700px',
        height: '80px'
    },
    root : {
        '& .btngridcel':{
            background: "transparent",
            border:"0px solid transparent",
            outline : "0px",
            padding:'0px',
            fontSize: 'inherit'
        }
    },

}));

function AutoClosedReport(props) {

    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles();

    const today = moment()
    
    const [duration, setDuration] = useState(true)
    const [tableFooter, setTableFooter] = useState("All Requests")
    const [date, setDate] = useState(today)
    const [openModel, setOpenModel] = useState(false);
    const [modelData, setModelData] = useState([]);
    const [closedType, setClosedType] = useState("")
    const [autoClosedData, setAutoClosedData] = useState([])
    const [requestor, setRequestor] = useState("All")

    const reportColumns = [{
        name : "Request Type",
        mapping:"requestType",
        width : 120
    },{
        name : "Active Relationship",
        align : 'center',
        mapping : "active_relationship"
    },{
        name : "Active Rentals",
        align : 'center',
        mapping:"Rental"
    },{
        name : "Active Vehicle Association",
        mapping:"subscriber",
        align : 'center',
        width : 180
    },{
        name : "Current Customer",
        mapping:"customer",
        align : 'center'
    },{
        name : "Lit. Hold",
        align : 'center',
        mapping : "litigation"
    },{
        name : "No Downstreams",
        mapping:"no_downstream",
        align : 'center',
    },{
        name : "No Vehicle",
        mapping:"vehicle",
        align : 'center'
    },{
        name : "Out of Scope",
        align : 'center',
        mapping : "out_of_scope"
    },{
        name : "Primary or Remote Subscription",
        align : 'center',
        mapping : "primary_or_remote",
        width : 210
    },{
        name : "Total",
        align : 'center',
        mapping : "total"
    }];
    useEffect(() => {
        // UseEffect used to fetch the data from the backend
        if (authToken) {
            axios.get('/reports/auto_closed_v2', {
                params: {
                    duration: duration === true ? null : "Month",
                    date: duration === true ? null : date.format("YYYY-MM"),
                    requestor
                },
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                // Static Rows which gets displayed in the Grid
                const rowsToDisplay = [{
                    requestType : "ISR",
                    requestMap : "isr",
                    popupMapping : 'ISR'

                },{
                    requestType : "Right To Correct",
                    requestMap : "rtc",
                    popupMapping : 'Right-to-Correct'
                },{
                    requestType : "Right to Create",
                    requestMap : "rtcr",
                    popupMapping : 'Right-to-Create'

                },{
                    requestType : "Right to Delete",
                    requestMap : "rtd",
                    popupMapping : 'Right-to-Delete'

                },{
                    requestType : "Right to Know",
                    requestMap : "rtk",
                    popupMapping : 'Right-to-Know-Detailed'
                },{
                    requestType : "Right to Opt Out",
                    requestMap : "rtoo",
                    popupMapping : 'Right-to-OptOut'
                },{
                    requestType : "Right to Terminate",
                    requestMap : "rtt",
                    popupMapping : 'Right-to-Terminate'
                },{
                    requestType : "Total",
                    requestMap : "total",
                    popupMapping : '%'
                }];
                // Formatting the Array response to Key value pair response which will be suitable for grid
                const ACRdata = rowsToDisplay.map((_eachRow)=>{
                    let rowData = res.data[_eachRow["requestMap"]];
                    if(!rowData){
                        return null;
                    }
                    let formatedResponse = {};
                    // iterating through the columns and columns are checked based on index
                    reportColumns.forEach((reportColumn,index)=>{
                        // Ignoring the first column (ie request type column)
                        if(index !== 0 ){
                            formatedResponse[reportColumn.mapping] = rowData[index-1]
                        }
                    })
                    return {
                        ..._eachRow,
                        id : _eachRow.requestMap,
                        ...formatedResponse
                    }
                }).filter(Boolean)
                setAutoClosedData(ACRdata)
                setTableFooter(duration === true ? "All Requests" : date.format("MMMM YYYY"))
            }).catch(err => {
                console.log(err)
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, date, duration])
/**
 * This Method returns clickable component when there is a value in the cell
 * @param {Row Data} params 
 * @returns React JSX element
 */
    const customDownLoadRenderCell = (params)=>{
        return params.value > 0 ?
            <button className="btngridcel btn btn-link p-0">{params.value}</button>
            : params.value
    };

    const fetchPopupData = (request_type,closed_type, closed_type_name)=>{

        axios.get('/reports/auto_closed/download_query_v2', {
            params: {
                duration: duration === true ? null : "Month",
                date: duration === true ? null : date.format("YYYY-MM"),
                request_type,
                closed_type,
                requestor
                // v2: true,
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setOpenModel(true);
            setModelData(res.data?.dsar_ids.map(({dsar_id, created_date, requestor, ticket_type})=>{
                return {
                    dsar_id,
                    ticket_type,
                    requestor,
                    created_date
                }
            }) || [])
            setClosedType(closed_type_name);

        }).catch(err => {
            console.log(err)
        })
    }
    return (
        <div className={`${classes.root}`}>
            <Typography variant="h6" className={'tabHeader'}>
                AUTO CLOSED REPORT
            </Typography>
            <FormGroup 
                formWrapperClassName={classes.formGroupCls}
                fields={[{
                    type : 'checkbox',
                    name : 'duration',
                    label : 'All Requests',
                    customInputCls : classes.customRadioInputCls,
                    defaultOption : true,
                    size :2,
                },{
                    type : 'month',
                    name : "date",
                    label : "Select Month",
                    defaultOption : date.format("YYYY-MM"),
                    isDisabled : duration,
                    inputAdditionalProps : {
                        min : "2020-01",
                        max : today.format("YYYY-MM")
                    }
                },{
                    type : 'multiselect',
                    name : 'requestor',
                    label : 'Requestor',
                    isMulti : false,
                    removeCheckBox : true,
                    customInputCls : classes.customInputCls,
                    options : ["All", ...Object.values(RequestorMapping)],
                    defaultOption : 'All',
                    size : 2
                }]}
                disableFormActions = {true}
                customFormUpdates={(formData)=>{
                    setDuration(formData["duration"])
                    setDate(moment(formData["date"]))
                    setRequestor(formData["requestor"])
                }}
            />
            <DataGrid 
                customHeight={200}
                customRowHeight={30}
                onCellClick={(cellparams)=>{
                    if(cellparams?.colDef?.field !== "requestType" && cellparams.value > 0){
                        fetchPopupData(cellparams.row.popupMapping, cellparams.colDef.mapping, cellparams.colDef.name);
                    }

                }}
                columns={reportColumns.map((reportColumn, index) => {
                    /**
                     * Iterating through the columns and adding custom cell render functionality to
                     * all the columns except request type column.
                     */
                    if(index===0) return reportColumn;
                    return {
                        ...reportColumn,
                        width : reportColumn.width || 150,
                        renderCell : customDownLoadRenderCell
                    }
                })}
                rows={autoClosedData}
            />
            <SwitchTransition>
                <CSSTransition key={tableFooter} timeout={250} classNames="swipeR">
                    <p className="font-italic text-center">Auto Closed Count: {tableFooter}</p>
                </CSSTransition>
            </SwitchTransition>
            <>
            {openModel && <Dialog
            isOpen={true}
            updateDialogStatus={() => {
                setOpenModel(false);
            }}
            dialogContent={<ReportPopup modelData={modelData} />}
            title={(
                <div className={classes.dialogheader}>
                            <div className='modelheader'>Auto Closed Requests</div>
                            <>
                                <span> Workflow : </span> {closedType}
                            </>
                        </div>
            )}
        />}
        
            </>
        </div>
    );
}

export default AutoClosedReport;
