/**
 * California Consumer Report main component 
 * This is rendered as part of reports -> California Customer Chart Tab
 */
import React, { useState, useContext, useEffect, useRef } from "react";
import moment from "moment";
import axios from "../../../../common/AxiosConfig";
import { AppContext } from "../../../../../RoutesWithAuth";
import { Typography, makeStyles } from "@material-ui/core";
import FormGroup from "../../FormGroup";
import { RequestorMapping, v2UIRoutePath } from '../../../../common/commons';
import BarChart from "./BarChart";
import { LoadingIndicator } from "../reconcilationreport/MuiStyled";
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {
        '& svg > g > g:nth-child(3)': {
            '& text': {
                transform: 'rotate(-90deg)'
            }
        },
        '& svg > g > g:nth-child(2)': {
            '& g': {
                display: 'none'
            },
            '& line': {
                stroke: '#ddddde !important'
            }
        },
        '& svg > g > g:nth-child(1)': {
            '& line': {
                stroke: '#ddddde !important'
            },

            '& text': {
                textTransform: 'uppercase'
            }
        }
    },
    formGroupWrapper: {
        width: '600px',
        '& .multiSelectedOptions': {
            width: '140px'
        }
    },
    customInputCls: {
        width: '140px',
    },

}));
function CaliforniaReport(props) {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles();
    const today = moment();
    //state for maintaining form related data
    const [formData, setFormData] = useState({
        from_date: "",
        to_date: "",
        requestor: ['All']
    });
    const [reportData, setReportData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const formGroupRef = useRef();
    const location = useLocation();


    useEffect(() => {
        // resetting the Form when there is change in the Location state.
        formGroupRef.current.resetFormToDefaults();
        setFormData({
            from_date: "",
            to_date: "",
            requestor: ['All']
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.fromredirection])

    useEffect(() => {
        if (authToken) {
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, JSON.stringify(formData)])

    const fetchData = () => {

        let requestor = formData.requestor.join(', ')?.toLowerCase();
        if (requestor === "all") {
            requestor = "ALL"
        } else if (requestor === "") {
            return false
        }
        setIsLoading(true)
        axios.get("/reports/california_consumer_report", {
            headers: {
                Authorization: authToken
            },
            params: {
                ...formData,
                requestor
            }
        }).then(res => {

            const unformatedResponseData = res?.data?.[0] || {};
            const requestTypeRows = [
                ["ISR", 'isr'],
                ["Right to Correct", 'rtc'],
                ["Right to Create", 'rtcr'],
                ["Right to Delete", 'rtd'],
                ["Right to Know", 'rtk'],
                ["Right to OptOut", "rtoo"],
                ["Right to Terminate", 'rtt'],
                ["Total", "total"]
            ];
            const ticket_status = [
                ['_cali', 'California Customer'],
                ['_non_cali', 'Non-California Customer'],
                ['_total', 'Total']
            ]
            const mappedUiResponse = [];
            requestTypeRows.forEach((requestTypeRow) => {
                let requestTypeColumns = {};

                ticket_status.forEach((_ticket_status) => {
                    requestTypeColumns[_ticket_status[1]] = unformatedResponseData[`${requestTypeRow[1]}${_ticket_status[0]}`]
                    if (requestTypeRow[1] === "total" && _ticket_status[1] === "Total") {
                        requestTypeColumns[_ticket_status[1]] = unformatedResponseData[requestTypeRow[1]];
                    }
                })
                if(unformatedResponseData.hasOwnProperty(`${requestTypeRow[1]}${ticket_status[0][0]}`)){
                mappedUiResponse.push( {
                    "name": requestTypeRow[0],
                    ...requestTypeColumns
                });
            }
            })
            setReportData(mappedUiResponse);
            setIsLoading(false)

        }).catch(err => {
            setIsLoading(false)

            console.log(err)
        })
    }

    return (

        <div className={`${classes.root}`} data-testid="california-consumer-report">
            {isLoading && (<LoadingIndicator />)}
            <Typography variant="h6" className={'tabHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);
                    return {
                        pathname: v2UIRoutePath + "reports/californiaconsumerreport",
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    CALIFORNIA CONSUMER REPORT
                </Link>

            </Typography>
            <FormGroup
                formWrapperClassName={classes.formGroupWrapper}
                ref={formGroupRef}
                fields={[{
                    type: 'date',
                    name: "from_date",
                    label: "From Date",
                    defaultOption: "",
                    inputAdditionalProps: {
                        min: "2020-01-01",
                        max: today.format("YYYY-MM-DD")
                    }
                }, {
                    type: 'date',
                    name: "to_date",
                    label: "To Date",
                    defaultOption: "",
                    inputAdditionalProps: {
                        min: formData?.from_date || "2020-01-01",
                        max: today.format("YYYY-MM-DD")
                    }
                }, {
                    type: 'multiselect',
                    name: 'requestor',
                    label: 'Requestor',
                    isMulti: true,
                    customInputCls: classes.customInputCls,
                    options: ["All", ...Object.values(RequestorMapping)],
                    defaultOption: 'All',
                    size: 2
                }]}
                disableFormActions={true}
                customFormUpdates={(formData) => {
                    // This Method is called when ever there is any change in the form fields
                    setFormData(formData)
                }}
            />
            <div style={{ height: "570px" }}>
                <BarChart
                    //Passing dummy data as API is not integrated yet
                    data={reportData}
                />
            </div>
        </div>
    );
}

export default React.memo(CaliforniaReport);
