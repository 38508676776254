import React from 'react';
import { useState, useEffect, useContext } from 'react';
import Dialog from '../Dialog';
import { Box } from '@material-ui/core';
import axios from '../../../common/AxiosConfig';
import { AppContext } from '../../../../RoutesWithAuth';
import { getFormatedDate } from '../../../common/commons';
import Toolbar from '../grid/Toolbar';

const AuditTrail = ({
    hideComponent,
    dsarRequestId
}) => {

    const [auditTrailData, setAuditTrailData] = useState();
    const context = useContext(AppContext)
    const authToken = context.authToken.get


    useEffect(() => {
        axios.get(`ticket/audittrail/${dsarRequestId}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setAuditTrailData(res.data?.map((_displayAuditTrailData,index)=>{
                return {
                    rowId: index + 1,
                    created_date_UTC : getFormatedDate(_displayAuditTrailData.created_date, true),
                    created_date_Local : getFormatedDate(_displayAuditTrailData.created_date),
                    ..._displayAuditTrailData
                }
            }))
        }).catch(err => {
            console.log(err)
        })
     
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[dsarRequestId])

    const downloadAuditLogs = () => {
        let csv = "#,Activity,Details,User,Date Time"
        csv = auditTrailData.reduce((total, {
            rowId,
            activity,
            additional_data,
            user_name,
            created_date_UTC
        }) => total + `\n${rowId},${activity},${additional_data === null ? '' : 
            additional_data.replace(/[\n,]/g, ' ')},${user_name},${created_date_UTC.replace(/[\n,]/g, ' ')}`, csv);
		const link = document.createElement('a');
        const file = new Blob(["\ufeff", csv])
        link.href = URL.createObjectURL(file);
        link.setAttribute('download', `Audit_Trail_${dsarRequestId}.csv`);
		document.body.appendChild(link);
		link.click();
		link.remove()
	}
    const handleDownloadClick =async(payload_type, rowData)=>{

        const res = await axios.get(`download/${rowData.dsar_id}/${rowData.id}/${payload_type}`, {
            headers: {
                Authorization: authToken
            }
        });

        const fileLink = res.data.presignedGet;
        if(fileLink){
            const link = document.createElement('a');
       
        link.href = fileLink;
        link.setAttribute('download', `${rowData.id}_${payload_type}.json`);
		document.body.appendChild(link);
		link.click();
		link.remove()
        }

    }
    const customDownLoadRenderCell = (params) => {
        return params.row.download_flag  ?
        <>
            <button className="btngridcel btn btn-link p-0" onClick={()=>handleDownloadClick('request', params.row)}>api-request, </button>
            <button className="btngridcel btn btn-link p-0" onClick={()=>handleDownloadClick('response', params.row)} >api-response</button>
            </>
            : <div></div>
    };
    return (
        <Dialog
            isOpen={true}
            isLoading={!auditTrailData}
            updateDialogStatus={() => {
                hideComponent()
            }}
            popupGridData={auditTrailData || []}
            popupColumns={[{
                name: "#",
                mapping: "rowId",
                width: 50
            }, {
                name: 'Activity',
                mapping: 'activity',
                displayTootip: ["activity"]
            }, {
                name: "Details",
                mapping: 'additional_data',
                displayTootip: ["additional_data"],
                width: 150
            },{
                name: "Logs",
                mapping: 'dsar_id',
                renderCell:customDownLoadRenderCell

            }, {
                name: "User",
                mapping: 'user_name',
                width: 65


            }, {
                name: "Date Time",
                mapping: 'created_date_UTC',
                displayTootip : ["created_date_Local"]
            }]}
            enablePagination={false}
            title={(
                <Box paddingBottom={"5px"} fontSize={"1rem"}>
                    <div className='modelheader'>Audit Trail</div>
                    <Toolbar 
                        downloadLabel={"Download"}
                        handleDownloadGrid={downloadAuditLogs}
                    />
                </Box>
            )}
        />
    )
}
export default AuditTrail;