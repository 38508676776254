import { makeStyles } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import FormGroup from '../FormGroup';
import axios from '../../../common/AxiosConfig';
import { AppContext } from '../../../../RoutesWithAuth';
import { useHistory } from 'react-router-dom';
import { v2UIRoutePath } from '../../../common/commons';
import Dialog from '../Dialog';
const CANCELLED_STATUS_WARNING = 'This action will cancel this request in CPS and will not be send to downstream or upstream systems. Click Submit again to confirm and proceed';
const DELETED_STATUS_WARNING = 'This action will delete this request from CPS permanently and this action cannot be revoked. Click Submit again to confirm and proceed';

const useStyles = makeStyles(() => ({
    dialogTitle: {
        padding: '0px',
        '& .MuiButtonBase-root': {
            color: 'white',
            marginTop: '-10px'
        },
    },
    dialogheader: {
        minHeight: 28,
        padding: "10px",
        // textAlign: 'center',
        background: "#7395d3",
        fontSize: '1rem',
        color: "white",

        '& span': {
            fontWeight: 'bold'
        },
        '& .modelheader': {
            paddingBottom: '5px',
        }
    },
    formWrapperClassName: {
        '& .checkboxcmp' :{
            paddingLeft : "25px"
        },
        '& .checkboxcmp label' :{
            width : '45% !important'
        },
        '& label': {
            display: "inline-block",
            width: "150px !important"
        },
        '& .MuiFormControl-fullWidth': {
            width: "50%"
        }
    }
}));

const StatusUpdate = ({
    hideComponent,
    isLoading,
    data,
    dsarRequestId,
    setDisplayErrMsg,
    setDisplaySuccessMsg,
    setIsLoading
}) => {
    const [formValidation, setFormValidation] = useState("");
    const context = useContext(AppContext)
    const authToken = context.authToken.get;
    const loggedInUserId = context.userId.get;
    const setReloadHealth = context.reloadHealth.set;

    const history = useHistory();

    const classes = useStyles();
    const currentStatus = data?.status || "";
    const getStatusOptions = () => {
        let options = [];
        if (currentStatus === "Pre Open"){
            options = ["Cancelled", "Deleted"]
        } else if(currentStatus === "Open") {
            options = ["Pre Open","Cancelled", "Deleted"]
        } else if (currentStatus === "Cancelled") {
            options =  ["Deleted"]
        } else if (["Closed", "Review", "Uploaded", "Upload Failed"].findIndex((rec)=>rec===currentStatus) !== -1) {
            options = ['Cancelled', 'Closed', 'Deleted', 'Review', 'Uploaded', 'Upload Failed'];
        }
        options = options.filter((data)=>data !== currentStatus);
        return options;
    }
    const handleClearForm = () => {
        setFormValidation("");
    }
    const handleFormSubmit = (formValues) => {
        if (formValues) {
            if (!formValues.status || !formValues.reason || (formValues.comments || "").length < 10) {
                setFormValidation("Status, Update Reason and minimum 10 character comments are mandatory for status update");
            } else if ((formValues.comments || "").includes(":")) {
                setFormValidation(" ':' character is not allowed in comments");
            } else {
                setFormValidation("");
                preCheckProcessStatusUpdate(formValidation, formValues);
            }
        }
    }
    const preCheckProcessStatusUpdate = (currentError, formValues) => {
        if (formValues.status === "Cancelled" && currentError !== CANCELLED_STATUS_WARNING) {
            setFormValidation(CANCELLED_STATUS_WARNING);
        } else if (formValues.status === "Deleted" && currentError !== DELETED_STATUS_WARNING) {
            setFormValidation(DELETED_STATUS_WARNING);
        } else {
            processStatusUpdate(formValues)
        }
    }
    const processStatusUpdate = (formValues) => {
        let api = '/ticket/update_status';
        let payload = {
            "dsar_id": dsarRequestId,
            "status": formValues.status,
            "cancellation_reason": formValues.reason,
            "cancellation_reason_other": formValues.comments,
            "userId": loggedInUserId
        }
        if (formValues.status === "Deleted") {
            api = "/delete_request";
            payload = {
                dsar_id: dsarRequestId,
                reason: formValues.reason,
                comments: formValues.comments
            }
        }
        if(formValues.status === "Closed"){
            payload["reconsolidatecheck"] = formValues.reconsolidatedCheck;
        }
        setIsLoading(true);
        axios.post(api, payload, {
            headers: {
                Authorization: authToken,
            },
        }).then((res) => {
            setIsLoading(false);
            setDisplaySuccessMsg("Status Update is completed");
            setTimeout(() => {
                hideComponent(true);
                setReloadHealth(Math.round(Math.random() * 1e5));
                if(formValues.status === "Deleted"){
                    history.push(v2UIRoutePath + "");
                }
            }, 300);

        }).catch((err) => {
            setIsLoading(false);
            if (err?.response?.data?.message) {
                setDisplayErrMsg(err.response.data.message)
            }
        });
    }
    return (
        <Dialog
        isOpen={true}
        updateDialogStatus={() => {
            hideComponent(false);
        }}
        dialogTitleClassName={classes.dialogTitle}
        isForm={true}
        isLoading={isLoading}
        dialogContent={<FormGroup
            formWrapperClassName={classes.formWrapperClassName}
            refreshGridWithSearch={handleFormSubmit}
            handleClearForm={handleClearForm}
            formError={formValidation}
            cancelButtonText={"Clear"}
            fields={[
                {
                    type: 'multiselect',
                    name: 'status',
                    label: 'Select Status :',
                    isMulti: false,
                    placeholder: "Select Status",
                    options: getStatusOptions(),
                    size: 12,
                    removeCheckBox: true,
                }, {
                    type: 'multiselect',
                    name: 'reason',
                    label: 'Update Reason :',
                    isMulti: false,
                    size: 12,
                    placeholder: "Select Update Reason",
                    removeCheckBox: true,
                    options: ['Duplicate request created by TIPS', 'Manual review completed', 'Test request', 'Other'],
                }, {
                    type: 'text',
                    name: 'comments',
                    label: 'Comments :',
                    size: 12
                },{
                    type : 'checkbox',
                    name : 'reconsolidatedCheck',
                    label : 'Re-Consolidate upstream response',
                    defaultOption : true,
                    hiddenCallback : (formData)=>{
                        return !(formData && formData.status === "Closed");
                    },
                    size :12,
                    customInputCls : "checkboxcmp"
                }
            ]}
        />}
        title={(
            <div className={classes.dialogheader}>
                        <div className='modelheader'>Status update</div>
                      
                    </div>
        )}
    />
      
    )
}
export default StatusUpdate;