/**
 * Reports Component - Main Component For Rendering the Reports Page
 * - This Component renders all the reports in the page.
 */
import React, { useState, useEffect } from 'react';
import { withNavbar } from './Navbar';
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import { Grid, Tab, Tabs } from '@material-ui/core';
import AgingReport from './reports/AgingReport';
import AverageProcessingTime from './reports/averageprocessingtime';
import AutoClosedReport from './reports/AutoClosedReport';
import RequestCountReport from './reports/requestcountreport';
import ReconcilationReport from './reports/reconcilationreport';
import DownstreamChart from './reports/downstreamChart';
import { v2UIRoutePath } from '../../common/commons';
import PendingAttachmentsReport from './reports/PendingAttachmentsReport';
import CaliforniaReport from './reports/californiaconsumerreport';
import CancelledOrDeletedRequestReport from './reports/cancelledordeletedrequests';
import TrendingReport from './reports/requeststrendingreport';
import ErrorReport from './reports/ErrorReport';
import ManualActionsRequestReport from './reports/manualactionrequests';

const VisibleReports = [
    'Auto Closed Report',
    'Average Processing Time',
    'California Consumer Report',
    'Cancelled / Deleted Requests',
    'Downstream Report',
    'Error Report',
    'Manual Action Requests',
    'Pending Attachments Report',
    'Reconciliation Report',
    'Request Count Report',
    'Requests Aging Report',
    'Requests Trending Report'
];
const useStyles = makeStyles(() => ({
    homePageWrapper: {
        fontSize: '0.8rem',
        height: "100%",
        margin: "0 10px 0px 10px",
        '& .tabHeader': {
            padding: '10px 0px 5px 10px',
            fontSize: '1rem',
            fontWeight: 'bold',
            borderBottom: '1px solid #dedfdf',
            marginBottom: '5px',
            marginRight: '10px',
            '& a': {
                color: '#212529'
            }
        },
        '& .ashyperlink': {
            color: "#007bff",
            cursor: "pointer"
        }
    },
    adminTabWrapper: {
        '& .MuiGrid-item': {
            background: '#f8f9fa',
        },
        background: '#f8f9fa',
        boxShadow: "0px 0px 0.8px 0px rgb(0 0 0 / 40%)",
        height: "calc( 100% - 40px)"
    },
    headerTitle: {
        textAlign: "center",
        flexGrow: 1,
        fontWeight: "bold",
        marginTop: '20px',
        marginBottom: '14px'
    },
    verticalTabGridItem: {
        maxWidth: '238px',
    },

    hrTabGridItem: {
        marginRight: '10px',
        width: 'calc( 100% - 280px)'
    },
    verticalTabWrapper: {
        borderRight: "1px solid #dedfdf",
        margin: "0px 10px 10px 0px",
        padding: "10px",
        height: '100%',
        '& .MuiTab-wrapper': {
            alignItems: 'flex-start',
            textTransform: 'capitalize'
        },
        '& .MuiTab-root': {
            background: "#dae3f3",
            marginBottom: '5px',
            outline: '0px',
            minHeight: '32px',
        },
        '& .MuiTabs-indicator': {
            left: '0px',
            background: '#7f9ed7',
            width: '4px',
            height: '35px',
        }
    },
    appsScopeWrapper: {
        '& .scopeHeader': {
            padding: '10px 20px',
            fontSize: '1rem',
            fontWeight: 'bold'
        },
        '& .MuiTab-root.Mui-selected': {
            background: "white",
        },
        '& .MuiTab-root': {
            background: "#dae3f3",
            marginRight: '5px',
            outline: '0px',
            '& .MuiTab-wrapper': {
                fontWeight: 'bold',
            },
        },
        '& .MuiTabs-indicator': {
            left: '0px',
            height: '3px'
        }
    }
}));
// reportTabs variable Holds all the Tabs available in the Reports Page
const reportTabs = [
    "autoclosedreport",
    "averageprocessingtime",
    "californiaconsumerreport",
    "cancelleddeletedrequests",
    "downstreamreport",
    "errorreport",
    "manualactionrequests",
    "pendingattachmentreport",
    "reconciliationreport",
    "requestcountreport",
    "requestsagingreport",
    "requeststrendingreport"
];

function Reports(props) {
    const classes = useStyles();
    const [verticalTab, setVerticalTab] = useState(0);
    const { routerProps } = props
    const { history, match } = routerProps;
    /**
     * Updating the active Tab when there is change in the route param.
     */
    useEffect(() => {
        // Updating active tab based on the route param
        if (match?.params?.reportname) {
            setVerticalTab(reportTabs.indexOf(match?.params?.reportname));
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[match])
    const reportsActiveTab = reportTabs[verticalTab];
    return (
        <div className={`pl-2 pr-2 ${classes.homePageWrapper}`}>
            <Typography variant="h6" className={classes.headerTitle}>
                Reporting
            </Typography>
            <Grid container className={classes.adminTabWrapper}>
                <Grid item xs className={classes.verticalTabGridItem}>
                    <div className={classes.verticalTabWrapper}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={verticalTab}
                            onChange={(_event, newValue) => {
                                setVerticalTab(newValue);
                                // redirecting and updating the location based on the selected tab in the report page
                                const path = reportTabs[newValue];
                                history.push(`${v2UIRoutePath}reports/${path}`)
                            }}
                            aria-label="Vertical tabs"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            {VisibleReports.map((report_label) => {
                                return (<Tab label={report_label} key={report_label} />)
                            })}
                        </Tabs>
                    </div>
                </Grid>
                <Grid item xs className={classes.hrTabGridItem}>
                    {reportsActiveTab === "requestsagingreport" && (
                        <div>
                            <AgingReport />
                        </div>
                    )}
                    {reportsActiveTab === "autoclosedreport" && (
                        <AutoClosedReport />
                    )}
                    {reportsActiveTab === "averageprocessingtime" && (
                        <div>
                            <AverageProcessingTime />
                        </div>
                    )}
                    {reportsActiveTab === "requestcountreport" && (
                        <div>
                            <RequestCountReport />
                        </div>
                    )}
                    {reportsActiveTab === "requeststrendingreport" && (
                        <div>
                            <TrendingReport />
                        </div>
                    )}
                    {reportsActiveTab === "californiaconsumerreport" && (
                        <div>
                            <CaliforniaReport />
                        </div>
                    )}
                    {reportsActiveTab === "cancelleddeletedrequests" && (<CancelledOrDeletedRequestReport tabActiveParam={match?.params?.tab}/>)}
                    {reportsActiveTab === "manualactionrequests" && (<ManualActionsRequestReport tabActiveParam={match?.params?.tab}/>)}
                    {reportsActiveTab === "downstreamreport" && (<DownstreamChart />)}
                    {reportsActiveTab === "errorreport" && (<ErrorReport tabActiveParam={match?.params?.tab}/>)}
                    {reportsActiveTab === "pendingattachmentreport" && (<PendingAttachmentsReport />)}
                    {reportsActiveTab === "reconciliationreport" && (<ReconcilationReport tabActiveParam={match?.params?.tab} />)}
                </Grid>
            </Grid>
        </div>
    );
}

export default withNavbar(Reports, 2);
