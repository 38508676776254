import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import { CSSTransition } from 'react-transition-group';
import ContactCard from './ContactCard';
import { withNavbar } from '../../common/Navbar';
import axios from '../../common/AxiosConfig';
import PageHeader from '../../common/PageHeader';


function ContactsTab() {
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get

    const [contacts, setContacts] = useState([])

    useEffect(() => {
        if (authToken) {
            axios.get('/contacts', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setContacts(res.data)
            }).catch(err => {
                console.log(err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken])

    return (
        <div className="mb-5 container">

            <PageHeader header="Contacts" newUIHref={"/admin/catalog"}/>

            <CSSTransition in={contacts.length > 0} appear={true} timeout={125} classNames="swipeR">
                <div>
                    {contacts.map(contact => (
                        <ContactCard key={contact.app_name} authToken={authToken} isAdmin={isAdmin} contact={contact} setContacts={setContacts} />
                    ))}
                </div>
            </CSSTransition>

        </div>
    );
}

export default withNavbar(ContactsTab, 2);
