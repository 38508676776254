import * as React from "react";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { capitalize, unstable_useId as useId } from "@material-ui/core/utils";
import { makeStyles } from "@material-ui/styles";

import {
  filterableGridColumnsSelector,
  GridCloseIcon,
  GridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

const useStyles = makeStyles(
  {
    root: {
      display: "flex",
      justifyContent: "space-around",
      padding: 8,
      '& .MuiInputBase-formControl': {
        fontSize: '0.8rem'
      }

    },
    linkOperatorSelect: {
      minWidth: 60
    },
    columnSelect: {
      width: 150
    },
    operatorSelect: {
      width: 120
    },
    filterValueInput: {
      width: 190
    },
    closeIcon: {
      flexShrink: 0,
      justifyContent: "flex-end",
      marginRight: 6,
      marginBottom: 2
    }
  },
  { name: "MuiGridFilterForm" }
);

export function GridFilterForm(props) {
  const componentReferenceId = "mui-filterInputField";

  React.useEffect(() => {

    if (componentReferenceId) {
      const cmp = document.getElementById(`${componentReferenceId}`);
      if (cmp) {
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          cmp?.focus();
        }, 0);
      }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

  const {
    item,
    deleteFilter,
    applyFilterChanges
  } = props;
  const classes = useStyles();

  const apiRef = React.useContext(GridApiContext);

  const filterableColumns = useGridSelector(
    apiRef,
    filterableGridColumnsSelector
  );
  const columnSelectId = useId();
  const columnSelectLabelId = useId();
  const operatorSelectId = useId();
  const operatorSelectLabelId = useId();

  const getCurrentColumn = React.useCallback(() => {
    if (!item.columnField) {
      return null;
    }
    return apiRef?.current.getColumn(item.columnField);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[apiRef, item]);

  const getCurrentOperator = React.useCallback(() => {
    const currentColumn = getCurrentColumn();
    if (!item.operatorValue || !currentColumn) {
      return null;
    }
    return currentColumn.filterOperators?.find(
      (operator) => operator.value === item.operatorValue
    );
// eslint-disable-next-line react-hooks/exhaustive-deps
},[item, getCurrentColumn]);

  const changeOperator = React.useCallback(
    (event) => {
      const operatorValue = event.target.value;
      applyFilterChanges({
        ...item,
        operatorValue
      });
    },
    [applyFilterChanges, item]
  );
  const handleDeleteFilter = React.useCallback(() => {
    deleteFilter(item);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[deleteFilter, item]);

  const currentOperator = getCurrentOperator();
  return (
    <div className={classes.root}>
      <FormControl variant="standard" className={classes.closeIcon}>
        <IconButton
          aria-label={apiRef?.current.getLocaleText(
            "filterPanelDeleteIconLabel"
          )}
          title={apiRef?.current.getLocaleText("filterPanelDeleteIconLabel")}
          onClick={handleDeleteFilter}
          size="small"
        >
          <GridCloseIcon fontSize="small" />
        </IconButton>
      </FormControl>
      <FormControl variant="standard" className={classes.columnSelect}>
        <InputLabel htmlFor={columnSelectId} id={columnSelectLabelId}>
          {apiRef?.current.getLocaleText("filterPanelColumns")}
        </InputLabel>
        <Select
          labelId={columnSelectLabelId}
          id={columnSelectId}
          disabled
          value={item.columnField || ""}
          native
        >
          {filterableColumns.map((col) => (
            <option key={col.field} value={col.field}>
              {col.headerName || col.field}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" className={classes.operatorSelect}>
        <InputLabel htmlFor={operatorSelectId} id={operatorSelectLabelId}>
          {apiRef?.current.getLocaleText("filterPanelOperators")}
        </InputLabel>
        <Select
          labelId={operatorSelectLabelId}
          id={operatorSelectId}
          value={item.operatorValue}
          onChange={changeOperator}
          native
        >
          {getCurrentColumn()?.filterOperators?.map((operator) => (
            <option key={operator.value} value={operator.value}>
              {operator.label ||
                apiRef?.current.getLocaleText(
                  `filterOperator${capitalize(operator.value)}`
                )}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="standard" className={classes.filterValueInput}>
        {getCurrentColumn()?.customSingleSelectFilter ? (
          <>
            <InputLabel shrink={true} htmlFor={componentReferenceId} id={componentReferenceId}>
              {apiRef?.current.getLocaleText("filterPanelInputLabel")}
            </InputLabel>
            <Select
              labelId={componentReferenceId}
              id={componentReferenceId}
              value={item.value}
              onChange={(event) => {
                const value = event.target.value;
                applyFilterChanges({
                  ...item,
                  value
                });
              }}
              native
            >
              <option style={{ display: "none" }} > </option>
              {(getCurrentColumn()?.filterOptions || getCurrentColumn()?.valueOptions)?.map((valueOption) => (
                <option key={valueOption} value={valueOption}>
                  {valueOption}
                </option>
              ))}
            </Select>
          </>
        ) : (<>
          {currentOperator?.InputComponent ? (
            <currentOperator.InputComponent
              apiRef={apiRef}
              item={item}
              applyValue={applyFilterChanges}
              {...currentOperator.InputComponentProps}
              InputProps={{
                id: componentReferenceId
              }}
            />
          ) : null}
        </>)}

      </FormControl>
    </div>
  );
}
