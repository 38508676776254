import moment from "moment"

export const feedbackTimeout = 4000

export const removeFeedback = (elementId) => {
    // Remove the feedback after a specified timeout period
    setTimeout(
        function () {
            const field = document.getElementById(elementId)
            if (!field) { return }
            field.innerHTML = ""
        }, feedbackTimeout
    )
}

export const hideModal = (id) => {
    const modal = document.getElementById(id)
    modal.classList.remove('show')
    const backdrop = document.getElementsByClassName('modal-backdrop')
    document.body.removeChild(backdrop[0])
}

export const copyToClipboard = (json) => {
    const clip = document.createElement('textarea');
    clip.value = JSON.stringify(json, null, 4)
    document.body.appendChild(clip);
    clip.select();
    document.execCommand('copy');
    document.body.removeChild(clip);
}

export const parseBool = (value) => {
    if (!value) { return null }
    else if (value.toLowerCase() === 'true') { return true }
    else if (value.toLowerCase() === 'false') { return false }
    else { return null }
}

export const switchTabs = async (locationHash) => {
    const makeInactive = (items) => {
        Object.keys(items).forEach((item) => {
            items[item].classList.remove("active", "show")
        })
    }

    const hash = locationHash
    const tabs = document.querySelectorAll("ul.nav-tabs > li > a")
    const panes = document.querySelectorAll(".tab-pane")
    makeInactive(panes)
    makeInactive(tabs)
    document.getElementById(`${hash}-tab`).classList.add("active")
    document.getElementById(`${hash}`).classList.add("active", "show")
}

export const getResponseConfig = (action) => {
    return {
        '': {
            radioActions: [
                'Record Returned',
                'Record not Found',
                'Unable to Retrieve',
                'Data Exempt by Policy',
            ],
            attachmentAccept: '*',
            attachmentType: 'attachment',
            uploadLabel: `${action} Attachments`
        },
        'Right-to-Correct': {
            radioActions: [
                'Record Returned',
                'Record not Found',
                'Unable to Retrieve, Due to Error',
                'Data Exempt by Policy',
            ],
            attachmentAccept: '*',
            attachmentType: 'attachment',
            uploadLabel: `${action} Attachments`
        },
        'Right-to-Know-Detailed': {
            radioActions: [
                'Record Returned',
                'Record not Found',
                'Unable to Retrieve, Due to Error',
                'Data Exempt by Policy',
            ],
            attachmentAccept: '*',
            attachmentType: 'attachment',
            uploadLabel: `${action} Attachments`
        },
        'Right-to-Delete': {
            radioActions: [
                'Record Deleted',
                'Record not Found',
                'Unable to Delete, Due to Error',
                'Data Exempt by Policy',
            ],
            attachmentAccept: 'image/*',
            attachmentType: 'screenshot',
            uploadLabel: `${action} Screenshots`
        },
        'Right-to-OptOut': {
            radioActions: [
                'Opt Out Performed Successfully',
                'Record not Found',
                'Unable to Opt Out, Due to Error',
                'Data Exempt by Policy',
            ],
            attachmentAccept: 'image/*',
            attachmentType: 'screenshot',
            uploadLabel: `${action} Screenshots`
        }
    }
}

export const responseMapping = [
    'success',
    'not_found',
    'error',
    'data_exempt'
]

export const queryDefaults = {
    "searchQuery": "",
    "fromDate": "",
    "toDate": "",
    "includeOpen": true,
    "includeClosed": true,
    "showAdvSearch": false,
    "includeUploaded": true,
    "includeUploadFailed": true,
    "includeCancelled": true,
    "searchUserId": "",
    "searchAssignedTo": "",
    "searchCaliCust": "",
    "searchTicketType": "",
    "searchRequestContains": "",
}

export const RequestorMapping = {
    B2B: 'B2B',
    CUSTOMER: 'Customer',
    EMPLOYEE: 'Employee',
    TCICUSTOMER: 'TCI Customer',
    SURVIVOR: 'Survivor'
}
export const RequestTypeMapping = {
    "ISR": "ISR",
    "Right-to-Correct": "Right to Correct",
    "Right-to-Create": "Right to Create",
    "Right-to-Delete": "Right to Delete",
    "Right-to-Know-Detailed": "Right to Know",
    "Right-to-OptOut": "Right to OptOut",
    "Right-to-Terminate": "Right to Terminate"
}

export const ticketTypeRTK = "Right-to-Know-Detailed"
export const ticketTypeRTD = "Right-to-Delete"

export const DATEFORMATUTC = "MMM Do, YYYY HH:mm:ss (UTC)";
export const DATEFORMATLOCAL = "MMM Do, YYYY HH:mm:ss [(local)]";
export const Scope_Not_Applicable = 'Not Applicable';
export const v2UIRoutePath = '/';

export const getFormatedDate = (date, isUTC) => {
    if (!date) {
        return null;
    }
    if (isUTC) {
        return moment(date)
            .utc()
            .format(DATEFORMATUTC)
    } else {
        return moment(date)
            .local()
            .format(DATEFORMATLOCAL)
    }
}