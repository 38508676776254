import React, { useEffect, useContext, useState, useRef } from 'react';
import { AppContext } from './RoutesWithAuth';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import axios from './components/common/AxiosConfig';
import IdleTimer from 'react-idle-timer';
import { Auth } from 'aws-amplify';

import { getAuthToken, returnable, loadingScreen } from './components/common/AuthHelper';

import NewHomeUI from './components/routes/newUI/Home';
import NewAdminUI from './components/routes/newUI/Admin';
import Reports from './components/routes/newUI/Reports';
import Sessiontimeout from './components/common/Sessiontimeout';
import { ThemeProvider } from '@material-ui/core';
import newUITheme from './components/routes/newUI/theme';
import { v2UIRoutePath } from './components/common/commons';
import Tracking from './components/routes/newUI/tracking';
import RequestDetailView from './components/routes/newUI/requestdetails/DetailsView';
import OldUIRouter from './OldUIRouter';

function Routes(props) {
    const { authState } = props;

    const context = useContext(AppContext);

    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get
    const validUserContext = context.validUser.get
    const [usersList, setUsersList] = useState(null)
    const [modelIsOpen, setModelIsOpen] = useState(false);
    const IdleTimerRef = useRef(null);
    const sessionTimeOutRef = useRef(null);
    const [seconds, setSeconds] = useState(60);
    const [loading, setLoading] = useState(false);
    const version = sessionStorage.getItem("version");
    let isnewVersionOnly = true;
    if (version && version === "old") {
        isnewVersionOnly = false
    }
    useEffect(() => {
        if (seconds > 0 && modelIsOpen) {
            setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
        } else {
            setSeconds(60);
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[seconds, modelIsOpen])
    const onIdle = () => {
        setModelIsOpen(true);
        sessionTimeOutRef.current = setTimeout(signOut, (60 * 1000 * 1));
    }
    const stayActive = () => {
        setSeconds(60);
        IdleTimerRef.current.reset();
        setModelIsOpen(false);
        clearTimeout(sessionTimeOutRef.current);
    }

    const signOut = () => {
        Auth.signOut().catch(err => {
            console.log(err)
        })
    }
    const loadAppDetails = () => {
        axios.get('apps/all', {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            let appsData = res.data;
            appsData.sort((a, b) => {
                const x = a.display.toLowerCase();
                const y = b.display.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            })
            context.apps.set(appsData)
        }).catch(err => {
            context.apps.set([])
            console.log('There was an error fetching App Details!', err)
        })
    }
    useEffect(() => {
        if (authToken !== null) {
            setLoading(true);
            loadAppDetails();
            axios.get('users', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setUsersList(res.data)
                setLoading(false);
            }).catch(err => {
                setLoading(false);
                console.log('There was an error fetching users!', err)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, isAdmin])

    useEffect(() => {
        if (context.authToken.get === null) {
            setLoading(true);
            getAuthToken(context)
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[context])

    if (loading) {
        return loadingScreen();
    }

    if (authState !== 'signedIn' && validUserContext !== "exists") {
        return null
    }

    const temp = returnable(context, usersList)
    if (temp != null) {
        return temp
    }


    return (
        <div className="">
            {modelIsOpen && <Sessiontimeout stayActive={stayActive} modelIsOpen={modelIsOpen} seconds={seconds} />}
            <ThemeProvider theme={newUITheme}>
                {isnewVersionOnly ? (<Router>
                    <Switch >
                        <Route exact path={v2UIRoutePath} render={(r) => <NewHomeUI routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "requestdetails/:dsarRequestId"} render={(r) => <RequestDetailView routerProps={r} />} />
                        <Route exact path={v2UIRoutePath+"admin"} render={(r) => <NewAdminUI routerProps={r} />} />
                        <Route exact path={v2UIRoutePath+"admin/:page"} render={(r) => <NewAdminUI routerProps={r} />} />
                        <Route exact path={v2UIRoutePath+"admin/:page/:tab"} render={(r) => <NewAdminUI routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "page/:page"} render={(r) => <NewHomeUI routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "reports"} render={(r) => <Reports routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "reports/:reportname"} render={(r) => <Reports routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "reports/:reportname/:tab"} render={(r) => <Reports routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "tracking"} render={(r) => <Tracking routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "tracking/:page"} render={(r) => <Tracking routerProps={r} />} />
                        <Route exact path={v2UIRoutePath + "tracking/:page/:id"} render={(r) => <Tracking routerProps={r} />} />

                    </Switch>
                </Router>) :
                    (<Router>
                        <Switch >
                            <OldUIRouter />
                        </Switch>
                    </Router>)}
            </ThemeProvider>
            <IdleTimer
                ref={IdleTimerRef}
                timeout={60 * 1000 * 59}
                onIdle={onIdle}
            />
        </div>
    );

}

export default Routes;
