import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../RoutesWithAuth";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { UncontrolledTooltip } from "reactstrap";
import axios from "../../common/AxiosConfig";
import moment from "moment";
import AppsInfo from "./AppsInfo";
import PageHeader from "../../common/PageHeader";
import { withNavbar } from "../../common/Navbar";
import { removeFeedback, DATEFORMATUTC, DATEFORMATLOCAL } from "../../common/commons";
import "../../../css/ticketDetails.css";
import "../../../css/smallComponents.css";
import { makeStyles } from "@material-ui/core/styles";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);
const useStyles = makeStyles({
  requestDetails: {
    '& button': {
      minWidth: 230
    }
  }
})

const StatusChangeErrorDomId = 'status-change-error';
const ErrorDeletionReason = 'Reason too short. Insufficient characters in reason for deletion';
const cardCalcHeight = 'calc(100% - .5rem)';

function RequestDetails(props) {
  const classes = useStyles();
  const { history } = props;
  const { dsarRequestId } = props.match.params;

  const context = useContext(AppContext);
  const authToken = context.authToken.get;
  const isAdmin = context.isAdmin.get;
  const userId = context.userId.get;

  const [systems, setSystems] = useState([]);
  const [requestor, setRequestor] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [subjectNames, setSubjectNames] = useState([]);
  const [subjectEmails, setSubjectEmails] = useState([]);
  const [consumerProfileData, setConsumerProfileData] = useState([]);
  const [consumerTypes, setConsumerTypes] = useState([]);
  const [dsarRequestType, setDsarRequestType] = useState("");
  const [dsarForm, setDsarForm] = useState("");
  const [createdDate, setCreatedDate] = useState("");
  const [personas, setPersonas] = useState("");
  const [creator, setCreator] = useState(null);
  const [creatorId, setCreatorId] = useState(null);
  const [assignee, setAssignee] = useState("");
  const [workflow, setWorkflow] = useState("");
  const [responseBuilt, setResponseBuilt] = useState(true);
  const [requestStatus, setRequestStatus] = useState("");
  const [caliCustomer, setCaliCustomer] = useState(false);
  const [language, setLanguage] = useState("");
  const [responseGeneratedTime, setResponseGeneratedTime] = useState(null);
  const [dataUploadedTime, setDataUploadedTime] = useState(null);
  const [note, setNote] = useState(null);
  const [noteUpdatedBy, setNoteUpdatedBy] = useState(null);
  const [noteUpdatedTime, setNoteUpdatedTime] = useState(null);
  const [deleteTicketFlag, setDeleteTicketFlag] = useState(false);
  const [deleteSuccessFlag, setDeleteSuccessFlag] = useState(false);

  const [reassignMode, setReassignMode] = useState(false);
  const [users, setUsers] = useState([]);
  const [newAssignee, setNewAssignee] = useState("");

  const [cancelledReason, setCancelledReason] = useState(null);
  const [cancelledBy, setCancelledBy] = useState(null);
  const [cancelledTime, setCancelledTime] = useState(null);

  const [deletionReason, setDeletionReason] = useState(null);

  // Related to cancellation FORM
  const [statusChangeMode, setStatusChangeMode] = useState(false);
  const [newStatus, setNewStatus] = useState("Upload Failed");
  const [cancellationReason, setCancellationReason] = useState(
    "Request Cancelled by Customer"
  );
  const [cancellationReasonOther, setCancellationReasonOther] = useState("");

  const [updateNoteMode, setUpdateNoteMode] = useState(false);
  const [newNote, setNewNote] = useState("");

  const [doesNotExist, setDoesNotExist] = useState(false);

  useEffect(() => {
    calculateExpandedHeight();
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);
  const fetchData = () => {
    axios
      .get(`app_tickets/${dsarRequestId}`, {
        headers: {
          Authorization: authToken,
        },
      })
      .then((res) => {
        let appInfo = res.data;
        appInfo = appInfo.sort((a, b) => a.display.localeCompare(b.display))
        setSystems(appInfo);
      })
      .catch((err) => {
        console.log("There was an error fetching sub-system data!");
      });

    axios
      .get(`ticket/search/`, {
        headers: {
          Authorization: authToken,
        },
        params: {
          id: dsarRequestId,
          include_open: true,
          include_closed: true,
          exact_match: true,
        },
      })
      .then((res) => {
        // Handle the case where the request does not exist
        if (res.data.length === 0) {
          setDoesNotExist(true);
          return;
        }

        setCaliCustomer(res.data[0].cali_customer);
        setLanguage(res.data[0].language || "");
        setPersonas(res.data[0].personas || "");
        setSubjectNames(res.data[0].dsar.dsarForm.subjectNames);
        setSubjectEmails(res.data[0].dsar.dsarForm.subjectEmails);
        setDsarForm(res.data[0].dsar.dsarForm);
        setConsumerProfileData(res.data[0].dsar.consumerProfileData);
        setConsumerTypes(res.data[0].dsar.consumerTypes || []);
        setDsarRequestType(res.data[0].ticket_type);
        setCreatedDate(res.data[0].created_date);
        setAssignee(res.data[0].assignee);
        setRequestor(res.data[0].requestor);
        setWorkflow(res.data[0].workflow);
        setResponseGeneratedTime(res.data[0].processing_start_time);
        setRequestStatus(res.data[0].status);
        setDataUploadedTime(res.data[0].tips_ack_time);
        setCreatorId(res.data[0].creator_id);
        setCreator(res.data[0].creator);
        setNote(res.data[0].note);
        setNoteUpdatedTime(res.data[0].note_updated_time);
        setNoteUpdatedBy(res.data[0].note_updated_by);
        setCancelledReason(res.data[0].cancellation_reason);
        setCancelledTime(res.data[0].cancellation_time);
        setCancelledBy(res.data[0].cancelled_by);

        // If a response has been built, then set the state
        if (!res.data[0].response_summary) {
          setResponseBuilt(false);
        }
      })
      .catch((err) => {
        console.log("There was an error fetching sub-system data!");
        console.log(err);
      });
  }
  useEffect(() => {
    if (authToken !== null) {
      fetchData();

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken]);

  // Fetch the users once reassignMode is entered
  useEffect(() => {
    if (reassignMode === true && authToken && !users.length) {
      // Get the list of all users
      axios
        .get("users", {
          headers: {
            Authorization: authToken,
          },
        })
        .then((res) => {
          if (res.data.length) {
            setUsers(res.data.filter(user => user.user_status === 'Y'))
          }
        })
        .catch((err) => {
          console.log("There was an error fetching users!", err);
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
// eslint-disable-next-line react-hooks/exhaustive-deps
},[reassignMode]);

  const viewAuditTrail = () => {
    history.push(`/ticket/${dsarRequestId}/auditTrail`);
  };

  const viewRequest = () => {
    history.push(`/ticket/${dsarRequestId}/viewRequest`);
  };

  const viewFeatureRequestJSON = (appId, request) => {
    history.push(`/ticket/${dsarRequestId}/${appId}/viewrequestjson`);
  };
  const viewResponseFiles = () => {
    history.push(`/ticket/${dsarRequestId}/viewResponseFiles`);
  };

  const downloadDoc = () => {
    axios
      .get(
        `ticket/response/download/${dsarRequestId}/doc/${dsarRequestId}.docx`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        const link = document.createElement("a");
        link.href = res.data.presignedGet;
        link.setAttribute("download", `${dsarRequestId}.docx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const regenerateDoc = () => {
    const regenBtn = document.getElementById("regenerate");
    regenBtn.setAttribute("disabled", true);

    axios
      .post(
        `regenerate/doc`,
        {
          dsar_id: dsarRequestId,
        },
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        console.log(res);
        regenBtn.innerHTML = "Success!";
      })
      .catch((err) => {
        console.log(err);
        regenBtn.innerHTML = "Failed to Generate Doc!";
        regenBtn.classList.replace("btn-success", "btn-danger");
      });
  };

  const downloadTxt = () => {
    axios
      .get(
        `ticket/response/download/${dsarRequestId}/txt/${dsarRequestId}.txt`,
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        const link = document.createElement("a");
        link.href = res.data.presignedGet;
        link.setAttribute("download", `${dsarRequestId}.docx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markDataUploaded = () => {
    const modal = document.getElementById("confirm-mark-uploaded");
    const feedback = document.getElementById("mark-uploaded-feedback");

    axios
      .post(
        `ticket/mark_uploaded`,
        {
          dsar_id: dsarRequestId,
        },
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        setRequestStatus("Uploaded");
        setDataUploadedTime(moment().toISOString());
        modal.classList.remove("show");
        const backdrop = document.getElementsByClassName("modal-backdrop");
        document.body.removeChild(backdrop[0]);
      })
      .catch((err) => {
        feedback.innerHTML = err.response.data.message;
        removeFeedback("mark-uploaded-feedback");
      });
  };

  const reassignRequest = () => {
    const feedback = document.getElementById("reassign-error");

    axios
      .put(
        "/ticket/assign",
        {
          dsar_id: dsarRequestId,
          user_id: newAssignee === "" ? null : newAssignee,
        },
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        setReassignMode(false);
        setAssignee(res.data.assigned_to);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          feedback.innerHTML = err.response.data.message;
          removeFeedback("reassign-error");
        }
      });
  };

  const updateStatus = () => {
    const feedback = document.getElementById(StatusChangeErrorDomId);

    axios
      .post(
        "/ticket/update_status",
        {
          dsar_id: dsarRequestId,
          status: newStatus,
        },
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        setStatusChangeMode(false);
        setRequestStatus(newStatus);
        if (newStatus === "Uploaded") {
          setDataUploadedTime(moment().toISOString());
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          feedback.innerHTML = err.response.data.message;
          removeFeedback(StatusChangeErrorDomId);
        }
      });
  };

  // Because of a problem where Modal instantly called a function when a parameter is passed in, I duplicated the function specifically for cancellation
  const updateCancelledStatus = () => {
    const modal = document.getElementById("confirm-set-cancel-status");
    const feedback = document.getElementById(StatusChangeErrorDomId);

    axios
      .post(
        "/ticket/update_status",
        {
          dsar_id: dsarRequestId,
          status: "Cancelled",
          cancellation_reason: cancellationReason,
          cancellation_reason_other: cancellationReasonOther,
          userId: userId,
        },
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        setStatusChangeMode(false);
        setRequestStatus("Cancelled");
        setCancelledReason(res.data.cancellation_reason);
        setCancelledTime(res.data.cancellation_time);
        setCancelledBy(res.data.cancelled_by);
        modal.classList.remove("show");
        const backdrop = document.getElementsByClassName("modal-backdrop");
        document.body.removeChild(backdrop[0]);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          feedback.innerHTML = err.response.data.message;
          removeFeedback(StatusChangeErrorDomId);
        }
      });
  };

  const deleteRequest = () => {

    const feedback = document.getElementById("deletion-error");

    if (deletionReason == null) {

      feedback.innerHTML = ErrorDeletionReason;
    } else {
      const reasonText = deletionReason.replace(/\s/g, "");

      if (reasonText.length >= 10) {
        axios
          .post(
            "/delete_request",
            {
              dsar_id: dsarRequestId,
              reason: deletionReason,
            },
            {
              headers: {
                Authorization: authToken,
              },
            }
          )
          .then((res) => {
            setDeleteSuccessFlag(true);
            setDeleteTicketFlag(true);
            feedback.innerHTML = "Deletion is completed";
          })
          .catch((err) => {
            console.log(err);
            if (err.response) {

              feedback.innerHTML = ErrorDeletionReason;
            }
          });
      } else {

        feedback.innerHTML = ErrorDeletionReason;
      }
    }
  };

  const onCancelDeleteRequest = () => {
    setDeleteTicketFlag(false);
    const feedback = document.getElementById("deletion-error");
    feedback.innerHTML = "";
    setDeletionReason('');
    if (deleteSuccessFlag) {
      window.location.href = '/';
    }
  }
  const handleConsolidate = () => {
    const errorFeedback = document.getElementById(`invalid-feedback`)
    const successFeedback = document.getElementById(`valid-feedback`)

    axios
      .post(
        "/response/consolidate",
        {
          dsar_id: dsarRequestId
        },
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        successFeedback.innerHTML = res.data.message;
        removeFeedback('valid-feedback')
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          errorFeedback.innerHTML = err.response.data.message
          removeFeedback('invalid-feedback')
        }
      });

  }
  const updateNote = () => {
    const feedback = document.getElementById("update-note-error");
    const clear = document.getElementById("note-clear");
    const done = document.getElementById("note-done");
    clear.setAttribute("disabled", true);
    done.setAttribute("disabled", true);

    axios
      .put(
        `/ticket/${dsarRequestId}/note`,
        {
          note: newNote.trim() !== "" ? newNote : null,
        },
        {
          headers: {
            Authorization: authToken,
          },
        }
      )
      .then((res) => {
        setNote(res.data.new_note);
        setNoteUpdatedTime(res.data.note_updated_time);
        setNoteUpdatedBy(res.data.note_updated_by);
        setUpdateNoteMode(false);
      })
      .catch((err) => {
        if (err.response) {
          feedback.innerHTML = err.response.data.message;
        } else {
          feedback.innerHTML = "Could not update note!";
        }
        removeFeedback("update-note-error");
      })
      .finally(() => {
        clear.removeAttribute("disabled");
        done.removeAttribute("disabled");
      });
  };

  // Initialize the height of the details card
  const calculateExpandedHeight = () => {
    const infoCard = document.getElementById("info");
    const upper = document.getElementById("upper-details");
    const lower = document.getElementById("lower-details");
    infoCard.style.setProperty(
      "--max",
      `calc(${upper.offsetHeight}px + ${lower.offsetHeight}px + 2.5rem)`
    );
    infoCard.style.setProperty(
      "--min",
      `calc(${upper.offsetHeight}px + 2.5rem)`
    );
  };

  const isFeatureAvailable = (featureName) => {
    let featureAvailable = false;
    systems.forEach((element) => {
      if (element.name === featureName) {
        featureAvailable = true;
      }
    });
    return featureAvailable;
  };

  const requestorDetailsSection = () => {
    if (!isCustomerReqestor()) {

      const subjectWorkerObj = {
        subjectWorkerType: [],
        subjectWorkerStatus: [],
        subjectWorkerDetailedStatus: [],
        subjectSupplier: [],
      };
      // eslint-disable-next-line no-unused-expressions
      consumerProfileData?.forEach((d) => {
        // eslint-disable-next-line no-unused-expressions
        d?.communicationInfo?.subjectWorkerInfo?.forEach((swi) => {
          swi["subjectWorkerType"] && subjectWorkerObj["subjectWorkerType"].push(swi["subjectWorkerType"]);
          swi["subjectWorkerStatus"] && subjectWorkerObj["subjectWorkerStatus"].push(swi["subjectWorkerStatus"]);
          swi["subjectWorkerDetailedStatus"] && subjectWorkerObj["subjectWorkerDetailedStatus"].push(swi["subjectWorkerDetailedStatus"])
          swi["subjectSupplier"] && subjectWorkerObj["subjectSupplier"].push(swi["subjectSupplier"])
        });
      });
      return (
        <>
          <p className="mb-0">
            Personas : {personas}
          </p>
          {subjectWorkerObj.subjectWorkerType.length > 0 && (<p className="mb-0">
            Worker Type : {subjectWorkerObj.subjectWorkerType.join(', ')}
          </p>)}
          {subjectWorkerObj.subjectWorkerStatus.length > 0 && (<p className="mb-0">
            Worker Status : {subjectWorkerObj.subjectWorkerStatus.join(', ')}
          </p>)}
          {subjectWorkerObj.subjectWorkerDetailedStatus.length > 0 && (<p className="mb-0">
            Detailed Status : {subjectWorkerObj.subjectWorkerDetailedStatus.join(', ')}
          </p>)}
          {subjectWorkerObj.subjectSupplier.length > 0 && (<p className="mb-0">
            Supplier Name : {subjectWorkerObj.subjectSupplier.join(', ')}
          </p>)}
        </>
      );
    }
    return consumerTypes[0]?.consumerType && (
      <>
        <p className="mb-0">
          Customer Type: {consumerTypes[0].consumerType}
        </p>
        <p className="mb-0">
          Personas : {personas}
        </p>
      </>

    )
  }

  const getSubjectName = (subjectFirstName, firstName) => {

    const subject = subjectNames?.map((s) => { return s[subjectFirstName] }) || [];

    if (isCustomerReqestor()) {
      return subject.join(", ")
    }
    // eslint-disable-next-line no-unused-expressions
    consumerProfileData?.forEach((d) => {
      // eslint-disable-next-line no-unused-expressions
      d?.consumerNames?.forEach((e) => {
        subject.push(e[firstName]);
      });
    });
    // consumerNames -> firstName
    return subject.join(", ");

  }

  const getSubjectEmail = () => {
    const subjectEmail = subjectEmails?.map((s) => { return s.subjectEmail }) || [];

    if (isCustomerReqestor()) {
      return subjectEmail.join(", ")
    }

    // eslint-disable-next-line no-unused-expressions
    dsarForm?.subjectBusinessEmails?.forEach((s) => { subjectEmail.push(s.subjectBusinessEmail); });
    // eslint-disable-next-line no-unused-expressions
    dsarForm?.subjectEmployeeEmails?.forEach((s) => { subjectEmail.push(s.subjectEmployeeEmail); });
    // eslint-disable-next-line no-unused-expressions
    consumerProfileData?.forEach((d) => {
      // eslint-disable-next-line no-unused-expressions
      d?.communicationInfo?.emailInfo?.forEach((e) => {
        subjectEmail.push(e.email);
      });
      // eslint-disable-next-line no-unused-expressions
      d?.communicationInfo?.subjectWorkerInfo?.forEach((e) => {
        subjectEmail.push(e.subjectWorkerEmail);
      });
    });
    return subjectEmail.join(", ")
  }
  const isCustomerReqestor = () => {

    return (!requestor || (requestor && (requestor.toLowerCase() === "customer" || requestor.toLowerCase() === "tci customer")));

  }

  const displayOriginRequestJSON = () => {
    // Returns true when Requestor is employee, b2b and Customer with Request type is RTC.
    return (requestor.toLowerCase() === "employee" || requestor.toLowerCase() === "b2b" || (requestor.toLowerCase() === "customer" && dsarRequestType === "Right-to-Correct"))
  }
  // 
  const getConsumerProfileLinkedIds_bulk = (profile) => {
    const profileMeta = [{
      profileName: 'azureUserIds',
      displayName: 'GUID'
    }, {
      profileName: 'spinIds',
      displayName: 'SPIN'
    }, {
      profileName: 'workdayIds',
      displayName: 'Workday'
    }, {
      profileName: 'applicantIds',
      displayName: 'Applicant ID'
    }, {
      profileName: 'loginIds',
      displayName: 'Login ID'
    }];
    let matchFound = false;
    const profileInfo = profileMeta.map((iprofileMeta) => {
      let linkedIDs = [];
      // eslint-disable-next-line no-unused-expressions
      profile?.linkedIds?.forEach((li) => {
        if (li?.[iprofileMeta.profileName]) {
          linkedIDs = li?.[iprofileMeta.profileName] || [];
        }
      })
      if (linkedIDs.length > 0) {
        matchFound = true;
        return (<p className="mb-0" key={iprofileMeta.profileName}>
          {iprofileMeta.displayName}:{" "}
          {linkedIDs.join(", ")}
        </p>);
      }
      return null;
    });
    return (
      <>
        {matchFound ? <hr /> : null}
        {profileInfo}
      </>
    )

  }
  if (doesNotExist) {
    return (
      <div className="container">
        <div className="jumbotron bg-transparent py-1 px-0 mb-2">
          <h1 className="display-4">
            Request Details
            <p className="lead">
              <Link to={`/`}>Home&nbsp;</Link>
              &gt; Request ID: {dsarRequestId}
            </p>
            <hr />
          </h1>
        </div>

        <p className="h3 text-center mt-3">
          Request Not Found!
          <br />
        </p>
        <p className="text-muted text-center">
          If you just created a request, your request may still be processing.
          <br />
          Please wait a few minutes and refresh the page.
          <br />
          <br />
          If you still don't see any data after an extended period of time,
          submit your original JSON to the CT-CPS Development Team for
          investigation.
        </p>
      </div>
    );
  } else {
    return (
      <div className="container mb-4">
        <PageHeader header="Request Details" removeMargin={true} />
        <p className="lead">
          <Link to={`/`}>Home&nbsp;</Link>
          &gt; Request ID: {dsarRequestId}
        </p>

        <CSSTransition
          in={expanded}
          timeout={300}
          classNames="scrollD"
          onEnter={() => calculateExpandedHeight()}
        >
          <div className="card mb-2 bg-light" id="info" style={{ height: cardCalcHeight }}>
            <div className="card-body">
              <div id="upper-details">
                <p className="mb-0">
                  Request Type: {dsarRequestType}
                  <span
                    className="text-muted expand-data"
                    onClick={() => setExpanded(!expanded)}
                  >
                    &nbsp;
                    {expanded ? (
                      <i className="material-icons align-middle">
                        remove_circle_outline
                      </i>
                    ) : (
                      <i className="material-icons align-middle">
                        add_circle_outline
                      </i>
                    )}
                  </span>
                  <span className="float-right">
                    {isAdmin ? (
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary btn-sm small-button btn-danger"
                          data-toggle="modal"
                          data-target="#delete-request"
                        >
                          Delete Request
                        </button>
                      </div>
                    ) : null}
                  </span>
                </p>
                <p className="mb-0">
                  Requestor: {requestor}
                </p>
                <p className="mb-0">
                  Status:{" "}
                  {statusChangeMode ? (
                    <span>
                      <select
                        className="form-control-sm ml-1"
                        id="newStatus"
                        value={newStatus}
                        onChange={(e) => setNewStatus(e.target.value)}
                      >
                        <option>Closed</option>
                        <option>Review</option>
                        <option>Uploaded</option>
                        <option>Upload Failed</option>
                      </select>
                      <button
                        type="button"
                        className="btn extra-small btn-danger mr-1 ml-2"
                        onClick={() => setStatusChangeMode(false)}
                      >
                        <i className="material-icons small-icon">clear</i>
                      </button>
                      <button
                        type="button"
                        className="btn extra-small btn-success"
                        onClick={updateStatus}
                      >
                        <i className="material-icons small-icon">done</i>
                      </button>
                      <span
                        className="mb-0 small text-danger ml-2"
                        id={StatusChangeErrorDomId}
                      ></span>
                    </span>
                  ) : (
                    <span>
                      {requestStatus}
                      {isAdmin && requestStatus && requestStatus !== "Open" && requestStatus !== "Cancelled" ? (
                        <button
                          className="btn btn-warning extra-small ml-2"
                          onClick={() => setStatusChangeMode(true)}
                        >
                          <i className="material-icons small-icon">edit</i>
                        </button>
                      ) : null}
                    </span>
                  )}
                </p>

                {requestStatus === "Cancelled" ? (
                  <span>
                    <span>Cancellation Reason: {cancelledReason}</span>
                    <br />
                    <span className="small">
                      Cancelled By: {cancelledBy ? `${cancelledBy} on` : null}
                      <span className="ml-1" id="cancelled-date">
                        {moment(cancelledTime)
                          .utc()
                          .format(DATEFORMATUTC)}
                      </span>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="cancelled-date"
                      >
                        {moment(cancelledTime)
                          .local()
                          .format(DATEFORMATLOCAL)}
                      </UncontrolledTooltip>
                    </span>
                  </span>
                ) : null}
              </div>
              <div id="lower-details">
                {expanded ? (
                  <button
                    className="btn btn-primary btn-sm small-button small mt-1"
                    onClick={viewAuditTrail}
                  >
                    View Audit Trail
                  </button>
                ) : null}

                {expanded && !updateNoteMode ? (
                  <span>
                    {note ? (
                      <p>
                        Note: {note}
                        <span>
                          <button
                            className="btn btn-warning extra-small ml-2"
                            onClick={() => {
                              setUpdateNoteMode(true);
                              setNewNote(note);
                            }}
                          >
                            <i className="material-icons small-icon">edit</i>
                          </button>
                        </span>
                        <br />
                        <span className="small">
                          Note Last Updated By:{" "}
                          {noteUpdatedBy ? `${noteUpdatedBy} on` : null}
                          <span className="ml-1" id="note-updated">
                            {moment(noteUpdatedTime)
                              .utc()
                              .format(DATEFORMATUTC)}
                          </span>
                          <UncontrolledTooltip
                            placement="bottom"
                            target="note-updated"
                          >
                            {moment(noteUpdatedTime)
                              .local()
                              .format(DATEFORMATLOCAL)}
                          </UncontrolledTooltip>
                        </span>
                      </p>
                    ) : (
                      <p>
                        <button
                          className="btn btn-primary btn-sm small-button small mt-1"
                          onClick={() => setUpdateNoteMode(true)}
                        >
                          Add Notes
                        </button>
                        <br />
                        {noteUpdatedTime ? (
                          <span className="small">
                            Note Deleted By:{" "}
                            {noteUpdatedBy ? `${noteUpdatedBy} on` : null}
                            <span className="ml-1" id="note-updated">
                              {moment(noteUpdatedTime)
                                .utc()
                                .format(DATEFORMATUTC)}
                            </span>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="note-updated"
                            >
                              {moment(noteUpdatedTime)
                                .local()
                                .format(DATEFORMATLOCAL)}
                            </UncontrolledTooltip>
                          </span>
                        ) : null}
                      </p>
                    )}
                  </span>
                ) : null}
                {updateNoteMode ? (
                  <div>
                    <label htmlFor="note" className="small">
                      Note
                    </label>
                    <div className="d-flex">
                      <textarea
                        className="form-control"
                        id="note"
                        rows="2"
                        placeholder="Add a note for this request"
                        value={newNote}
                        onChange={(e) => {
                          setNewNote(e.target.value);
                        }}
                        required
                      />
                      <div className="d-flex flex-column justify-content-around ml-2">
                        <button
                          type="button"
                          className="btn btn-sm small-button btn-danger"
                          id="note-clear"
                          onClick={() => { setNewNote(''); setUpdateNoteMode(false) }}
                        >
                          <i className="material-icons small-icon">clear</i>
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm small-button btn-success"
                          id="note-done"
                        >
                          <i
                            className="material-icons small-icon"
                            onClick={updateNote}
                          >
                            done
                          </i>
                        </button>
                      </div>
                    </div>
                    <div
                      className="small text-danger ml-2 mt-1"
                      id="update-note-error"
                    />
                  </div>
                ) : null}

                {expanded ? (
                  <div>
                    <hr />
                    {requestorDetailsSection()}
                    <p className="mb-0">
                      California Customer: {caliCustomer ? "Yes" : "No"}
                    </p>
                    <p className="mb-0">Language: {language}</p>
                    <p className="mb-0">Created By: {creator}</p>
                    <p className="mb-0">
                      Created Date:&nbsp;
                      <span id="request-created">
                        {moment(createdDate)
                          .utc()
                          .format(DATEFORMATUTC)}
                      </span>
                      <UncontrolledTooltip
                        placement="bottom"
                        target="request-created"
                      >
                        {moment(createdDate)
                          .local()
                          .format(DATEFORMATLOCAL)}
                      </UncontrolledTooltip>
                    </p>
                    <p className="mb-0">Workflow: {workflow}</p>
                    <p className="mb-0">
                      Assignee:{" "}
                      {reassignMode ? (
                        <span>
                          <select
                            className="form-control-sm ml-1"
                            id="reportType"
                            value={newAssignee}
                            onChange={(e) => setNewAssignee(e.target.value)}
                          >
                            {users.map((user) => {
                              if (!user.is_disabled) {
                                return (
                                  <option
                                    key={user.user_id}
                                    value={user.user_id}
                                  >
                                    {user.first_name} {user.last_name}
                                  </option>
                                );
                              } else {
                                return null;
                              }
                            })}
                            <option value={""}></option>
                          </select>
                          <button
                            type="button"
                            className="btn btn-danger extra-small mr-1 ml-2"
                            onClick={() => setReassignMode(false)}
                          >
                            <i className="material-icons small-icon">clear</i>
                          </button>
                          <button
                            type="button"
                            className="btn btn-success extra-small"
                            onClick={() =>
                              reassignRequest()
                            }
                          >
                            <i className="material-icons small-icon">done</i>
                          </button>
                          <span
                            className="mb-0 small text-danger ml-2"
                            id="reassign-error"
                          ></span>
                        </span>
                      ) : (
                        <span>
                          {assignee}
                          {requestStatus !== "Uploaded" ? (
                            <button
                              className="btn btn-warning extra-small ml-2"
                              onClick={() => setReassignMode(true)}
                            >
                              <i className="material-icons small-icon">edit</i>
                            </button>
                          ) : null}
                        </span>
                      )}
                    </p>
                    {isAdmin &&
                      requestStatus &&
                      requestStatus !== "Cancelled" ? (
                      <button
                        className="btn btn-sm btn-warning mt-1 text-danger small-button small"
                        data-toggle="modal"
                        data-target="#confirm-set-cancel-status"
                      >
                        Cancel Request
                      </button>
                    ) : null}
                    <hr />
                  </div>
                ) : null}

                {expanded ? (
                  <div>
                    <p className="mb-0">
                      First Name:{" "}
                      {getSubjectName("subjectFirstName", "firstName")}
                    </p>

                    <p className="mb-0">
                      Last Name:{" "}
                      {getSubjectName("subjectLastName", "lastName")}
                    </p>

                    <p className="mb-0">
                      Email:{" "}
                      {getSubjectEmail()}
                    </p>
                  </div>
                ) : null}

                {consumerProfileData.map((profile, i) => {
                  if (!expanded) {
                    return null;
                  } else if (isCustomerReqestor()) {
                    return (
                      <div key={profile?.consumerProfileMetadata?.customerId}>
                        {i > 0 ? <hr /> : null}

                        <p className="mb-0">
                          CUSTID:{" "}
                          {profile.consumerProfileMetadata
                            ? profile.consumerProfileMetadata.customerId
                            : null}
                        </p>

                        <p className="mb-0">
                          First Name:{" "}
                          {profile.consumerNames
                            ? profile.consumerNames.map((cn, index) => {
                              if (index === 0) {
                                return cn.firstName;
                              }
                              return ", " + cn.firstName; // Add comma separation
                            })
                            : null}
                        </p>

                        <p className="mb-0">
                          Last Name:{" "}
                          {profile.consumerNames
                            ? profile.consumerNames.map((cn, index) => {
                              if (index === 0) {
                                return cn.lastName;
                              }
                              return ", " + cn.lastName; // Add comma separation
                            })
                            : null}
                        </p>

                        <p className="mb-0">
                          VIN:{" "}
                          {profile.vehicleInfo
                            ? profile.vehicleInfo.map((v, index) => {
                              if (index === 0) {
                                return v.vin;
                              }
                              return ", " + v.vin; // Add comma separation
                            })
                            : null}
                        </p>

                        {profile.communicationInfo ? (
                          <div>
                            <p className="mb-0">
                              Email:{" "}
                              {profile.communicationInfo.emailInfo
                                ? profile.communicationInfo.emailInfo.map(
                                  (e, index) => {
                                    if (index === 0) {
                                      return e.email;
                                    }
                                    return ", " + e.email; // Add comma separation
                                  }
                                )
                                : null}
                            </p>
                          </div>
                        ) : null}
                        <p className="mb-0">
                          GUID:{" "}
                          {profile.linkedIds
                            ? profile.linkedIds.map((li, index) => {
                              return li.azureUserIds
                                ? li.azureUserIds.map((az, ind) => {
                                  if (ind === 0) {
                                    return az;
                                  }
                                  return ", " + az; // Add comma separation
                                })
                                : "";
                            })
                            : null}
                        </p>
                      </div>
                    );
                  } else {
                    return (
                      <div key={'consumerProfileLinkedIds_Bulk_' + i}>
                        {getConsumerProfileLinkedIds_bulk(profile)}
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </CSSTransition>

        <div className="row">
          <div className="col-6">
            <div className="card mb-2 bg-light" style={{ height: cardCalcHeight }}>
              <div className="card-body">
                <h5>Request Details</h5>
                <p className={`${classes.requestDetails} row justify-content-center m-0 d-flex`}>
                  <div className="d-flex mb-2 flex-fill">
                    <button
                      className="p-2 flex-fill btn btn-primary col-lg-up center mx-2 mt-3"
                      onClick={downloadDoc}
                    >
                      Download Word Doc
                    </button>
                    <button
                      id="regenerate"
                      className="p-2 flex-fill btn btn-primary col-lg-up center mx-2 mt-3"
                      onClick={regenerateDoc}
                    >
                      Regenerate Word Doc
                    </button>
                  </div>
                  <div className="d-flex mb-2 flex-fill">
                    {workflow === "Normal" && isCustomerReqestor() ? (
                      <button
                        className="p-2 flex-fill btn btn-primary col-lg-up center mx-2 mt-3"
                        onClick={downloadTxt}
                      >
                        Download Text File
                      </button>
                    ) : null}
                    <button
                      className="p-2 flex-fill btn btn-success col-lg-up center mx-2 mt-3"
                      onClick={viewRequest}
                    >
                      View Request JSON
                    </button>
                  </div>
                  {displayOriginRequestJSON() && (
                    <div className="d-flex mb-2 flex-fill">
                      <button
                        className=" flex-fill btn btn-success col-lg-up mx-2 mt-3"
                        onClick={() => viewFeatureRequestJSON('viewOriginalRequest')}
                      >
                        View Original Request JSON
                      </button>
                    </div>
                  )}
                  {isFeatureAvailable("WOVENCORE") && (
                    <button
                      className=" flex-fill btn btn-success col-lg-up mx-2 mt-3"
                      onClick={() => viewFeatureRequestJSON('WOVENCORE')}
                    >
                      View Woven Request JSON
                    </button>
                  )}
                  {isFeatureAvailable("Subaru") && (
                    <button
                      className=" flex-fill btn btn-success col-lg-up mx-2 mt-3"
                      onClick={() => viewFeatureRequestJSON('Subaru')}
                    >
                      View Subaru Request JSON
                    </button>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card mb-2 bg-light" style={{ height: cardCalcHeight }}>
              <div className="card-body">
                <h5>
                  Response Details
                  {requestStatus === "Uploaded" ? (
                    <small className="h6 text-success">&nbsp;(Uploaded)</small>
                  ) : null}
                </h5>
                {responseGeneratedTime !== null ? (
                  <p className="mb-0">
                    Response Generated:&nbsp;
                    <span id="res-generated">
                      {moment(responseGeneratedTime)
                        .utc()
                        .format(DATEFORMATUTC)}
                    </span>
                    <UncontrolledTooltip
                      placement="bottom"
                      target="res-generated"
                    >
                      {moment(responseGeneratedTime)
                        .local()
                        .format(DATEFORMATLOCAL)}
                    </UncontrolledTooltip>
                  </p>
                ) : null}
                {dataUploadedTime !== null && dataUploadedTime !== undefined ? (
                  <p className="mb-0">
                    Response Uploaded:&nbsp;
                    <span id="res-uploaded">
                      {moment(dataUploadedTime)
                        .utc()
                        .format(DATEFORMATUTC)}
                    </span>
                    <UncontrolledTooltip
                      placement="bottom"
                      target="res-uploaded"
                    >
                      {moment(dataUploadedTime)
                        .local()
                        .format(DATEFORMATLOCAL)}
                    </UncontrolledTooltip>
                  </p>
                ) : null}
                {requestStatus === "Uploaded" &&
                  dataUploadedTime !== null &&
                  dataUploadedTime !== undefined &&
                  responseGeneratedTime !== null ? (
                  <p className="mb-0">
                    Processing Time:&nbsp;
                    {moment
                      .duration(
                        moment(dataUploadedTime).diff(moment(createdDate))
                      )
                      .format("d [days], h [hours], m [minutes], s [seconds]", {
                        trim: "both mid",
                      })}
                  </p>
                ) : null}
                <p className="text-center m-0">
                  <button
                    className="btn btn-success col-lg-up center mx-2 mt-3"
                    onClick={handleConsolidate}
                  >
                    <span id="consolidate-btn">Consolidate</span>
                  </button>
                  <button
                    className="btn btn-success col-lg-up center mx-2 mt-3"
                    onClick={viewResponseFiles}
                    disabled={!responseBuilt}
                  >
                    <span id="view-res-btn">View Response Files</span>
                  </button>
                  {!responseBuilt ? (
                    <UncontrolledTooltip
                      placement="bottom"
                      target="view-res-btn"
                    >
                      Response has not been built!
                    </UncontrolledTooltip>
                  ) : null}
                  {responseBuilt &&
                    creatorId !== "TIPS" &&
                    (requestStatus === "Open" || requestStatus === "Closed") ? (
                    <button
                      className="btn btn-primary col-lg-up center mx-2 mt-3"
                      data-toggle="modal"
                      data-target="#confirm-mark-uploaded"
                    >
                      Mark Data Uploaded
                    </button>
                  ) : null}
                  <p className="text-danger small mb-0" id={`invalid-feedback`}></p>
                  <p className="text-success small mb-0" id={`valid-feedback`}></p>
                </p>
              </div>
            </div>
          </div>
        </div>

        <CSSTransition
          in={systems.length > 0}
          appear={true}
          timeout={125}
          classNames="swipeD"
        >
          <div className="row">
            {systems.map((system) => (
              <AppsInfo
                key={system.name}
                data={system}
                isAdmin={isAdmin}
                history={history}
                dsar_id={dsarRequestId}
                authToken={authToken}
              />
            ))}
          </div>
        </CSSTransition>

        {/* Mark Uploaded Modal */}
        <div
          className="modal fade"
          id="confirm-mark-uploaded"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Confirm Data Uploaded
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                Please confirm that the response data has been uploaded to the
                TIPS System and the ONETRUST task has been closed.
              </div>
              <div className="modal-footer pb-0">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={markDataUploaded}
                >
                  Confirm
                </button>
              </div>
              <small
                className="text-right px-3 py-2 text-danger"
                id="mark-uploaded-feedback"
              ></small>
            </div>
          </div>
        </div>

        {/* Cancel Request Modal */}
        <div
          className="modal fade"
          id="confirm-set-cancel-status"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Cancel Request
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body text-center">
                Please select a reason for cancellation:
                <select
                  className="form-control-sm mt-2"
                  id="cancellation-reason"
                  value={cancellationReason}
                  onChange={(e) => {
                    setCancellationReason(e.target.value);
                    setCancellationReasonOther("");
                  }}
                >
                  <option>Request Cancelled by Customer</option>
                  <option>Duplicate Request created by TIPS</option>
                  <option>Test Request</option>
                  <option>Other</option>
                </select>
                {cancellationReason === "Other" ? (
                  <input
                    className="form-control mt-2"
                    type="text"
                    value={cancellationReasonOther}
                    onChange={(e) => setCancellationReasonOther(e.target.value)}
                    required
                  />
                ) : null}
                <span
                  id={`error-add-cancellation-reason`}
                  className="small text-danger mb-1"
                ></span>
              </div>
              <span></span>
              <div className="modal-footer pb-0">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={updateCancelledStatus}
                >
                  Confirm
                </button>
              </div>
              <small
                className="text-right px-3 py-2 text-danger"
                id="cancel-status-feedback"
              ></small>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="delete-request"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Request
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={onCancelDeleteRequest}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {!deleteTicketFlag && <div className="modal-body text-center">
                Please enter a reason for deletion:
                <input
                  className="form-control mt-2"
                  type="text"
                  value={deletionReason}
                  onChange={(e) => setDeletionReason(e.target.value)}
                  required
                />
                <span
                  id={`error-add-cancellation-reason`}
                  className="small text-danger mb-1"
                ></span>
              </div>}
              <span
                className={!deleteTicketFlag ? "text-middle mb-0 small text-danger ml-2" : "text-middle mb-0 small ml-2 pt-2 pb-2"}
                id="deletion-error"
              ></span>
              <div className="modal-footer pb-0">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={onCancelDeleteRequest}
                >
                  Close
                </button>
                {!deleteTicketFlag && <button
                  type="button"
                  className="btn btn-primary"
                  onClick={deleteRequest}
                >
                  Confirm
                </button>}
              </div>
              <small
                className="text-right px-3 py-2 text-danger"
                id="cancel-status-feedback"
              ></small>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withNavbar(RequestDetails);
