import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import momentDurationFormatSetup from "moment-duration-format";
import '../../../css/smallComponents.css';
import { DATEFORMATLOCAL } from '../../common/commons';

momentDurationFormatSetup(moment);

function CtpAggregatorRow(props) {
    const { serialNumber, data, get21MMDetails, downloadResponse } = props
    const [formatting, setFormatting] = useState("")

    useEffect(() => {
        let className = ""
        if (data.status_overall === "Error" || data.cps_ack === "Error") { className += "text-danger " }
        setFormatting(className)
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[data.status_overall, data.cps_ack])

    return (
        <tr className={formatting}>
            <td>{serialNumber}</td>
            <td>
                <Link id={`reqID-${serialNumber}`} to={`/ticket/${data.dsar_id}`}>{data.dsar_id}&nbsp;</Link>
                <UncontrolledTooltip placement="bottom" target={`reqID-${serialNumber}`}>
                    {`Sent On: ${moment(data.created_date).local().format(DATEFORMATLOCAL)}`}
                </UncontrolledTooltip>
            </td>
            <td>{data.requestor}</td>
            <td>{data.ticket_type}</td>
            <td>
                <span id={`age-${serialNumber}`}>{moment.duration(moment(new Date()).subtract(12, 'hours').diff(moment(data.created_date))).format("d")}</span>
                <UncontrolledTooltip placement="bottom" target={`age-${serialNumber}`}>
                    {moment.duration(moment(new Date()).diff(moment(data.created_date))).format("d [days], h [hours], m [minutes], s [seconds]")}
                </UncontrolledTooltip>
            </td>
            <td>
                <span id={`status-17CY-${serialNumber}`}>

                    {data.status_17cy === "N/A" || data.status_17cy === "Pending" ?
                        data.status_17cy
                        :
                        <Link onClick={() => downloadResponse(data.dsar_id, '17CY')}>{data.status_17cy}</Link>
                    }
                </span>
                {data.created_date_17cy ?
                    <UncontrolledTooltip placement="bottom" target={`status-17CY-${serialNumber}`}>
                        {`Received On: ${moment(data.created_date_17cy).local().format(DATEFORMATLOCAL)}`}
                    </UncontrolledTooltip>
                    :
                    null
                }
            </td>
            <td>
                <span id={`status-21MM-${serialNumber}`}>
                    {data.status_21mm === "N/A" ?
                        data.status_21mm
                        :
                        <Link onClick={() => get21MMDetails(data.dsar_id, data.status_21mm)}>{data.status_21mm}</Link>
                    }
                </span>
                {data.created_date_21mm ?
                    <UncontrolledTooltip placement="bottom" target={`status-21MM-${serialNumber}`}>
                        {`Received On: ${moment(data.created_date_21mm).local().format(DATEFORMATLOCAL)}`}
                    </UncontrolledTooltip>
                    :
                    null
                }
            </td>
            <td>
                <span id={`status-MP-${serialNumber}`}>
                    {data.status_mp === "N/A" || data.status_mp === "Pending" ?
                        data.status_mp
                        :
                        <Link onClick={() => downloadResponse(data.dsar_id, 'MP')}>{data.status_mp}</Link>
                    }
                </span>
                {data.created_date_mp ?
                    <UncontrolledTooltip placement="bottom" target={`status-MP-${serialNumber}`}>
                        {`Received On: ${moment(data.created_date_mp).local().format(DATEFORMATLOCAL)}`}
                    </UncontrolledTooltip>
                    :
                    null
                }
            </td>
            <td>{data.status_overall}</td>
            <td>{data.cps_ack}</td>
        </tr>
    )

}

export default CtpAggregatorRow;
