import React, { useState, useEffect } from 'react';
import AgingReport from './AgingReport';
import RequestCount from './RequestCount';
import ErrorReport from './ErrorReport';
import SxmCaliCount from './SxmCaliCount';
import DownstreamChart from './downstreamChart/DownstreamChart';
import UsersReport from './UsersReport';
import ReconciliationReport from './ReconciliationReport';
import ReconciliationOnDemandReport from './ReconciliationOnDemandReport';
import CaliforniaReport from './californiaReport/CaliforniaReport';
import AutoClosedReport from './AutoClosedReport';
import CancellationReport from './CancellationReport';
import AverageProcessingTime from './averageProcessingTime/AverageProcessingTime';
import RequestChartReport from './requestCountChart/RequestChartReport';
import DuplicateRequests from './DuplicateRequests';
import PendingAttachments from './PendingAttachments';
import DeletionReport from './deletionReport/DeletionReport';


function ReportsTab(props) {
    const { authToken, initialReport, history } = props

    const [reportType, setReportType] = useState(initialReport ? initialReport : "Aging Report Open")

    useEffect(() => {
        if (initialReport) { setReportType(initialReport) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[initialReport])

    return (
        <div className="tab-pane fade" id="reports" role="tabpanel" aria-labelledby="reports-tab">
            <div className="mt-2 row justify-content-end text-right">

                <div className="mb-4">
                    <label className="small" htmlFor="reportType">Report Type</label>
                    <select className="form-control" id="reportType" value={reportType}
                        onChange={e => { history.push(`/reports/${e.target.value}`) }}
                    >
                        <option value="Aging Report Open">Aging Report - OPEN</option>
                        <option value="Aging Report Closed">Aging Report - CLOSED</option>
                        <option>Auto Closed Report</option>
                        <option>Average Processing Time</option>
                        <option>California Customer Chart</option>
                        <option>Cancellation Report</option>
                        <option>Deletion Report</option>
                        <option>Downstream Chart</option>
                        <option>Duplicate Requests</option>
                        <option value="Error Report Downstream">Error Report - Downstream</option>
                        <option value="Error Report Upstream">Error Report - Upstream</option>
                        <option>Pending Attachments</option>
                        <option>Reconciliation Report</option>
                        <option>Reconciliation On Demand Report</option>
                        <option>Request Count</option>
                        <option>Request Count Chart</option>
                        <option>SXM California Count</option>
                        <option>Users</option>
                    </select>
                </div>

            </div>
            {reportType === 'Aging Report Open' ? <AgingReport authToken={authToken} open={true} /> : null}
            {reportType === 'Aging Report Closed' ? <AgingReport authToken={authToken} open={false} /> : null}
            {reportType === 'Auto Closed Report' ? <AutoClosedReport authToken={authToken} /> : null}
            {reportType === 'Average Processing Time' ? <AverageProcessingTime authToken={authToken} /> : null}
            {reportType === 'California Customer Chart' ? <CaliforniaReport authToken={authToken} /> : null}
            {reportType === 'Cancellation Report' ? <CancellationReport authToken={authToken} /> : null}
            {reportType === 'Deletion Report' ? <DeletionReport authToken={authToken} /> : null}
            {reportType === 'Downstream Chart' ? <DownstreamChart authToken={authToken} /> : null}
            {reportType === 'Duplicate Requests' ? <DuplicateRequests authToken={authToken} /> : null}
            {reportType === 'Error Report Downstream' ? <ErrorReport authToken={authToken} upstream={false} /> : null}
            {reportType === 'Error Report Upstream' ? <ErrorReport authToken={authToken} upstream={true} /> : null}
            {reportType === 'Pending Attachments' ? <PendingAttachments authToken={authToken} /> : null}
            {reportType === 'Reconciliation Report' ? <ReconciliationReport authToken={authToken} /> : null}
            {reportType === 'Reconciliation On Demand Report' ? <ReconciliationOnDemandReport authToken={authToken}/> : null}
            {reportType === 'Request Count' ? <RequestCount authToken={authToken} /> : null}
            {reportType === 'Request Count Chart' ? <RequestChartReport authToken={authToken} /> : null}
            {reportType === 'SXM California Count' ? <SxmCaliCount authToken={authToken} /> : null}
            {reportType === 'Users' ? <UsersReport authToken={authToken} /> : null}
        </div>

    );
}

export default ReportsTab;
