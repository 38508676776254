import React from 'react';
import { DataGrid, getGridNumericColumnOperators, getGridDateOperators, getGridStringOperators } from '@mui/x-data-grid';
import ColumnHeader from './ColumnHeader';
import Menu from './Menu';
import FilterPanel from './FilterPanel';
import Toolbar from './Toolbar';
import { Tooltip } from '@material-ui/core';

function Grid(props) {
  const {
    rows,
    columns,
    clearFilterSortState,
    remoteFiltering = false,
    applyRemoteFiltering,
    defaultFilters = {},
    defaultFilterOrder = [],
    applyRemoteSorting
  } = props;
  const [activeColumn, setActiveColumn] = React.useState("");
  const [gridFilters, setGridFilters] = React.useState(defaultFilters);
  const [gridFilterOrder, setGridFilterOrder] = React.useState(defaultFilterOrder);
  const [sortModel, setSortModel] = React.useState([]);
  
  React.useEffect(() => {
    if (clearFilterSortState) {
      setGridFilterOrder([...defaultFilterOrder]);
      setGridFilters({
        ...defaultFilters
      });
      setSortModel([]);
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[clearFilterSortState])

  React.useEffect(()=>{
    if(defaultFilters){
      setGridFilters(defaultFilters);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[JSON.stringify(defaultFilters)])

  React.useEffect(() => {
    if (remoteFiltering) {

      applyRemoteFiltering(gridFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridFilters])

  React.useEffect(()=>{
    if(applyRemoteSorting){
      applyRemoteSorting(sortModel)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[sortModel])

  const visibleRowsMap = {};

  if (!remoteFiltering) {
    for (const key in gridFilterOrder) {
      const columnInfo = gridFilters[gridFilterOrder[key]];
      if (!columnInfo) {
        continue;
      }
      const columnData = columnInfo["columnInfo"];
      const filterOperator = columnData.filterOperators.find(
        (operator) => operator.value === columnInfo.operatorValue
      );
      let parsedValue = columnInfo.value;
      if (typeof parsedValue === "string" && parsedValue?.length === 0) {
        continue;
      }
      if (typeof parsedValue === "string" && columnData.valueParser && parsedValue?.length > 0) {
        parsedValue = columnData.valueParser(parsedValue);
      }

      const applyFilterOnRow = filterOperator.getApplyFilterFn(
        {
          ...columnInfo,
          value: parsedValue
        },
        columnData
      );
      if (typeof applyFilterOnRow !== "function") {
        continue;
      }
      rows.forEach((row, id) => {
        const isShown = applyFilterOnRow({
          value: row[columnData.field]
        });
        if (visibleRowsMap[id] == null) {
          visibleRowsMap[id] = isShown;
        } else if (visibleRowsMap[id]) {
          visibleRowsMap[id] = isShown;
        }
      });
    }
  }
  let visibleRows = [];
  if (Object.keys(visibleRowsMap).length === 0) {
    visibleRows = rows;
  }
  for (const key in visibleRowsMap) {
    if (visibleRowsMap[key] == null || visibleRowsMap[key]) {
      visibleRows.push({
        ...rows[key],
        filteredRowIndex : (visibleRows.length + 1 )
      });
    }
  }
  const formatedColumns = columns.map((column) => {
    let isFilterIconVisible = false;
    if (gridFilters[column.field]) {
      if (typeof gridFilters[column.field].value === "string") {
        isFilterIconVisible = gridFilters[column.field].value.length > 0;
        // For Handling the SingleSelect Empty Selection 
        if (column.customSingleSelectFilter) {
          isFilterIconVisible = true;
        }
      } else {
        isFilterIconVisible = true;
      }
    }

    if (column.type === "singleSelect" && column.customSingleSelectFilter) {
      column.filterOperators = getGridStringOperators().filter((operator) => {
        return operator.value === "equals";
      })
    } else if (column.type === "number") {
      column.filterOperators = getGridNumericColumnOperators().filter((operator) => {
        return operator.label === "=" || operator.label === ">" || operator.label === "<";
      })
    } else if (column.type === "date") {
      column.filterOperators = getGridDateOperators().filter((operator) => {
        return operator.value === "is" || operator.value === "after" || operator.value === "before";
      })
    } else {
      column.filterOperators = getGridStringOperators().filter((operator) => {
        return operator.value === "contains";
      })
    }
    if (column.displayTootip) {
      column.renderCell = (params) => {
        let displayTooltip = true;
        if (column.displayTootip[1]) {
          displayTooltip = params.row[column.displayTootip[0]]?.length > column.displayTootip[1];
        }
        return displayTooltip ? (
          <Tooltip title={params.row[column.displayTootip[0]] || ""} >
            <span className='MuiDataGrid-cell'>
              {column.renderCellKey ? params.row[column.renderCellKey] : params.value}
            </span>
          </Tooltip>
        ) : (
          <span className='MuiDataGrid-cell'>
            {column.renderCellKey ? params.row[column.renderCellKey] : params.value}
          </span>
        )
      }
    }
    return {
      ...column,
      renderHeader: () => {
        return (
          <ColumnHeader
            column={column}
            updateActiveColumn={setActiveColumn}
            filterIconEnable={isFilterIconVisible}
          />
        );
      }
    };
  })
  const CustomColumnMenu = (_props) => {
    return (
      <Menu
        {..._props}
        hideFilterMenu={(menuProps) => {
          setActiveColumn(_props.currentColumn);
          _props.hideMenu(menuProps);
        }}
      />
    );
  }
  const CustomFilterPanel = () => {
    return (
      <FilterPanel
        activeColumn={activeColumn}
        gridFilters={gridFilters}
        setGridFilters={setGridFilters}
        gridFilterOrder={gridFilterOrder}
        setGridFilterOrder={setGridFilterOrder}
      />
    );
  }
  const CustomToolbar = () => {

    return props?.toolbarConfig ? (
      <Toolbar
        {...props.toolbarConfig}
        rows = {visibleRows}
      />
    ) : null;
  }
  return (
    <DataGrid
      {...props}
      rows={visibleRows}
      disableVirtualization={true}
      sortModel={sortModel}
      onSortModelChange={(model) => {
        const sortMode = [];
        if (model[0]) {
          if (sortModel.length > 0) {
            if (sortModel[0].field !== model[0].field || sortModel[0].sort !== model[0].sort) {
              sortMode.push(model[0])
              setSortModel(sortMode);
            }
          } else {
            sortMode.push(model[0])
            setSortModel(sortMode);
          }
        } else {
          setSortModel([]);
        }
      }}
      columns={formatedColumns}
      editMode="row"
      onRowEditStart={(params, event) => {
        event.defaultMuiPrevented = true;
      }}
      onRowEditStop={(params, event) => {
        event.defaultMuiPrevented = true;
      }}
      onCellFocusOut={(params, event) => {
        event.defaultMuiPrevented = true;
      }}
      components={{
        Toolbar: CustomToolbar,
        ColumnMenu: CustomColumnMenu,
        FilterPanel: CustomFilterPanel
      }}
    />
  )
}
export default Grid;
