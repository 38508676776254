import React, { useState, useEffect } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';


function SxmCaliCount(props) {
    const { authToken } = props
    const today = moment().format('YYYY-MM')

    const [date, setDate] = useState(today)
    const [tableFooter, setTableFooter] = useState(moment().format('MMMM YYYY'))
    const [report, setReport] = useState([])
    const [totals, setTotals] = useState({})

    useEffect(() => {
        if (authToken) {
            axios.get(`/reports/sxm_california/?date=${date}`, {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setReport(res.data.sxm_breakdown)
                setTotals(res.data.totals)
                setTableFooter(moment(date).format('MMMM YYYY'))
            }).catch(err => {
                console.log(err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken, date])


    return (
        <div className="mb-5">

            <div className="form-inline mt-3">
                <div className="form-group mr-2 mb-2">
                    <label className="mr-1" htmlFor="monthSelect">Select Month:</label>
                    <input id="monthSelect" type="month" className="form-control date-form" value={date}
                        min="2020-01" max={today} onChange={e => setDate(e.target.value)} required
                    />
                </div>
            </div>

            <h4 className="text-center mt-4">SXM California Report</h4>
            <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                        <th scope="col" className="text-left">Date</th>
                        <th scope="col">Right to Delete CA</th>
                        <th scope="col">Right to Delete non-CA</th>
                        <th scope="col">Right to Know CA</th>
                        <th scope="col">Right to Know non-CA</th>
                    </tr>
                </thead>
                <CSSTransition in={report.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody className="text-center">
                        {report.map(row => (
                            <tr key={row.date}>
                                <th scope="row" className="text-left">{row.date}</th>
                                <td>{row.rtd_ca}</td>
                                <td>{row.rtd_non_ca}</td>
                                <td>{row.rtk_ca}</td>
                                <td>{row.rtk_non_ca}</td>
                            </tr>
                        ))}

                        <tr><td></td><td></td><td></td><td></td><td></td></tr>

                        {totals ?
                            <tr>
                                <th className="text-left">Total</th>
                                <td className="font-weight-bold">{totals.rtd_ca}</td>
                                <td className="font-weight-bold">{totals.rtd_non_ca}</td>
                                <td className="font-weight-bold">{totals.rtk_ca}</td>
                                <td className="font-weight-bold">{totals.rtk_non_ca}</td>
                            </tr>
                            : null
                        }
                    </tbody>
                </CSSTransition>
            </table>

            <SwitchTransition>
                <CSSTransition key={tableFooter} timeout={250} classNames="swipeR" appear={true}>
                    <p className="font-italic text-center">Date Range: {tableFooter}</p>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}

export default SxmCaliCount;
