import React, { useState, useContext, useEffect } from 'react';
import { AppContext } from '../../../../../RoutesWithAuth';
import axios from '../../../../common/AxiosConfig';
import DataGrid from '../../grid';
import { makeStyles } from "@material-ui/core/styles";
import { DATEFORMATLOCAL, DATEFORMATUTC, RequestorMapping } from '../../../../common/commons';
import RowMenuCell from '../../grid/RowEditActions';
import { LoadingIndicator } from '../../reports/reconcilationreport/MuiStyled';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';


const Requestor = [RequestorMapping.B2B, RequestorMapping.CUSTOMER, RequestorMapping.EMPLOYEE, RequestorMapping.TCICUSTOMER];
const COLUMNS = [{
    name: "#",
    mapping: "rowId",
    width: 40
}, {
    name: 'PI Attribute',
    mapping: "pi_attribute",
}, {
    name: 'Threshold Count',
    mapping: "threshold_count",
    editable: true,
    width: 160,
    isHiddenForResponse: true,
    type: 'singleSelect',
    valueOptions: ['10', '20', '30'],
}, {
    name: 'Updated By',
    mapping: "updated_by",
}, {
    name: 'Updated On',
    mapping: "updated_on",
    width: 200,
    renderCell: (params) => (

        <Tooltip title={params.row["updateDateLocal"]}>
            <span>
                {params.row["updatedDateUTC"]}
            </span>
        </Tooltip>
    ),
}, {
    name: 'Is Active',
    mapping: "is_active",
    editable: true,
    width: 160,
    type: 'singleSelect',
    valueOptions: ['Yes', 'No']
}, {
    name: "Edit",
    mapping: "actions",
    adminEnabled: true,
    width: 80,
    editable: false,
    renderCell: RowMenuCell
}];

const useStyles = makeStyles(() => ({
    appscopeWrapper: {
        marginTop: '4px',
        marginRight: '15px'
    },
    additionalInfoWrapper: {
        marginTop: '10px',
        padding: '0px 0px 10px 0px',
        boxShadow: '0px 0px 1px 0px rgb(0 0 0 / 41%)',
        '& .headeradditional': {
            background: '#7f9ed7',
            padding: '5px',
            color: "white",
            fontWeight: 'bold'
        },
        '& .contentAdditionalInfo': {
            fontStyle: 'italic'

        }
    },
    noteWrapper: {
        marginTop: '10px',
        fontStyle: 'italic'
    }
}));
function RequestReviewTab({ activeTab, fromredirection, workflow, noteContent}) {

    const [appsData, setAppsData] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);
    const [additionalInfo, setAdditionalInfo] = useState("");


    const requestor = Requestor[activeTab];
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles()
    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, activeTab, fromredirection])

    const fetchData = () => {
        setIsProcessing(true);
        const params = {
            requestor,
            workflow
        }
        axios.get(`settings/review`, {
            headers: {
                Authorization: authToken
            },
            params
        }).then(res => {
            const appsData = res.data;
            setAppsData(appsData);
            let selection = [];
            let additRule = "";
            if (appsData.length > 0) {
                selection = [`1-${activeTab}`];
                additRule = appsData[0].details || "None";
            }
            setAdditionalInfo(additRule)
            setSelectionModel(selection);
            setIsProcessing(false);

        }).catch(err => {
            setIsProcessing(false);
            console.log(err)
        })
    }
    const updateApp = (rowInfo) => {
        setIsProcessing(true);
        const payload = {
            requestor,
            workflow,
            pi_attribute: rowInfo.pi_attribute,
            is_active: rowInfo.is_active === "Yes",
        };
        if (workflow === "request") {
            payload["threshold_count"] = rowInfo.threshold_count;
        }
        axios.post(`settings/review`, payload, {
            headers: {
                Authorization: authToken
            }
        })
            .then(res => {
                fetchData();
                setIsProcessing(false);
            }).catch(err => {
                setIsProcessing(false);
                console.log(err)
            })
    }
    const dataGridColumns = () => {
        const formatedColumns = []
        COLUMNS.forEach((column) => {
            if (!(column.isHiddenForResponse && workflow === "response")) {
                formatedColumns.push(column);
            }
        })
        return formatedColumns;
    }
    const appsGridData = () => {
        return appsData?.map((row, i) => {
            return {
                rowId: (i + 1),
                originalId: row.id,
                id: `${(i + 1)}-${activeTab}`,
                ...row,
                is_active: row.is_active ? "Yes" : "No",
                updateDateLocal: row.updated_on ? moment(row.updated_on).local().format(DATEFORMATLOCAL) : '--',
                updatedDateUTC: row.updated_on ? moment(row.updated_on).utc().format(DATEFORMATUTC) : '--',
            }
        })
    }
    return (
        <div className={classes.appscopeWrapper} key={`RequestReview-${workflow}-${activeTab}`}>
            {isProcessing && (<LoadingIndicator />)}
            <DataGrid
                disableSelectionOnClick={false}
                columns={dataGridColumns()}
                selectionModel={selectionModel}
                onSelectionModelChange={(selection) => {
                    let gridSelection = selectionModel;
                    if (!(isProcessing || selection?.length === 0)) {
                        gridSelection = selection;
                        setSelectionModel(selection);
                    }
                    let selectedAppData = {};
                    appsData.forEach((app, index) => {
                        if (`${(index + 1)}-${activeTab}` === gridSelection[0]) {
                            selectedAppData = app;
                        }
                    })
                    !isProcessing && setAdditionalInfo(selectedAppData?.details || "None");
                }}
                rows={appsGridData()}
                onRowEditCommit={(rows, event) => {
                    if (event.rowInfo) {
                        updateApp(event.rowInfo);
                    }
                }}
            />
            {(<div className={classes.additionalInfoWrapper}>
                <div className={"headeradditional"}>
                    Details of PI Attribute check
                </div>
                <div className='contentAdditionalInfo'>
                    {additionalInfo}
                </div>
            </div>)}
            <div className={classes.noteWrapper}>
                {noteContent()}
            </div>
        </div>
    )
}
export default React.memo(RequestReviewTab);