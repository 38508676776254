import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import axios from '../../../../common/AxiosConfig';
import moment from 'moment';
import { feedbackTimeout } from '../../../../common/commons';

const ticketTypeMapping = ['Right-to-Correct', 'Right-to-Delete', 'Right-to-Know-Detailed', 'Right-to-OptOut']
const ageMapping = ['11_plus', '7_10', '4_6', '0_3']

function AgingReport(props) {
    const { authToken, open } = props
    const ticket_state = open ? "Open" : "Closed"
    const todayUTC = moment().utc()

    const [rtdBreakdown, setRtdBreakdown] = useState([])
    const [rtkBreakdown, setRtkBreakdown] = useState([])
    const [rtooBreakdown, setRtooBreakdown] = useState([])
    const [rtcBreakdown, setRtcBreakdown] = useState([])
    const [totalBreakdown, setTotalBreakdown] = useState([])

    const [downstreamMetrics, setDownstreamMetrics] = useState([])

    const [age, setAge] = useState("")

    useEffect(() => {
        if (authToken) {
            axios.get('/reports/aging', {
                params: {
                    is_open: open,
                },
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setRtdBreakdown(res.data.age.rtd)
                setRtkBreakdown(res.data.age.rtk)
                setRtooBreakdown(res.data.age.rtoo)
                setRtcBreakdown(res.data.age.rtc)
                setTotalBreakdown(res.data.age.total)

                // Decide the default age for the downstream metrics
                let i = 3
                if (open) {
                    res.data.age.total.find((num, index) => {
                        if (num > 0) { i = index; }
                        return num;
                    })
                    setAge(metricAgeMapping[i])
                }
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken])

    useEffect(() => {
        if (open && age !== "") {
            axios.get('/reports/aging/metrics', {
                params: {
                    metric_age: age,
                },
                headers: {
                    Authorization: authToken,
                }
            }).then(res => {
                setDownstreamMetrics(res.data.metrics)
            }).catch(err => {
                console.log(err)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[age, authToken])

    const downloadDsarIds = () => {
        axios.get('/reports/aging/download', {
            params: {
                metric_age: age,
                is_open: open,
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            if (res.data.old_dsar_ids.length) {
                let text_file = ""
                for (const dsar_id of res.data.old_dsar_ids) {
                    text_file += dsar_id + "\r\n"
                }
                const downloadLink = document.createElement("a");
                const blob = new Blob([text_file], { type: 'text/plain' })
                const url = URL.createObjectURL(blob)
                downloadLink.href = url
                const timestamp = moment().format("YYYYMMDDHHmm")
                downloadLink.download = `Aging_Report_${age}_${ticket_state}_${timestamp}.txt`
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
            } else {
                populateError('There are no old DSAR IDs!')
            }
        }).catch(err => {
            console.log(err)
            populateError(err.response.data.message)
        })
    }

    const downloadQuery = (appName, ticketType) => {

        // Map the ticketType index to the actual value
        ticketType = ticketTypeMapping[ticketType]

        axios.get('/reports/aging/download_query', {
            params: {
                app_name: appName,
                ticket_type: ticketType,
                metric_age: age,
                is_open: open,
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Download the results to a text file
            let text_file = ""
            for (const dsar_id of res.data.dsar_ids) {
                text_file += dsar_id + "\r\n"
            }
            const downloadLink = document.createElement("a");
            const blob = new Blob([text_file], { type: 'text/plain' })
            const url = URL.createObjectURL(blob)
            downloadLink.href = url
            const timestamp = moment().format("YYYYMMDDHHmm")
            downloadLink.download = `Aging_Report_${age}_${ticket_state}_${appName}_${ticketType}_${timestamp}.txt`
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }).catch(err => {
            console.log(err)
        })
    }

    const downloadAgingQuery = (ticketType, age) => {
        // Map the ticketType & age index to the actual value
        ticketType = ticketTypeMapping[ticketType]
        age = ageMapping[age]

        axios.get(`/reports/aging/download_${open ? "open" : "closed"}_query`, {
            params: {
                ticket_type: ticketType,
                metric_age: age,
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Download the results to a text file
            let text_file = ""
            for (const dsar_id of res.data.dsar_ids) {
                text_file += dsar_id + "\r\n"
            }
            const downloadLink = document.createElement("a");
            const blob = new Blob([text_file], { type: 'text/plain' })
            const url = URL.createObjectURL(blob)
            downloadLink.href = url
            const timestamp = moment().format("YYYYMMDDHHmm")
            downloadLink.download = `Aging_Report_${ticket_state}_${age}_${ticketType}_${timestamp}.txt`
            document.body.appendChild(downloadLink)
            downloadLink.click()
            document.body.removeChild(downloadLink)
        }).catch(err => {
            console.log(err)
        })
    }

    const populateError = (message) => {
        const downloadError = document.getElementById('downloadError')
        downloadError.innerHTML = message
        removeFeedback('downloadError')
    }

    const removeFeedback = (elementId) => {
        // Remove the feedback after a specified timeout period
        setTimeout(
            function () {
                const field = document.getElementById(elementId)
                if (!field) { return }
                field.innerHTML = ""
            }, feedbackTimeout
        )
    }

    return (
        <div className="mb-5">
            <h4 className="text-center">Aging Report - {open ? "OPEN" : "CLOSED"}</h4>
            <table className="table table-striped">
                <thead>
                    <tr className="text-center">
                        <th scope="col" className="text-left">Request Type</th>
                        <th scope="col">
                            11+ Days<br />
                            <span className="small">
                                ({todayUTC.clone().subtract(11, "days").format("MM/DD")} &amp; earlier)
                            </span>
                        </th>
                        <th scope="col">
                            7-10 Days<br />
                            <span className="small">
                                ({todayUTC.clone().subtract(10, "days").format("MM/DD")} - {todayUTC.clone().subtract(7, "days").format("MM/DD")})
                            </span>
                        </th>
                        <th scope="col">
                            4-6 Days<br />
                            <span className="small">
                                ({todayUTC.clone().subtract(6, "days").format("MM/DD")} - {todayUTC.clone().subtract(4, "days").format("MM/DD")})
                            </span>
                        </th>
                        <th scope="col">
                            0-3 Days<br />
                            <span className="small">
                                ({todayUTC.clone().subtract(3, "days").format("MM/DD")} - {todayUTC.format("MM/DD")})
                            </span>
                        </th>
                    </tr>
                </thead>
                <CSSTransition in={rtdBreakdown.length > 0} appear={true} timeout={125} classNames="swipeD">
                    {rtdBreakdown.length ?
                        <tbody className="text-center">
                            <tr>
                                <th scope="row" className="text-left">Right to Correct</th>
                                {rtcBreakdown.map((count, i) => (
                                    <td key={`rtc-count-${ageMapping[i]}`}>
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadAgingQuery(0, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th scope="row" className="text-left">Right to Delete</th>
                                {rtdBreakdown.map((count, i) => (
                                    <td key={`rtd-count-${ageMapping[i]}`}>
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadAgingQuery(1, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th scope="row" className="text-left">Right to Know</th>
                                {rtkBreakdown.map((count, i) => (
                                    <td key={`rtk-count-${ageMapping[i]}`}>
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadAgingQuery(2, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th scope="row" className="text-left">Right to Opt Out</th>
                                {rtooBreakdown.map((count, i) => (
                                    <td key={`rtoo-count-${ageMapping[i]}`}>
                                        {count > 0 ?
                                            <button className="btn btn-link p-0" onClick={() => downloadAgingQuery(3, i)}>{count}</button>
                                            : count
                                        }
                                    </td>
                                ))}
                            </tr>
                            <tr>
                                <th scope="row" className="text-left">Total</th>
                                {totalBreakdown.map((count, i) => (<td key={`total-count-${ageMapping[i]}`}>{count}</td>))}
                            </tr>
                        </tbody>
                        :
                        <tbody />
                    }
                </CSSTransition>
            </table>

            {open ?
                <div>
                    <h4 className="mt-4 d-flex justify-content-center">
                        <form className="form-inline">
                            Downstream Metrics for
                            <select className="form-control form-control-inline form-inline mx-2"
                                value={age} onChange={e => setAge(e.target.value)}
                            >
                                <option value="0_3">0-3</option>
                                <option value="4_6">4-6</option>
                                <option value="7_10">7-10</option>
                                <option value="11_plus">11+</option>
                            </select>
                            Day Old Tickets
                        </form>
                    </h4>

                    <table className="table table-striped">
                        <thead>
                            <tr className="text-center">
                                <th scope="col" className="text-left">System</th>
                                <th scope="col">Right to Correct</th>
                                <th scope="col">Right to Delete</th>
                                <th scope="col">Right to Know</th>
                                <th scope="col">Right to Opt Out</th>
                            </tr>
                        </thead>
                        <CSSTransition in={downstreamMetrics.length > 0} appear={true} timeout={125} classNames="swipeD">
                            <tbody className="text-center">
                                {downstreamMetrics.map(system => (
                                    <tr key={system.name}>
                                        <th scope="row" className="text-left">{system.name}</th>
                                        {system.data.map((count, i) => (
                                            <td key={`${system.name}-${ticketTypeMapping[i]}`}>
                                                {count > 0 ?
                                                    <button className="btn btn-link p-0" onClick={() => downloadQuery(system.name, i)}>{count}</button>
                                                    : count
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </CSSTransition>
                    </table>
                    <div className="text-right">
                        <button className="btn btn-primary" onClick={downloadDsarIds}>Download Request IDs</button>
                        <br />
                        <small className="text-danger" id="downloadError"></small>
                    </div>
                </div>
                : null
            }
        </div>
    );
}

export default AgingReport;

const metricAgeMapping = {
    0: "11_plus",
    1: "7_10",
    2: "4_6",
    3: "0_3",
}