import React, { useState, useEffect, useCallback } from "react";
import { CSSTransition } from "react-transition-group";
import axios from "../../../../common/AxiosConfig";
import { UncontrolledTooltip } from 'reactstrap';
import moment from "moment";
import Papa from 'papaparse';
import { Link } from "react-router-dom";

function ReconciliationOnDemandReport(props) {
  const { authToken } = props;
  const [reconReport, setReconReport] = useState(null);
  const [loading, setLoading] = useState(false)
  const [lastGenerated, setLastGenerated] = useState(null)

  const generatereconciliationdemanddata = useCallback(() => {
    setLoading(true);
    axios.get(`reports/on-demand-recon-report`,
      {
        params: { "operation": "report" },
        headers: {
          Authorization: authToken,
        },
      }).then(res => {
        setLoading(false);
        const responseData = res.data.cps_tips_ondemand_reconciliation_report;
        if (Object.keys(responseData).length === 0) {
          setReconReport(null);
          return;
        }
        responseData['requests_tips_status_legend'] = [
          {
            "dsar_status_tips": "REQUEST_PENDING_SUBMISSION",
            "dsar_description": "Request is yet to be pushed to CPS",
          },
          {
            "dsar_status_tips": "RESPONSE_PENDING",
            "dsar_description": "Pushed to CPS but waiting for response",
          },
          {
            "dsar_status_tips": "RESPONSE_PENDING_ATTACHMENTS_FETCH",
            "dsar_description": "Waiting on attachment verification",
          },
          {
            "dsar_status_tips": "RECEIVED_RESPONSE_ATTACHMENTS",
            "dsar_description": "Request closed in TIPS",
          },
          {
            "dsar_status_tips": "RECEIVED_RESPONSE",
            "dsar_description": "Request closed in TIPS",
          }
        ];
        setReconReport(responseData);
        setLastGenerated(moment());
      }).catch(err => {
        console.log(err)
      })
// eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken])

  const downloadCsvReport = () => {
    axios.get(`reports/on-demand-recon-report`, {
      headers: {
        Authorization: authToken
      },
      params: { "operation": "download" },
    }).then(res => {
      // Don't update the state if download_csv is set to true
      // Download the csv file
      const csv = Papa.unparse(res.data.cps_tips_ondemand_reconciliation_report)
      createBlobAndDownload(csv)
    }).catch(err => {
      // Handle errors fetching data here
      console.log('There was an error fetching the data!', err)
    })
  }

  const createBlobAndDownload = (csv) => {
    const downloadLink = document.createElement("a");
    const blob = new Blob(["\ufeff", csv])
    const url = URL.createObjectURL(blob)
    downloadLink.href = url
    const timestamp = moment().format("YYYYMMDDHHmmss")
    downloadLink.download = `Recon_On_Demand_Report_${timestamp}.csv`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  useEffect(() => {
    if (authToken) {
      generatereconciliationdemanddata();
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken, generatereconciliationdemanddata]);

  const regenerate = () => {
    setReconReport(null);
    generatereconciliationdemanddata();
  }


  const renderTIPSHeaders = () => {
    return (
      <tr className="table-dark-custom">
        <th scope="col">#</th>
        <th scope="col">DSAR</th>
        <th scope="col">Request Type</th>
        <th scope="col">Status-TIPS</th>
        <th scope="col">Creation Time-TIPS</th>
      </tr>
    );
  };

  const renderCPSHeaders = () => {
    return (
      <tr className="table-dark-custom">
        <th scope="col">#</th>
        <th scope="col">DSAR</th>
        <th scope="col">Request Type</th>
        <th scope="col">Status-CPS</th>
        <th scope="col">Creation Time-CPS</th>
      </tr>
    );
  };

  const renderCPSandTIPSHeaders = () => {
    return (
      <tr className="table-dark-custom">
        <th scope="col">#</th>
        <th scope="col">DSAR</th>
        <th scope="col">Request Type</th>
        <th scope="col">Status-TIPS</th>
        <th scope="col">Status-CPS</th>
        <th scope="col">Creation Time-TIPS</th>
      </tr>
    );
  };
  const renderTipsStatusLegendHeaders = () => {
    return (
      <tr className="table-dark-custom">
        <th scope="col">#</th>
        <th scope="col">Status-Tips</th>
        <th scope="col">Description</th>
      </tr>
    );
  };
  const renderRow = (rowData, headerName) => {
    if (headerName === 'TIPS_STATUS_LEGEND') {
      return rowData?.length > 0 && rowData.map((d, i) => (
        <tr key={i}>
          <td>{i + 1}</td>
          <td>{d.dsar_status_tips || ""}</td>
          <td>{d.dsar_description || ""}</td>
        </tr>
      ));
    } else {
      return rowData?.length > 0 && rowData.map((d, i) => (
        <tr key={i}>
          <td>{i + 1}</td>
          <td><Link id={`reqID-${i}`} to={`/ticket/${d.dsar_request_id}`}>{d.dsar_request_id || ""}</Link></td>
          <td>{d.dsar_request_type || ""}</td>
          <td>{headerName === 'CPSTIPS' ? (d.dsar_tips_request_status || "") : (d.dsar_request_status || "")}</td>
          {headerName === 'CPSTIPS' && <td>{d.dsar_cps_request_status || ""}</td>}
          <td>{d.created_date || ""}</td>
        </tr>
      ));
    }
  };
  const renderTableColumns = (headerName) => {
    if (headerName === 'CPSTIPS') {
      return renderCPSandTIPSHeaders()
    } else if (headerName === 'TIPS_STATUS_LEGEND') {
      return renderTipsStatusLegendHeaders()
    } else if (headerName === 'TIPS') {
      return renderTIPSHeaders()
    } else {
      return renderCPSHeaders()
    }
  }
  const renderTable = (data, headerName) => {
    return (
      <table className="table table-sm table-striped">
        <thead className='thead-secondary table-header'>{renderTableColumns(headerName)}</thead>
        <CSSTransition
          in={reconReport !== null}
          appear={true}
          timeout={125}
          classNames="swipeD"
        >
          <tbody className="table-body-data">{renderRow(data, headerName)}</tbody>
        </CSSTransition>
      </table>
    );
  };
  if (reconReport) {

    return (
      <div className="mb-5">
        <h4 className="text-center mt-4">
          CPS-TIPS On Demand Reconciliation Report
        </h4>
        <div className="d-flex justify-content-between">
          <div>TIPS Source File: {reconReport.tips_source_file ? reconReport.tips_source_file : '--'}</div>
          <button className="btn btn-primary mr-2" type="submit" onClick={downloadCsvReport}>Download</button>
        </div>
        <div>
          DSARs in TIPS(REQUEST_PENDING_SUBMISSION):{" "}
          {reconReport.dsars_in_tips_request_pending}
        </div>
        <div>DSARs in TIPS(OTHERS): {reconReport.dsars_in_tips_others}</div>
        <div>DSARs in CPS: {reconReport.dsars_in_cps}</div>
        {<div>
          <div>-------------------------------------</div>
          <h6 className="table-head-title">Requests in TIPS which are absent in CPS  ({reconReport.requests_in_tips_not_in_cps?.length || 0})</h6>
          {renderTable(reconReport.requests_in_tips_not_in_cps, 'TIPS')}
        </div>}
        {<div>
          <div>-------------------------------------</div>
          <h6 className="table-head-title">Requests in CPS which are absent in TIPS  ({reconReport.requests_in_cps_not_in_tips?.length || 0})</h6>
          {renderTable(reconReport.requests_in_cps_not_in_tips, 'CPS')}
        </div>}
        {<div>
          <div>-------------------------------------</div>
          <h6 className="table-head-title">Requests which have status mismatch CPS-TIPS ({reconReport.requests_with_mismatch?.length || 0})</h6>
          {renderTable(reconReport.requests_with_mismatch, 'CPSTIPS')}
        </div>}
        <div>-------------------------------------</div>
        <h6 className="table-head-title">Tips Status Legend</h6>
        {renderTable(reconReport.requests_tips_status_legend, 'TIPS_STATUS_LEGEND')}
        <CSSTransition in={reconReport !== null} appear={true} timeout={125} classNames="swipeD">
          {reconReport ?
            <p className="text-center">
              Generated On:&nbsp;
              <span>
                <span id="reconciliation-date">
                  {moment(lastGenerated).utc().format("MMM Do, YYYY HH:mm:ss (UTC)")}
                </span>
                <UncontrolledTooltip
                  placement="bottom"
                  target="reconciliation-date"
                >
                  {moment(lastGenerated).local().format('MMM Do, YYYY HH:mm:ss [(local)]')}
                </UncontrolledTooltip>
              </span>
            </p>
            :
            <p>&nbsp;</p>
          }
        </CSSTransition>
        <p className="text-center">
          <button type="button" className="btn btn-primary" onClick={regenerate}>
            Regenerate
          </button>
        </p>
      </div>)
  } else if (loading) {
    return (<div class="d-flex justify-content-center">
      <span className="loading-spin material-icons"> rotate_right</span>
    </div>)
  }
  return (<div className="mb-5">
    <p className="text-center">No Data available for CPS-TIPS On Demand Reconciliation Report.</p>
  </div>)
}
export default ReconciliationOnDemandReport;
