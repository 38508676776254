import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../../../RoutesWithAuth';
import axios from '../../../../common/AxiosConfig';
import { makeStyles } from "@material-ui/core/styles";
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';
import { Button, IconButton } from '@material-ui/core';
import { Cancel, Edit, Save } from '@material-ui/icons';
import FormGroup from '../../FormGroup';
import { LoadingIndicator } from '../../reports/reconcilationreport/MuiStyled';


const useStyles = makeStyles(() => ({
    userListWrapper: {
        height: 'calc(100vh - 300px)',
        overflowY: 'scroll'
    },
    filterWrapper: {
        marginTop: '10px',
        display: 'flex'
    },
    userHeader: {
        marginTop: '10px',
        padding: '0px 0px 10px 0px',
        boxShadow: '0px 0px 1px 0px rgb(0 0 0 / 41%)',
        marginLeft: '1px',
        marginRight: '10px',
        '& .header': {
            background: '#7f9ed7',
            // padding: '5px',
            padding: '5px 15px !important',
            color: "white",
            fontWeight: 'bold'
        },
        '& .roleText': {
            minWidth: '50px'
        },
        '& .roleInfo': {
            flex: 1
        },
        '& .role': {
            width: '220px',
            alignItems: 'baseline',
            display: 'inline-flex',
            float: 'right'
        }
    },
    userBodyWrapper: {
        boxShadow: '0px 0px 1px 0px rgb(0 0 0 / 41%)',
        margin: '5px 5px 0px 5px',
        padding: '5px',
        marginTop: '10px'
    },
    userBody: {
        margin: '10px 10px 0px 10px',
        minHeight: '45px',
        display: 'flex',
        flexWrap: 'wrap'
    },
    wrapper: {
        '& .MuiGrid-root.MuiGrid-item': {
            background: 'transparent'
        },
        '& .MuiFormControl-root': {
            background: '#f8f9fa'
        }
    },
    userColumn: {
        width: '15%',
        wordWrap: 'break-word',
        paddingRight: '10px',
        marginBottom: '10px',
        '& .Active': {
            fontWeight: 'bold',
            color: 'green'
        },
        '& .Disabled': {
            fontWeight: 'bold',
            color: 'red'
        },
        '& .label': {
            fontWeight: 'bold'
        }
    },
    SailpointBtnWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    SailpointBtn: {
        width: '140px',
        background: '#4472c4 !important',
        marginRight: '45px',
        color: 'white',
        textTransform: 'none'
    }
}));
function Users(props) {
    const classes = useStyles()
    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const isAdmin = context.isAdmin.get

    const [users, setUsers] = useState([])
    const [enableEditMode, setEnableEditMode] = useState()
    const [appliedFilters, setAppliedFilters] = useState({ user_status: 'All', user_role: 'All' })
    const [editUserRole, setEditUserRole] = useState();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        axios.get('users', {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setUsers(res.data);
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false);
            console.log('There was an error fetching users!', err)
        })
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken])
    /**
     * Method is responsible to update User_Role in the backend
     * @param {*User Object of the Edited User} user 
     * @returns Void
     */
    const setAdminStatus = (user) => {
        if (!editUserRole || editUserRole === user.role) {
            setEditUserRole(null);
            setEnableEditMode(null);
            return void 0;
        }
        setIsLoading(true);
        axios.post("users/set_admin", {
            user_id: user.user_id,
            is_admin: editUserRole === "Admin",
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // Update Role for the Edited User
            const newUsers = [...users]
            const userIndex = users.findIndex(x => x.user_id === user.user_id);
            newUsers[userIndex].role = editUserRole;
            setUsers(newUsers);
            // reset the Edit Mode to initiate State
            setEnableEditMode(null);
            setEditUserRole(null);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            if (err.response?.data.message) {
                // Displays the Error message in the Settings Main Component
                props.toggleError(err.response.data.message);
            }
        })
    }

    const columns = [{
        name: 'first_name',
        label: 'First Name',
    }, {
        name: 'last_name',
        label: 'Last Name'
    }, {
        name: 'workday_id',
        label: 'Workday ID'
    }, {
        name: 'email',
        label: 'Email'
    }, {
        name: 'user_status',
        label: 'User Status',
        width: '25%',
        type: 'boolean',
        values: {
            "Y": "Active",
            "N": "Disabled"
        }
    }, {
        name: 'department',
        label: 'Department'
    }, {
        name: 'employee_status',
        label: 'Employee Status'
    }, {
        name: 'location',
        label: 'Location'
    }, {
        name: 'create_date',
        label: 'Created On',
        type: 'date'
    }, {
        name: 'revoked_date',
        label: 'Disabled On',
        type: 'date'
    }, {
        name: 'guid',
        label: 'GUID',
        width: '25%'
    }, {
        name: 'last_login_time',
        label: 'Last Login',
        type: 'date'
    }];
    /**
     * Method returns the value based on the data Type 
     * date - returns the tooltip along with the UTC Time
     * boolen - returns the value along with the styling
     * @param {*Column Infor contans the name} column 
     * @param {*User Object} user 
     * @returns Formatted Value based on the column type
     */
    const renderValue = (column, user) => {
        if (column.type === "date" && user[column.name]) {
            return (
                <>
                    <span className="small" id={`user-${user.user_id}-last-login-time`}>
                        {moment(user[column.name]).utc().format("MMM Do, YYYY HH:mm:ss [(UTC)]")}
                    </span>
                    <UncontrolledTooltip
                        placement="bottom"
                        target={`user-${user.user_id}-last-login-time`}
                    >
                        {moment(user[column.name]).local().format("MMM Do, YYYY HH:mm:ss [(local)]")}
                    </UncontrolledTooltip>
                </>
            )
        } else if (column.type === "boolean" && user[column.name]) {
            return (<span className={column.values[user[column.name]]}>
                {column.values[user[column.name]]}
            </span>)
        }
        return user[column.name] || "--";
    }
    /**
     * Verifies the Edit enabled User and returns approprtiate action icons
     * @param {*} user 
     * @returns Actions icons are retured based on the edit mode of the user
     */
    const renderEditIcons = (user) => {
        // Verify if the edit action is clicked for the user
        if (enableEditMode === user.user_id) {
            return (
                <>
                    <IconButton
                        size="small"
                        aria-label="save"
                        data-testid={"save"}
                        onClick={() => { setAdminStatus(user) }}
                    >
                        <Save fontSize="small" />
                    </IconButton>
                    <IconButton 
                        data-testid={"cancel"}
                        size="small"
                        aria-label="cancel"
                        className={classes.textPrimary}
                        onClick={() => { setEnableEditMode(null); setEditUserRole(null); }}
                    >
                        <Cancel fontSize="small" color="error" />
                    </IconButton>
                </>
            )
        }
        return (
            <>
                <IconButton
                    className={classes.textPrimary}
                    size="small"
                    aria-label="edit"
                    data-testid={"edit"}
                    disabled={!isAdmin}
                    onClick={() => { !enableEditMode && setEnableEditMode(user.user_id) }}
                >
                    <Edit fontSize="small" />
                </IconButton>
            </>
        )

    }
    /**
     * Method Filters the user based on the selected filter
     * @param {*} user 
     * @returns
     * true - when the component/user should be rendered
     * false - when the component/ user should not be rendered
     */
    const shouldReturnUser = (user) => {
        let isFilterMatched = true;
        // Filter based on selected User Role 
        if (appliedFilters.user_role !== "All") {
            isFilterMatched = (user.role === appliedFilters.user_role)
        }
        // Filter based on the selected user status
        if (isFilterMatched && appliedFilters.user_status !== "All") {
            isFilterMatched = appliedFilters.user_status === 'Active' ? user.user_status === "Y" : user.user_status === "N";
        }

        return isFilterMatched;
    }
    return (
        <div>
            {isLoading && (<LoadingIndicator />)}
            <div className={classes.filterWrapper}>
                <FormGroup
                    fields={[{
                        type: 'multiselect',
                        name: 'user_status',
                        label: "User Status",
                        size: 2,
                        options: ["All", "Active", "Disabled"],
                        removeCheckBox: true,
                        defaultOption: appliedFilters.user_status
                    }, {
                        type: 'multiselect',
                        name: 'user_role',
                        size: 2,
                        label: "Role",
                        options: ["All", "Admin", "User"],
                        removeCheckBox: true,
                        defaultOption: appliedFilters.user_role
                    }]}
                    disableFormActions={true}
                    customFormUpdates={(formData) => {
                        setAppliedFilters(formData)
                    }}
                />
                <div className={classes.SailpointBtnWrapper}>
                    <Button className={classes.SailpointBtn} onClick={() => {
                        window.open(`${process.env.REACT_APP_SAIL_POINT_URL}`, '_blank');
                    }}>
                        Launch SailPoint
                    </Button>
                </div>
            </div>
            <div className={classes.userBodyWrapper}>
                <div className={classes.userListWrapper}>
                    {users?.map((user) => {
                        // Verify weather to render the user based on the applied Filter
                        if (!shouldReturnUser(user)) { return null; }
                        return (
                            <div className={classes.userHeader} key={user.user_id}>
                                <div className={"header"}>
                                    {user.display_name ? user.display_name : '--'}
                                    <div className={"role"}>
                                        <div className={"roleText"}>
                                            Role :
                                        </div>
                                        <div className={"roleInfo"}>
                                            {enableEditMode === user.user_id ? (
                                                <FormGroup
                                                    formWrapperClassName={classes.wrapper}
                                                    fields={[{
                                                        type: 'multiselect',
                                                        name: 'user_role',
                                                        size: 12,
                                                        options: ["User", "Admin"],
                                                        removeCheckBox: true,
                                                        defaultOption: editUserRole || user.role
                                                    }]}
                                                    disableFormActions={true}
                                                    customFormUpdates={(formData) => {
                                                        setEditUserRole(formData.user_role)
                                                    }}
                                                />
                                            ) : user.role}
                                        </div>
                                        {/* Enabling the Edit Icons only when the User status is Active */}
                                        {user.user_status === 'Y' && renderEditIcons(user)}
                                    </div>

                                </div>
                                <div className={classes.userBody}>
                                    {columns.map((column) => {
                                        return (<div className={classes.userColumn} style={{ width: column.width || '15%' }} key={user.user_id + '-inner-' + column.name}>
                                            <div className='label'>
                                                {column.label}
                                            </div>
                                            <div className='value'>
                                                {renderValue(column, user)}
                                            </div>
                                        </div>)
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>
    );
}

export default Users;
