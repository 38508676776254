import React from 'react';
import moment from 'moment';
import { ResponsiveLine } from '@nivo/line';


const assignColor = (d, toFilter) => {
    const colors = {
        "ISR": "#b96ee7",
        "Right to Correct": "#4472c4",
        "Right to Delete": "#ed7d31",
        "Right to Opt Out": "#a5a5a5",
        "Right to Know": "#ffc000",
        "Right to Terminate": "#ee553e",
        "Right to Create": "#64d9aa",
        "Total": "#535353",
    }

    if (toFilter.includes(d.id)) {
        return "#FFFFFF"
    } else {
        return colors[d.id]
    }
}

const getTooltip = (d, isYear) => {
    const tooltip = isYear
        ? `${d.point.serieId} (${d.point.data.x}): ${d.point.data.y} requests`
        : `${d.point.serieId} (${moment('01/' + d.point.data.x + '/2000').format("Do")}): ${d.point.data.y} requests`
    return (
        <div
            style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                color: "black",
            }}
        >
            {tooltip}
        </div>
    )
}

const TrendingChart = ({ data, toFilter, setToFilter, isYear }) => (
    <ResponsiveLine
        data={data}
        tooltip={(d) => getTooltip(d, isYear)}
        margin={{ top: 50, right: 5, bottom: 80, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Date',
            legendOffset: 65,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Requests',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        colors={(d) => assignColor(d, toFilter)}
        pointSize={10}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 50,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 115,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                onClick: (d) => {
                    if (toFilter.includes(d.id)) {
                        setToFilter(toFilter.filter(el => el !== d.id))
                    } else {
                        setToFilter([d.id].concat(toFilter))
                    }
                },
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    },
                ]
            }
        ]}
    />
)

export default TrendingChart;
