import * as React from 'react';
import InputBase from '@material-ui/core/InputBase';


export function GridEditInputCell(props) {
  const {
    id,
    value,
    formattedValue,
    api,
    field,
    row,
    colDef,
    cellMode,
    isEditable,
    tabIndex,
    hasFocus,
    getValue,
    ...other
  } = props;

  const inputRef = React.useRef();
  const [valueState, setValueState] = React.useState(value);

  const handleChange = React.useCallback(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      api.setEditCellValue({ id, field, value: newValue }, event);
    },
    [api, field, id],
  );

  React.useEffect(() => {
    setValueState(value);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[value]);

  return (
    <InputBase
      inputRef={inputRef}
      required={true}
      fullWidth
      type={colDef.type === 'number' ? colDef.type : 'text'}
      value={valueState || ''}
      onChange={handleChange}
      style={{
        border : '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius : '5px'
      }}
      {...other}
    />
  );
}
export const renderEditInputCell = (params) => <GridEditInputCell {...params} />;