import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { pageMapping } from './constants';
import axios from '../../../../common/AxiosConfig';
import { AppContext } from '../../../../../RoutesWithAuth';
import GetAppIcon from '@material-ui/icons/GetApp';
import { copyToClipboard } from '../../../../common/commons';


const useStyles = makeStyles(() => ({
    toolbar: {
        textAlign: "right",
        height: '35px',
        marginRight: '15px',
        '& .MuiButtonBase-root': {
            padding: '5px !important'
        }
    },
    JsonContentWrapper: {
        height: 'calc(100% - 40px)',
        overflowY: 'scroll',
        background: '#f8f9fa',
        boxShadow: 'inset 0rem 0rem 0.25rem rgb(0 0 0 / 8%)'
    },
    copyToClipboard: {
        position: 'absolute',
        right: '20px'
    }
}));


const ViewJson = ({ tabInfo, setDisplayErrMsg, setLoading }) => {
    const context = useContext(AppContext);
    const authToken = context.authToken.get;
    const classes = useStyles();
    //Fetching ${pageMapping[tabInfo.tabtype].url}...
    const [json, setJson] = useState();
    const [showCopySuccess, setShowCopySuccess] = useState(false);

    useEffect(() => {
        fetchData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
},[tabInfo.tabtype]);

    const fetchData = () => {
        const pageLevelInfo = pageMapping[tabInfo.tabtype];
        let requestUrl = `${pageLevelInfo.urlPrefix || 'ticket'}/${tabInfo.dsarRequestId}/${pageLevelInfo.url}`;
        if (pageLevelInfo.app_id) {
            requestUrl = `${requestUrl}/?app_id=${pageLevelInfo.app_id}`;
        }
        if(pageLevelInfo.payload_type){
            let appender = '/?';
            if(requestUrl.indexOf('?') !== -1){
                appender = '&';
            }
            requestUrl = `${requestUrl}${appender}payload_type=${pageLevelInfo.payload_type}`;
        }
        setLoading(true)
        axios.get(requestUrl, {
            headers: {
                Authorization: authToken,
            },
        })
            .then((res) => {
                setLoading(false)
                let jsonData = res.data;
                if(pageLevelInfo["responseKeyMapper"]){
                    jsonData = res.data[pageLevelInfo["responseKeyMapper"]]
                }
                setJson(jsonData);
            })
            .catch((err) => {
                if (err?.response?.data?.message) {
                    setDisplayErrMsg(err.response.data.message)
                }
                setLoading(false);
                setJson("");
                console.log(err);
            });
    };

    const downloadJSON = () => {
        const dsarRequestId = tabInfo.dsarRequestId;
        const link = document.createElement("a");
        const file = new Blob([JSON.stringify(json, null, 4)], {
            type: "application/json",
        });

        link.href = URL.createObjectURL(file);
        let linkAttr = dsarRequestId;
        linkAttr = pageMapping[tabInfo.tabtype]?.downloadFileName?.(dsarRequestId) || linkAttr;
        link.setAttribute("download", `${linkAttr}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
    };
    const copyClip = ()=>{
        copyToClipboard(json);
        setShowCopySuccess(true);
        setTimeout(() => {
            setShowCopySuccess(false);
        }, 1500);
    }

    return (<Box width={"100%"} height={"100%"} overflow={"hidden"}>
        <div className={classes.toolbar}>
            <Tooltip title={"download"}>
                <IconButton disabled={!json} onClick={() => { downloadJSON() }} aria-label="download">
                    <GetAppIcon />
                </IconButton>
            </Tooltip>
        </div>
        <Box className={classes.JsonContentWrapper}>
            <Tooltip open={showCopySuccess} title="Copied!" position="bottom" >
                <IconButton className={classes.copyToClipboard} disabled={!json} onClick={() => { 
                    copyClip()
                    }} aria-label="copy to Clipboard">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 0 24 24"
                        width="24">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" fill="currentColor"/>
                    </svg>
                </IconButton>
            </Tooltip>
            <pre>{json && JSON.stringify(json, null, 2)}</pre>
        </Box>

    </Box>)
}
export default React.memo(ViewJson);

