
import { Typography, makeStyles } from '@material-ui/core';
import React from 'react';

// Custom page Level Styling
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: '100%'

    },
    documentWrapper : {
        borderTop : "1px solid #dedfdf",
        marginRight : '10px'
    },
    documentsList : {
        boxShadow : '0px 0px 0.8px 0px rgb(0 0 0 / 40%)',
        height : '120px',
        padding : '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '20px',
        marginBottom: '15px',
        '& a':{
            textDecoration : 'underline'
        }
    }
}));
const ListOfDocuments = [
    {
        title: "21mm Monitoring Dashboard",
        link: "https://tmna-ctp.datadoghq.com/dashboard/kfm-bzv-9n5/ctcps21mmqadashboarddraft?from_ts=1603387008112&live=true&to_ts=1603991808112",
    },
    {
        title: "CTCPS DB Monitoring Dashboard",
        link: "https://tmna-ctp.datadoghq.com/monitors/manage?saved-view-id=504481",
    },
    {
        title: "CTCPS Monitoring Dashboard",
        link: "https://p.datadoghq.com/sb/rh1s03wkc079c6ke-3c1166eea7d1501e9f80cfe9814819aa",
    },{
        title: "DataDog Logs",
        link: "https://tmna-ctp.datadoghq.com/logs?query=%40aws.awslogs.logGroup%3A%2Faws%2Flambda%2Fccpa-prod* &cols=host%2Cservice&index=*&messageDisplay=inline&refresh_mode=sliding&storage=hot&stream_sort=desc&viz=stream&from_ts=1696023453170&to_ts=1696024353170&live=true",
    }
];

const CPSHealthCheck = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Typography variant="h6" className={'scopeHeader'}>
                CPS HEALTH
            </Typography>
            <div className={classes.documentWrapper}>
                <div className={classes.documentsList}>
                    {ListOfDocuments.map(({ title, link }) => (
                        <div key={title}>
                            <a href={link} target="_blank" rel="noopener noreferrer">{title}</a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default React.memo(CPSHealthCheck);