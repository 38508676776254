import React, { useState, useEffect, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { AppContext } from '../../RoutesWithAuth';
import { Link } from 'react-router-dom';
import axios from '../common/AxiosConfig';
import PageHeader from '../common/PageHeader';
import { withNavbar } from '../common/Navbar';
import { UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';


function AuditTrail(props) {
	const { dsarRequestId } = props.match.params
	const { url } = props.match
	const page = url.split('/').pop()

	const context = useContext(AppContext)
	const authToken = context.authToken.get

    const [Logs, setLogs] = useState([])
    const [filteredLogs, setFilteredLogs] = useState([])


	useEffect(() => {
		if (authToken !== null) {
			fetchData()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authToken])

	useEffect(() => {
        setFilteredLogs(Logs)
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[Logs])

    const fetchData = () => {
        axios.get(`ticket/audittrail/${dsarRequestId}`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setLogs(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

	const downloadLogs = () => {
        let csv = "#,Dsar ID,Time,Activity,Data,User"
        console.log(filteredLogs)
        filteredLogs.map((log,i) => (csv = csv + 
            `\n${i+1},${log.dsar_id},${moment(log.created_date).utc().format("MMM Do YYYY HH:mm:ss (UTC)")},${log.activity},${log.additional_data === null ? '' : 
            log.additional_data.replace(/[\n,]/g, ' ')},${log.user_name}`))
		const link = document.createElement('a');
        const file = new Blob(["\ufeff", csv])
        link.href = URL.createObjectURL(file);
        link.setAttribute('download', `Audit_Trail_${dsarRequestId}.csv`);
		document.body.appendChild(link);
		link.click();
		link.remove()
	}

	return (
		<div className="container mb-5">

			<PageHeader header="Audit Trail" removeMargin={true} />

			<p className="lead mb-2">
				<Link to={`/`}>Home&nbsp;</Link>
				&gt; <Link to={`/ticket/${dsarRequestId}`}>Request ID: {dsarRequestId}&nbsp;</Link>
				{page === 'viewResponseJSON' ?
					<span>&gt; &nbsp;
						<Link to={`/ticket/${dsarRequestId}/viewResponseFiles`}>
							View Response Files&nbsp;
						</Link>
					</span>
					: null
				}
				&gt; Audit Trail<br />
			</p>

			<div className="text-right mb-2">
				<button className="btn btn-sm btn-primary" disabled={typeof json === "string"} onClick={downloadLogs}>
					Download Logs
				</button>
			</div>

			
            <table className="table table-striped table-sm">
                <thead>
                    <tr className="text-center">
                        <th scope="col">#</th>
                        <th scope="col">Time</th>
                        <th scope="col">Activity</th>
                        <th scope="col">Data</th>
                        <th scope="col">User</th>
                    </tr>
                </thead>
                <CSSTransition in={filteredLogs.length > 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody className="text-center">
						{filteredLogs.map((log, i) => (
                            <AuditTrailRow key={log.id} serialNumber={i + 1} data={log} />
                        ))}
                    </tbody>
                </CSSTransition>
            </table>
            <p><em>Note: Audit logs may not be comprehensive for DSAR requests created before 04/15/2021</em></p>
		</div>
	);
}


function AuditTrailRow(props) {
    const { serialNumber, data} = props


    return (
        <tr>
            <td>{serialNumber}</td>
            <td id={`created-${serialNumber}`}>
                {moment(data.created_date).utc().format("MMM Do, YYYY HH:mm:ss (UTC)")}
                <UncontrolledTooltip placement="bottom" target={`created-${serialNumber}`}>
                    {moment(data.created_date).local().format('MMM Do, YYYY HH:mm:ss [(local)]')}
                </UncontrolledTooltip>
            </td>
            <td>{data.activity}</td>
            <td>{data.additional_data}</td>
            <td>{data.user_name}</td>
        </tr>
    )

}

export default withNavbar(AuditTrail);
