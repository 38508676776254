/**
 * View Request Component which displays the Request Details JSON
 * This component is displayed as part of a tab in request Queue Screen
 */

import React from 'react';
import { LoadingIndicator } from '../reports/reconcilationreport/MuiStyled';
import { useState, useContext, useEffect } from 'react';
import axios from '../../../common/AxiosConfig';
import { AppContext } from '../../../../RoutesWithAuth';
import { IconButton, makeStyles, Tooltip,Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Cancel, Edit, Save,History } from '@material-ui/icons';
import RequestQueueStatusUpdate from '../requestdetails/RequestQueueStatusUpdate';

// Custom Styles for the View Request component
const useStyles = makeStyles(() => ({
    headerList: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '20px',
        marginBottom: '15px',

    },
    errorcls : {
        color : '#dc3545'
    }
}))
// Set of fields that are displayed on the top of the tab
const ListOfFields = [{
    display: 'Request ID',
    mapping: 'dsar_id'
}, {
    display: "Requestor",
    mapping: "requestor"
}, {
    display: "Request Type",
    mapping: "ticket_type"
}, {
    display: 'Request Status',
    mapping: "ingest_status"
}]
const ViewRequest = (props) => {
    // state to store the API progress
    const [isInProgress, setIsInProgress] = useState(false);
    const [error, setError] = useState(false);
    // state to store the Enabled state of the Text area
    const [editEnabled, setEditEnabled] = useState(false);
    // Stores the Backend api response
    const [viewRequestResponse, setViewRequestResponse] = useState({});
    // stores the JSON data displayed in the text area
    const [editJSON, setEditJson] = useState("");
    const [dsar_id , setDsarId] = useState(props.dsar_id);
    const [displayStatusUpdate, setDisplayStatusUpdate] = useState(false);
    const [displayErrMsg, setDisplayErrMsg] = useState("");
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState("");

    const context = useContext(AppContext)
    const authToken = context.authToken.get
    const classes = useStyles();
    // fetch the data when there is change in the dsarId
    useEffect(() => {
        fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[dsar_id]);

    // triggers the api call and stores the response in the state
    const fetchData = (fallbackDsarId) => {
        setIsInProgress(true);
        axios.get(`queued_requests/${fallbackDsarId || dsar_id}/view`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            const view_resp = res.data[0];
            // storing the responmse in the state variable
            setViewRequestResponse(view_resp);
            // parsing the dsar json and storing in the state variable
            setEditJson(JSON.stringify(view_resp.dsar,null,2));
            setIsInProgress(false)
        }).catch(err => {
            // diplaying the error message when there is error from the api.
            let errormessage = err?.response?.data?.message ?? err.message;
            props.displayErrorMessage(errormessage);
            setIsInProgress(false)
        })
    }
    // Triggered when we clicked on the download icon.
    // triggers the API call and parses the response
    const handleDownloadView = () => {
        axios.get(`/queued_requests/${dsar_id}/json`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            // creating the clickable link
            const downloadLink = document.createElement("a");
            // formating the resonse to application json format
            const blob = new Blob([JSON.stringify(res.data, null, 4)], { type: 'application/json' })
            const url = URL.createObjectURL(blob)
            downloadLink.href = url
            // creating the file name with dsar Id extension
            downloadLink.download = `${dsar_id}.json`
            document.body.appendChild(downloadLink)
            // explicitly clicking the link to trigger the download
            downloadLink.click()
            // removing the dynamically created link since download is complete
            document.body.removeChild(downloadLink)
        }).catch(err => {
            console.log(err)
        })
    }
    // triggered when user clicks on the edit icon
    const handleEditClick = () => {
        // enabling the edit functionality
        setEditEnabled(true);
    }
    // triggered when user clicks on the save icon
    const handleSaveClick = () => {
        
        let parsedJson;
        // validating if the json enetered is valid.
        try {
            parsedJson = JSON.parse(editJSON);
        } catch (error) {
            parsedJson = null
        }
        // JSON parse error or empty json is handle here
        if(!parsedJson){
            setError("Unable to parse the request JSON")
            return false
        }
        setError(null);
        // removing the edit capability 
        setEditEnabled(false);

        setIsInProgress(true);
        let parsedEditedJson = JSON.parse(editJSON);
        axios.post('/queued_requests/update', [{
            ...viewRequestResponse,
            dsar : parsedEditedJson
        }], {
            headers: {
                Authorization: authToken
            }
        }).then(()=>{

            setIsInProgress(false)
            setDsarId(parsedEditedJson.dsarRequestId);
            // fetch the RequestQueue response  since there is change in the json.
            props.fetchRequestQueueData(parsedEditedJson.dsarRequestId);
            // reloading the current tab data with the latest dsar ID.
            fetchData(parsedEditedJson.dsarRequestId);
        }).catch(()=>{
            setIsInProgress(false)
        })
    }
    // triggered when user click on close when on edit view
    const handleCancelClick = () => {
        // disabling the edit mode.
        setEditEnabled(false);
        // resetting the JSON to the response stored to revert any user changes made.
        setEditJson(JSON.stringify(viewRequestResponse.dsar,null,2));
        // Removing Error Messages if available
        setError(null)
    }

    const handleClose = () => {
        // resetting the error message.
        setDisplayErrMsg(null);
        setDisplaySuccessMsg(null);
    }

    return (
        <div>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
             {displayErrMsg && <Snackbar open={!!displayErrMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {displayErrMsg}
                </Alert>
            </Snackbar>}
            {displaySuccessMsg && <Snackbar open={!!displaySuccessMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    {displaySuccessMsg}
                </Alert>
            </Snackbar>}
            {displayStatusUpdate && (
                <RequestQueueStatusUpdate
                    hideComponent={(reloadData) => {
                        if (reloadData) {
                            fetchData();
                        }
                        setDisplayStatusUpdate(false);
                    }}
                    data={viewRequestResponse}
                    dsarRequestId={dsar_id}
                    setDisplayErrMsg={setDisplayErrMsg}
                    setDisplaySuccessMsg={setDisplaySuccessMsg}
                    setIsLoading={setIsInProgress}
                />
            )}
            <div className={classes.headerList}>
                {ListOfFields.map(({ display, mapping }, index) => (
                    <div key={display}>
                        <b>{display}</b> : {viewRequestResponse[mapping]}
                    </div>
                ))}
                <div>
                     <Tooltip title={"Request Status Update"} >
                     <IconButton onClick={() => { setDisplayStatusUpdate(true) }}>
                        <History />
                    </IconButton>
                     </Tooltip>
                    <IconButton onClick={handleDownloadView} aria-label="download">
                        <GetAppIcon />
                    </IconButton>
                    {editEnabled ? (<>
                    <Tooltip title="Save">
                       
                        <IconButton
                            size="small"
                            aria-label="save"
                
                            onClick={handleSaveClick}
                        >
                            <Save fontSize="small"  />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                        <IconButton
                            size="small"
                            aria-label="cancel"
                            className={classes.textPrimary}
                            onClick={handleCancelClick}
                        >
                            <Cancel fontSize="small" color="error" />
                        </IconButton>
                        </Tooltip>
                    </>) : (<>
                        <Tooltip title="edit">
                        <IconButton
                            className={classes.textPrimary}
                            size="small"
                            aria-label="edit"
                            onClick={handleEditClick}
                        >
                            <Edit />
                        </IconButton>
                        </Tooltip>
                    </>)}

                </div>
            </div>
            <textarea
                disabled={!editEnabled}
                className={`${error && "is-invalid"} form-control`}
                id="JSON"
                rows="20"
                placeholder="Enter valid JSON here..."
                value={editJSON}
                onChange={e => {
                    // Update the state varaible when there is a change in the text area
                    setEditJson(e.target.value)
                }}
                required
            />
            <div className={classes.errorcls}>
                {error}
            </div>
            
        </div>
    )
}
// Exporting the React.memo so that unnessary rerendering is reduced for better performace.
export default React.memo(ViewRequest);