/**
 * DownStream Report Main index file which is refered in reports Page
 */
import React, { useState, useContext, useEffect, useRef } from "react";
import moment from "moment";
import axios from "../../../../common/AxiosConfig";
import { AppContext } from "../../../../../RoutesWithAuth";
import { Typography, makeStyles } from "@material-ui/core";
import FormGroup from "../../FormGroup";
import { RequestorMapping, v2UIRoutePath } from '../../../../common/commons';
import DownstreamBarGraph from "./DownstreamBarGraph";
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {

    },
    formGroupWrapper: {
        width: '600px',
        '& .multiSelectedOptions': {
            width: '140px'
        }
    },
    customInputCls: {
        width: '140px',
    },

}));
function DownstreamChart(props) {
    const context = useContext(AppContext)
    const location = useLocation();
    const authToken = context.authToken.get
    const classes = useStyles();
    const today = moment();
    const formGroupRef = useRef();
    //state for maintaining form related data
    const [formData, setFormData] = useState({
        from_date: "",
        to_date: "",
        requestor: ['All']
    });
    const [downstreamMetrics, setDownstreamMetrics] = useState([])

    useEffect(() => {
        // resetting the Form when there is change in the Location state.
        formGroupRef.current.resetFormToDefaults();
        setFormData({
            from_date: "",
            to_date: "",
            requestor: ['All']
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.fromredirection])

    useEffect(() => {
        if (authToken) {
            fetchData()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, JSON.stringify(formData)])

    const fetchData = () => {
        let requestor = formData.requestor.join(', ')?.toLowerCase();
        if (requestor === "all") {
            requestor = "ALL"
        } else if(requestor === "") {
            return false
        }

        axios.get('/reports/aging', {
            params: {
                metric_age: "all",
                downstream_chart: true,
                ...formData,
                requestor
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setDownstreamMetrics(
                res.data.old.reverse().map(system => {
                    const downstreamTotal = system.data.reduce((sum, num) => sum + num, 0)

                    return {
                        name: system.name + ` (${downstreamTotal})`,
                        ISR: system.data[0],
                        RTC: system.data[1],
                        RTCR: system.data[2],
                        RTD: system.data[3],
                        RTK: system.data[4],
                        RTO: system.data[5],
                        RTT: system.data[6],
                    }
                })
            )

        }).catch(err => {
            console.log(err)
        })
    }

    return (

        <div className={`${classes.root}`}>
            <Typography variant="h6" className={'tabHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);
                    return {
                        pathname: v2UIRoutePath + "reports/downstreamreport",
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    DOWNSTREAM REPORT
                </Link>
            </Typography>
            <FormGroup
                formWrapperClassName={classes.formGroupWrapper}
                ref={formGroupRef}
                fields={[{
                    type: 'date',
                    name: "from_date",
                    label: "From Date",
                    defaultOption: "",
                    inputAdditionalProps: {
                        min: "2020-01-01",
                        max: today.format("YYYY-MM-DD")
                    }
                }, {
                    type: 'date',
                    name: "to_date",
                    label: "To Date",
                    defaultOption: "",
                    inputAdditionalProps: {
                        min: formData?.from_date || "2020-01-01",
                        max: today.format("YYYY-MM-DD")
                    }
                }, {
                    type: 'multiselect',
                    name: 'requestor',
                    label: 'Requestor',
                    isMulti: true,
                    customInputCls: classes.customInputCls,
                    options: ["All", ...Object.values(RequestorMapping)],
                    defaultOption: 'All',
                    size: 2
                }]}
                disableFormActions={true}
                customFormUpdates={(formData) => {
                    // This Method is called when ever there is any change in the form fields
                    setFormData(formData)
                }}
            />
            <div style={{ height: "570px" }}>
                <DownstreamBarGraph
                    data={downstreamMetrics}
                />
            </div>
        </div>
    );
}

export default React.memo(DownstreamChart);
