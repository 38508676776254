import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../RoutesWithAuth';
import { Link } from 'react-router-dom';
import { withNavbar } from '../common/Navbar';
import axios from '../common/AxiosConfig';
import PageHeader from '../common/PageHeader';


function CreateRequest(props) {
	const { history } = props;

	const context = useContext(AppContext)
	const authToken = context.authToken.get
	const userId = context.userId.get

	const [users, setUsers] = useState([]);

	const [json, setJson] = useState('');
	const [assignedTo, setAssignedTo] = useState('');
	const [processing, setProcessing] = useState(false);

	useEffect(() => {
		setAssignedTo(userId)
	// eslint-disable-next-line react-hooks/exhaustive-deps
},[userId])

	useEffect(() => {
		if (authToken !== null) {
			const fetchData = () => {
				// Get the list of all users
				axios.get('users', {
					headers: {
						Authorization: authToken
					}
				}).then(res => {
					if (res.data.length) {
						setUsers(res.data.filter(user => user.user_status === 'Y' && !user.is_disabled ))
					}
				}).catch(err => {
					console.log('There was an error fetching users!', err)
				})
			}

			fetchData()
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken])

	const handleSubmit = (e) => {
		e.preventDefault()
		setProcessing(true)

		axios.post('ticket', {
			creatorId: userId,
			assigned_to: assignedTo === "" ? null : assignedTo,
			body: json
		}, {
			headers: {
				Authorization: authToken
			}
		}).then(res => {
			console.log('Ticket has been created!')
			setProcessing(false)
			history.replace(`/ticket/${res.data.id}`)
		}).catch(err => {
			console.log('There was an error creating the ticket!', err)
			// Display an error message to the user if the creation did not complete
			const errorMessage = document.getElementById('error-message')
			if (err.response.data.error){
				if (err.response.data.error.includes("Error 522 Bad Gateway")) {
					errorMessage.innerHTML = err.response.data.message.split('\n')[0] + " --- CTPs /v5/vehicle endpoint returned an error: Error 522 Bad Gateway"
				}
				else if (err.response.data.error.includes("APIGW-401")) {
					errorMessage.innerHTML = err.response.data.message.split('\n')[0] + " --- CTP Vehicle API endpoint returned an error: Unauthorized - Access Denied"
				}
				else if (err.response.data.error.includes("Failed validating")) {
					errorMessage.innerHTML = err.response.data.message.split('\n')[0] + " --- JSON Validation Failed due to incorrect schema, pls check logs for info"
				}
				else{
					errorMessage.innerHTML = err.response.data.message.split('\n')[0] +" --- "+ err.response.data.error
				}
			}
			else{
				errorMessage.innerHTML = err.response.data.message.split('\n')[0]
			}
			
			document.getElementById('JSON').classList.add('is-invalid')

			if (err.response.data.dsar_id) {
				errorMessage.innerHTML += `<br /><a href='/ticket/${err.response.data.dsar_id}'>View Existing Ticket</a>`
			}
			setProcessing(false)
		})
	}

	return (
		<div className="container">

			<PageHeader header="DSAR Request" removeMargin={true} />

			<p className="lead mb-4">
				<Link to={`/`}>Home&nbsp;</Link>
				&gt; Create a New Request
			</p>

			<form onSubmit={handleSubmit}>
				<div className="form-group row">
					<label htmlFor="JSON">DSAR JSON</label>
					<textarea className="form-control" id="JSON" rows="25" placeholder="Enter valid JSON here..."
						value={json} onChange={e => { setJson(e.target.value) }} required>
					</textarea>
					<div className="invalid-feedback" id="error-message">
						Could not create a response. Please check if JSON is valid.
					</div>
				</div>

				<div className="row justify-content-center">
					<div className="form-group col-6">
						<label htmlFor="assignTo">Assign To</label>
						<select className="form-control" id="assignTo" value={assignedTo} onChange={e => setAssignedTo(e.target.value)} required>
							{
								users.map(user => {
									return (
										<option key={user.user_id} value={user.user_id}>{user.first_name} {user.last_name}</option>
									)
								})
							}
							<option value={null}></option>
						</select>
					</div>
				</div>

				<div className="form-group-row text-center">
					<button type="submit" className="btn btn-primary" disabled={processing}>
						{processing ? <span className="spinner-border spinner-border-sm mr-2"></span> : null}
						{processing ? "Processing" : "Create"}
					</button>
				</div>
			</form>

		</div>
	);
}

export default withNavbar(CreateRequest, 4);
