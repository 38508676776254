import React from 'react';
import ListOfItems from '../ListOfItems';

const Header = ({ data, handleReconsolidate }) => {
    const columns = [{
        display: "Response Generated On",
        type: "time",
        mapping: "response_generated_time_utc",
        tooltip: "response_generated_time_local"
    }, {
        display: "Response Uploaded On",
        type: "time",
        mapping: "data_uploaded_time_utc",
        tooltip: "data_uploaded_time_local"
    }, {
        display: "Processing Time",
        mapping: "processing_time"
    },
     {
        display: "Re-consolidate Response",
        type: "button",
        text: "Reconsolidate",
        handleCallback : ()=>{
            handleReconsolidate()
        }
    }]
    return (
        <>
            <ListOfItems
                items={columns}
                data={data}
            />
        </>
    )
}

export default React.memo(Header);