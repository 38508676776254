import React, { useState, useEffect } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import axios from "../../../../../common/AxiosConfig";
import moment from "moment";
import CaliforniaBarGraph from "./CaliforniaBarGraph";


function CaliforniaReport(props) {
    const { authToken } = props
    const today = moment().format("YYYY-MM")

    const [date, setDate] = useState(today)
    const [requestType, setRequestType] = useState("%")

    const [reportStats, setReportStats] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [tableFooter, setTableFooter] = useState(moment().format("MMMM YYYY"))
    const [requestTypeFooter, setRequestTypeFooter] = useState("All")

    useEffect(() => {
        if (authToken) {
            axios.get("/reports/california", {
                params: {
                    date: date,
                    request_type: requestType,
                },
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setReportStats(res.data.reverse())
                setTableFooter(moment(date).format("MMMM YYYY"))
                setRequestTypeFooter(requestTypeMapping[requestType])
                setLoaded(true)
            }).catch(err => {
                console.log(err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken, date, requestType])


    return (
        <div className="mb-5">

            <form className="form-inline mt-3 d-flex">
                <div className="form-group mr-auto mb-2">
                    <label className="mr-1" htmlFor="duration">Select Month:</label>
                    <input type="month" className="form-control date-form" value={date}
                        min="2020-01" max={today} onChange={e => setDate(e.target.value)} required
                    />
                </div>
                <div className="form-group">
                    <label className="mr-1" htmlFor="request_type">Request Type:</label>
                    <select className="form-control" value={requestType} onChange={e => setRequestType(e.target.value)} required>
                        <option value="%">All</option>
                        <option value="Right-to-Correct">Right to Correct</option>
                        <option value="Right-to-Delete">Right to Delete</option>
                        <option value="Right-to-Know-Detailed">Right to Know</option>
                        <option value="Right-to-OptOut">Right to Opt Out</option>
                    </select>
                </div>
            </form>

            <h4 className="text-center mt-4">California Customer Chart</h4>

            {reportStats.length ?
                <div style={{ height: "500px" }}>
                    <CaliforniaBarGraph data={reportStats} />
                </div>
                :
                <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: "500px" }}>
                    {loaded ?
                        <div>
                            <h5>No Requests to Display</h5>
                            <p>Please adjust the filter criteria</p>
                        </div>
                        : null
                    }
                </div>
            }

            <SwitchTransition>
                <CSSTransition key={tableFooter} timeout={250} classNames="swipeR">
                    <div className="font-italic text-center">Date Range: {tableFooter}</div>
                </CSSTransition>
            </SwitchTransition>
            <SwitchTransition>
                <CSSTransition key={requestTypeFooter} timeout={250} classNames="swipeR">
                    <div className="font-italic text-center">Request Type: {requestTypeFooter}</div>
                </CSSTransition>
            </SwitchTransition>
        </div>
    );
}

export default CaliforniaReport;

const requestTypeMapping = {
    "Right-to-Know-Detailed": "Right to Know",
    "Right-to-Delete": "Right to Delete",
    "Right-to-OptOut": "Right to Opt Out",
    "Right-to-Correct": "Right to Correct",
    "%": "All",
}
