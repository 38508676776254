import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import axios from '../../../../../common/AxiosConfig';
import moment from 'moment';
import Papa from 'papaparse';
import { removeFeedback } from '../../../../../common/commons';

const ErrorFeedBackDomId = 'adv-search-feedback';
const AllRequests = 'All Requests';

function DeletionReport(props) {

    const { authToken } = props
    const [deletedRequests, setDeletedRequests] = useState([])

    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(null);
    const [pagination, setPagination] = useState([]);
    const [processing, setProcessing] = useState(false);

    const length_deletedRequests = deletedRequests.length;

    const today = moment()
    const [duration, setDuration] = useState("Month")
    const [date, setDate] = useState(today)

    const [currentPage, setCurrentPage] = useState(1)

    const [startIndex, setStartIndex] = useState(1)

    const downloadDeletionReport = () => {

        setProcessing(true);
        axios.get('/reports/deletion-report', {
            params: {
                duration: duration === AllRequests ? null : duration,
                date: duration === AllRequests ? null : date.format("YYYY-MM"),
                page: currentPage - 1,
                download_csv: true,
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            if (res.status === 204) {
                console.log('No search records found')
                setProcessing(false)
                setDeletedRequests([])
                setTotalPages(0)
                const feedback = document.getElementById(ErrorFeedBackDomId)
                if (feedback) {
                    feedback.innerHTML = "No records to download"
                    removeFeedback(ErrorFeedBackDomId)
                }
            } else if (res.data.email_sent === "1") {
                setProcessing(false);
                const feedback = document.getElementById(ErrorFeedBackDomId)
                if (feedback) {
                    feedback.innerHTML = res.data.message
                    removeFeedback(ErrorFeedBackDomId)
                }
            } else if (res.data.includes("/DELETION_REPORT/")) {
                const link = document.createElement('a');
                link.href = res.data;
                document.body.appendChild(link);
                link.click();
                link.remove()
                setProcessing(false)
            }
            else {
                // Don't update the state if download_csv is set to true
                // Download the csv file
                const csv = Papa.unparse(res.data)
                createBlobAndDownload(csv)
                setProcessing(false)
            }
        }).catch(err => {
            console.log(err)
            setTotalPages(0)
            setProcessing(false)
            const feedback = document.getElementById(ErrorFeedBackDomId)
            if (feedback) {
                feedback.innerHTML = "Error occured in fetching search records."
                removeFeedback(ErrorFeedBackDomId)
            }
        })
    }
    const createBlobAndDownload = (csv) => {
        const downloadLink = document.createElement("a");
        const blob = new Blob(["\ufeff", csv])
        const url = URL.createObjectURL(blob)
        downloadLink.href = url
        const timestamp = moment().format("YYYYMMDDHHmmss")
        downloadLink.download = `CPS_Deletion_Report_${timestamp}.csv`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }
    useEffect(() => {
        if (authToken) {
            axios.get('/reports/deletion-report', {
                params: {
                    duration: duration === AllRequests ? null : duration,
                    date: duration === AllRequests ? null : date.format("YYYY-MM"),
                    page: currentPage - 1,
                },
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setDeletedRequests(res.data)
                setTotalPages(Math.ceil(res.data[0].total_results / 20))
                setTotalResults(res.data[0].total_results)
                setStartIndex((currentPage - 1) * 20)
            }).catch(err => {
                console.log(err)
                setTotalPages(0)
                setTotalResults(0)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken, date, duration, currentPage])

    useEffect(() => {
        const pages = []
        for (let index = 0; index < totalPages; index++) {
            if (index + 1 === currentPage) {
                pages.push(
                    <li className="page-item disabled" key={index}><button className="page-link">{index + 1}</button></li>
                )
            } else {
                pages.push(

                    <li className="page-item">
                        <button className="page-link" onClick={() => { setCurrentPage(index + 1) }}>{index + 1}</button>
                    </li>
                )
            }
        }

        setPagination(pages)
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[totalPages, currentPage])


    return (
        <div className="mb-5">
            <h4 className="text-center">Deletion Report</h4>
            <form className="form-inline mt-3">
                <div className="form-group mr-2 mb-2">
                    <label className="mr-1" htmlFor="duration">Duration:</label>
                    <select className="form-control" id="duration" value={duration} onChange={e => { setDuration(e.target.value); setCurrentPage(1); setStartIndex(1) }}>
                        <option>{AllRequests}</option>
                        <option>Month</option>
                    </select>
                </div>
                {duration === 'Month' ?
                    <div className="form-group mr-2 mb-2">
                        <input type="month" className="form-control date-form" value={date.format("YYYY-MM")}
                            min="2020-01" max={today.format("YYYY-MM")} onChange={e => setDate(moment(e.target.value))} required
                        />
                    </div>
                    : null
                }
                <div style={{ flex: 1 }}></div>
                <button className="btn btn-primary" type="button" disabled={processing} onClick={() => downloadDeletionReport()} >
                    {processing ? <span className="spinner-border spinner-border-sm mr-2"></span> : null}
                    {processing ? "Processing" : "Download"}
                </button>
                <div class="ml-4">
                    Total Results: {totalResults || 0}
                </div>

            </form>
            <div className="mt-2 text-center text-danger small" id={ErrorFeedBackDomId}></div>
            <div>
                {length_deletedRequests > 0 ?
                    <table className="table table-striped">
                        <thead>
                            <tr className="text-center">
                                <th scope="col">#</th>
                                <th scope="col">DSAR ID</th>
                                <th scope="col">Requestor</th>
                                <th scope="col">Request Type</th>
                                <th scope="col">Request Status</th>
                                <th scope="col">Created Date</th>
                                <th scope="col">Deleted Date</th>
                                <th scope="col">Deletion Reason</th>
                                <th scope="col">Deleted By</th>
                            </tr>
                        </thead>
                        <CSSTransition in={deletedRequests.length > 0} appear={true} timeout={125} classNames="swipeD">
                            <tbody className="text-center">
                                {deletedRequests.map((request, i) => (
                                    <tr key={request.DSAR}>
                                        <td>{startIndex + i + 1}</td>
                                        <td>{request.DSAR}</td>
                                        <td>{request.Requestor}</td>
                                        <td>{request["Request Type"]}</td>
                                        <td>{request["Request Status"]}</td>
                                        <td>{request["Created Date"] || null}</td>
                                        <td>{request["Deleted Date"] || null}</td>
                                        <td>{request["Deletion Reason"]}</td>
                                        <td>{request["Deleted By"]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </CSSTransition>
                    </table>
                    :
                    <div className="mb-5">
                        <p className="text-center">No deleted requests found in CPS</p>
                    </div>
                }
            </div>
            <nav>
                <ul className="pagination justify-content-center">
                    {(totalPages > 1 && totalPages <= 5) ?
                        pagination.map(element => element)
                        :
                        null
                    }
                    {totalPages > 5 ?
                        <ul className="pagination justify-content-center">
                            <li className="page-item mr-2">
                                <button className="page-link" onClick={() => { setCurrentPage(1) }}>First</button>
                            </li>
                            {pagination.map((element, index) => {
                                if (currentPage > 3 && currentPage <= totalPages - 3) {
                                    if (index >= currentPage - 3 && index <= currentPage + 1) {
                                        return element
                                    }
                                } else if (currentPage <= 3 && index <= 4) {
                                    return element
                                } else if (currentPage >= totalPages - 2 && index >= totalPages - 5) {
                                    return element
                                }
                                return null
                            })}
                            <li className="page-item ml-2">
                                <button className="page-link" onClick={() => { setCurrentPage(totalPages) }}>Last</button>
                            </li>
                        </ul>
                        : null
                    }
                </ul>
            </nav>
        </div>
    )
}

export default DeletionReport;