import React, { useState, useEffect } from 'react';
import axios from '../../common/AxiosConfig';

import '../../../css/smallComponents.css'

let count = 0

function AttributeRow(props) {
    const { authToken, isAdmin, data, fetchMasterKeys, tableView, setTableView, addKeyMode } = props
    const isRestricted = restrictedSystems.includes(data.app_name)
    const isKeyEditRestricted = restrictedKeyEdit.includes(data.app_name)
    let userInput = null

    const [editMode, setEditMode] = useState(false)

    const [appName, setAppName] = useState(data.app_name)
    const [elementType, setElementType] = useState(data.element_type)
    const [key, setKey] = useState(data.key)
    const [displayTerm, setDisplayTerm] = useState(data.display_term)
    const [desc, setDesc] = useState(data.description)
    const [sample, setSample] = useState(data.sample)
    const [tips, setTips] = useState(data.tips)
    const [active, setActive] = useState(data.is_active)

    const [formatting, setFormatting] = useState("")

    useEffect(() => {
        const getFormatting = () => {
            let className = ""
            if (!active) { className += "text-danger " }
            if (elementType === "Transaction") { className += "font-weight-bold" }
            setFormatting(className)
        }
        getFormatting()
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[active, elementType])

    const submitChanges = () => {
        axios.put(`/rtk_keys/${data.key}`, {
            app_name: appName,
            element_type: elementType,
            is_active: active,
            new_key: key,
            display_term: displayTerm,
            desc: desc,
            sample: sample,
            tips: tips
        }, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setEditMode(false)
            fetchMasterKeys();
        }).catch(err => {
            if (err.response) {
                const errorFeedback = document.getElementById(`error-${data.key}`)
                errorFeedback.innerHTML = `${err.response.data.message}`
            }
        })
    }

    const openDeleteModal = () => {
        const modalTrigger = document.getElementById("show-delete-key-modal")
        const modalText = document.getElementById("delete-key-modal-text")
        const deleteBtn = document.getElementById("delete-key-btn")

        deleteBtn.setAttribute("data-key", data.key)
        modalText.innerHTML = `Are you sure you want to delete the following key:<br /><br />${data.key}`
        modalTrigger.click()
    }

    const exitEditMode = () => {
        setEditMode(false)
        setAppName(data.app_name)
        setElementType(data.element_type)
        setKey(data.key)
        setDisplayTerm(data.display_term)
        setDesc(data.description)
        setSample(data.sample)
        setTips(data.tips)
        setActive(data.is_active)
    }

    if (tableView) {
        count = 0
    }

    if (addKeyMode) {
        count += 1
    }

    if (editMode) {
        if (!tableView) {
            if (count === 0) {
                userInput = window.confirm('Discard changes and navigate to collapsed view ?')
                if (userInput === true) {
                    setTableView(false)
                    exitEditMode()
                    count += 1
                }
                else {
                    setTableView(true)
                    count = 0
                }
            }
            else if (count !== 0) {
                setTableView(false)
                exitEditMode()
            }
        }
        return (
            <tr key={data.key}>
                <td>
                    {isRestricted ?
                        data.display_name
                        :
                        <select className="form-control form-control-sm" value={appName} onChange={e => { setAppName(e.target.value) }}>
                            <option value='CT_MP'>CT Marketing Portal</option>
                            <option value='CTP'>CTP</option>
                            <option value='Digital Key'>Digital Key</option>
                            <option value='GAP'>GAP</option>
                            <option value='Mobility'>Mobility</option>
                            <option value='CT_PAYMENT'>Payment Platform</option>
                            <option value='CT_EV'>Public Charging</option>
                            <option value='TC'>TC</option>
                            <option value='VGI'>VGI</option>
                        </select>
                    }
                </td>
                <td>
                    {isRestricted ?
                        elementType
                        :
                        <select className="form-control form-control-sm" value={elementType} onChange={e => { setElementType(e.target.value) }}>
                            <option>Transaction</option>
                            <option>Attribute</option>
                        </select>
                    }
                </td>
                <td>
                    {isKeyEditRestricted ? key :
                        <input type="text" className="form-control form-control-sm" value={key} onChange={e => { setKey(e.target.value) }} required />
                    }
                    <span id={`error-${data.key}`} className="small text-danger mb-1"></span>
                </td>
                <td>
                    <input type="text" className="form-control form-control-sm" value={displayTerm} onChange={e => { setDisplayTerm(e.target.value) }} />
                    <span id={`error-${data.display_term}`} className="small text-danger mb-1"></span>
                </td>
                <td>
                    <input type="text" className="form-control form-control-sm" value={desc} onChange={e => { setDesc(e.target.value) }} />
                    <span id={`error-${data.description}`} className="small text-danger mb-1"></span>
                </td>
                <td>
                    <input type="text" className="form-control form-control-sm" value={sample} onChange={e => { setSample(e.target.value) }} />
                    <span id={`error-${data.key}`} className="small text-danger mb-1"></span>
                </td>
                <td>
                    <select className="form-control form-control-sm" value={tips} onChange={e => { setTips(e.target.value) }}>
                        <option></option>
                        <option>Available</option>
                        <option>Not Applicable</option>
                        <option>Pending</option>
                    </select>
                </td>
                <td>
                    <select className="form-control form-control-sm" value={active} onChange={e => { setActive(e.target.value === 'true' ? true : false) }}>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                    </select>
                </td>
                {isAdmin ?
                    <td className="text-nowrap">
                        <button type="button" className="btn btn-sm small-button btn-danger mr-3" disabled={isRestricted} onClick={openDeleteModal}>
                            <i className="material-icons small-icon">delete</i>
                        </button>
                        <button type="button" className="btn btn-sm small-button btn-danger mr-1" onClick={exitEditMode}>
                            <i className="material-icons small-icon">clear</i>
                        </button>
                        <button type="button" className="btn btn-sm small-button btn-success" onClick={submitChanges}>
                            <i className="material-icons small-icon">done</i>
                        </button>
                    </td>
                    : null
                }
            </tr>
        )
    } else {
        if (tableView) {
            return (
                <tr key={data.key} className={formatting}>
                    <td><div>{data.display_name}</div></td>
                    <td><div>{elementType}</div></td>
                    <td><div>{key}</div></td>
                    <td><div>{displayTerm}</div></td>
                    <td><div>{desc}</div></td>
                    <td><div>{sample}</div></td>
                    <td><div>{tips == null ? "" : tips}</div></td>
                    <td><div>{active ? "True" : "False"}</div></td>
                    {isAdmin ?
                        <td>
                            <button type="button" className="btn btn-sm small-button btn-warning" onClick={() => { setEditMode(true); setTableView(true); }}>
                                <i className="material-icons small-icon">edit</i>
                            </button>
                        </td>
                        : null
                    }
                </tr>
            )
        } else {
            return (
                <tr key={data.key} className={formatting}>
                    <td><div>{data.display_name}</div></td>
                    <td><div>{elementType}</div></td>
                    <td><div>{key}</div></td>
                    <td><div>{active ? "True" : "False"}</div></td>
                    {isAdmin ?
                        <td>
                            <button type="button" className="btn btn-sm small-button btn-warning" onClick={() => { setEditMode(true); setTableView(true); }}>
                                <i className="material-icons small-icon">edit</i>
                            </button>
                        </td>
                        : null
                    }
                </tr>
            )
        }

    }

}

export default AttributeRow;

const restrictedSystems = ["SXM", "Cerence", "Service Connect", "DriverWaiver", "HireVue", "ITGFLEET"]
const restrictedKeyEdit = ["DriverWaiver", "HireVue", "ITGFLEET"]
