import React, { useState, useEffect } from 'react';
import DataGrid from '../../grid';
import { Box, makeStyles } from '@material-ui/core';
import Pagination from '../../grid/Pagination';
import {
    COLUMNSTIPS,
    COLUMNSQB64,
    displayFieldsTips,
    displayFieldsQB64
} from './reconcilConstants';


const useStyles = makeStyles(() => ({
    reconGridWrapper: {
        // Custom Class for the Row When the Row is not present is CPS or a status mismatch
        '& .isInvalid-row-cust-cls': {
            color: 'red'
        },
    },
    displayFieldsWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    userColumn: {
        width: '25%',
        marginBottom: '10px',
        '& .label': {
            fontWeight: 'bold'
        }
    },

}));
function ReconcilationGrid({ data, handleDownload, isTIPS = true }) {
    // State to handle Active Page
    const [currentPage, setCurrentPage] = useState(1);
    // State to handle the Paginated Data
    const [gridData, setGridData] = useState([]);
    const [activeFilters, setActiveFilters] = useState({});

    const classes = useStyles()

    // Reloading Grid to page 1 when there is change in Filters
    useEffect(() => {
        setCurrentPage(1);

  // eslint-disable-next-line react-hooks/exhaustive-deps
},[activeFilters])

    useEffect(() => {
        // Reloading the Pagination Data when there is change in Data or Active Page
        handleLocalPagination(currentPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, data?.gridData, data, activeFilters])

    const getLocalFilteredGridData = () => {
        let originalData = data?.gridData || [];

        /**
        * Adding Custom Logic For mimicing Remote filtering
        */
        const activeFilterColumns = Object.keys(activeFilters);
        // Iterating through the Applied Filtered Columns
        activeFilterColumns.forEach(activeFilterColumn => {
            // Filtervalue for specific column
            const remoteFilterStatusColumn = activeFilters?.[activeFilterColumn]?.value || null;
            if (remoteFilterStatusColumn !== null) {
                // Filtering the Table Data Based on the Filter Value Selected
                originalData = originalData.filter((row) => {
                    return row[activeFilterColumn] === remoteFilterStatusColumn
                })
            }
        });
        return originalData;
    }
    const handleLocalPagination = (pageToLoad) => {

        let originalData = getLocalFilteredGridData();
        if (originalData && originalData.length > 0) {
            // Finding the Active Page Records and loading it into grid data
            const pageSize = 100;
            const startPageIndex = ((pageToLoad - 1) * pageSize);
            setGridData(originalData.slice(startPageIndex, startPageIndex + pageSize));
        } else if (gridData && gridData.length > 0) {
            setGridData([]);
        }
    }
    const handlePageChange = (page) => {
        setCurrentPage(page);
    }
    const handleDownloadGrid = () => {
        const filterPayload = {};
        const activeFilterColumns = Object.keys(activeFilters);
        // Iterating through the Applied Filtered Columns
        activeFilterColumns.forEach(activeFilterColumn => {
            // Filtervalue for specific column
            let filterValueOfColumn = activeFilters?.[activeFilterColumn]?.value || null;
            if (filterValueOfColumn !== null) {
                // Handling this check for the boolean columns since API requires in true/false instead of Yes/NO
                if (["isStatusMismatch", "isPresentTips", "isPresentcps", "isPresentQB64"].includes(activeFilterColumn)) {
                    filterValueOfColumn = filterValueOfColumn === "Yes";
                }
                filterPayload[activeFilterColumn] = filterValueOfColumn;
            }
        });
        // Passing the ISTIPS prop to differentiate between TIPS and QB64
        handleDownload(filterPayload, isTIPS);
    }
    const renderValue = (name) => {
        // Return value if present other wise display -- when there is no value
        return (data?.[name] === "" ? "--" : data?.[name])
    }
    // calculating the total Number of records available in the Original response data
    let totalResults = getLocalFilteredGridData()?.length
    totalResults = totalResults || 0;
    // Displaying the Columns and Fields based on the TIPS/QB64 view
    const displayFields = isTIPS ? displayFieldsTips : displayFieldsQB64;
    const COLUMNS = isTIPS ? COLUMNSTIPS : COLUMNSQB64;

    return (
        <Box width={'100%'} className={classes.reconGridWrapper}>
            <div className={classes.displayFieldsWrapper}>
                {displayFields.map((displayField) => {
                    return (<div className={classes.userColumn} style={{ width: displayField.width || '25%' }} key={displayField.name}>
                        <div className='label'>
                            {displayField.label}
                        </div>
                        <div className='value'>
                            {renderValue(displayField.name)}
                        </div>
                    </div>)
                })}
            </div>
            <DataGrid
                columns={COLUMNS}
                enableCustomFiltering={true}
                getRowClassName={(params) => {
                    const _classes = [];
                    const _row = params?.row;
                    // For Displaying the Custom Class when request is not present in CPS or Status is Mismatch
                    if (_row?.isPresentcps === "No" || _row.isStatusMismatch === "Yes") {
                        _classes.push("isInvalid-row-cust-cls");
                    }
                    return _classes.join(" ");
                }}
                rows={gridData?.map((_gridData, index) => {
                    return {
                        ..._gridData,
                        rowId: ((currentPage - 1) * 100) + (index + 1)

                    }
                }) || []}
                defaultFilters={activeFilters}
                applyRemoteFiltering={(filters) => {
                    setActiveFilters(filters)
                }}
                remoteFiltering={true}
                toolbarConfig={{
                    downloadLabel: "Download",
                    handleDownloadGrid
                }}
            />
            <Pagination
                currentPage={currentPage}
                pageCount={100}
                totalResults={totalResults}
                pageChange={handlePageChange}
            />
        </Box>
    )
}
export default ReconcilationGrid;