import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../../RoutesWithAuth';
import { CSSTransition } from 'react-transition-group';
import RequestsTab from './tabs/requests/RequestsTab'
import BulkUploadTab from './tabs/bulkUpload/BulkUploadTab';
import DailyFeedTab from './tabs/dailyFeed/DailyFeedTab';
import ReportsTab from './tabs/reports/ReportsTab';
import { withNavbar } from '../../common/Navbar';
import PageHeader from '../../common/PageHeader';
import { switchTabs } from '../../common/commons';


function Home(props) {
	const { routerProps, tab } = props
	const { history, match } = routerProps
	const { reportType } = match.params

	const context = useContext(AppContext)
	const authToken = context.authToken.get
	const isAdmin = context.isAdmin.get
	const userId = context.userId.get

	useEffect(() => {
		// Bulk tab is not available to non-admins
		if (tab === "bulk" && !isAdmin) { return }
		else if (tab) { switchTabs(tab) }
	// eslint-disable-next-line react-hooks/exhaustive-deps
},[tab, isAdmin])

	return (
		<div className="container">

			<PageHeader header="CT Consumer Privacy System"/>

			<ul className="nav nav-tabs" role="tablist">
				<li className="nav-item">
					<a className="nav-link active" id="requests-tab" data-toggle="tab" href="/" role="tab"
						aria-controls="requests" aria-selected="true" onClick={() => history.push("/")}
					>
						Requests
					</a>
				</li>
				{/* <li className="nav-item">
					<a className="nav-link" id="daily-tab" data-toggle="tab" href="/" role="tab"
						aria-controls="daily" aria-selected="false" onClick={() => history.push("/daily")}
					>
						Daily Feed
					</a>
				</li>
				{isAdmin ?
					<li className="nav-item">
						<a className="nav-link" id="bulk-tab" data-toggle="tab" href="/" role="tab"
							aria-controls="bulk" aria-selected="false" onClick={() => history.push("/bulk")}
						>
							Bulk Upload
					</a>
					</li>
					: null
				}
				<li className="nav-item">
					<a className="nav-link" id="reports-tab" data-toggle="tab" href="/" role="tab"
						aria-controls="reports" aria-selected="false" onClick={() => history.push("/reports")}
					>
						Reports
					</a>
				</li> */}
			</ul>

			<div className="tab-content">
				<CSSTransition in={tab === "requests"} timeout={250} classNames="fade">
					<RequestsTab routerProps={routerProps} authToken={authToken} tab={tab} />
				</CSSTransition>
				<CSSTransition in={tab === "daily"} timeout={250} classNames="fade">
					<DailyFeedTab authToken={authToken} isAdmin={isAdmin} />
				</CSSTransition>
				{isAdmin ?
					<CSSTransition in={tab === "bulk"} timeout={250} classNames="fade">
						<BulkUploadTab userId={userId} authToken={authToken} />
					</CSSTransition>
					: null
				}
				<CSSTransition in={tab === "reports"} timeout={250} classNames="fade">
					<ReportsTab authToken={authToken} initialReport={reportType} history={history} />
				</CSSTransition>
			</div>
		</div>
	);
}

export default withNavbar(Home, 1);
