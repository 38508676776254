import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import Papa from 'papaparse';
import moment from 'moment';
import querystring from 'querystring';
import axios from '../../../../common/AxiosConfig';
import Request from './Request';
import { removeFeedback, queryDefaults, parseBool, RequestorMapping } from '../../../../common/commons';


const useQuery = () => {
    return new URLSearchParams(useLocation().search)
}

const AdvanceSearchErrorDomId = 'adv-search-feedback';

function RequestsTab(props) {
    const { routerProps, authToken, tab } = props
    const { history, match } = routerProps
    const { page } = match.params

    const query = useQuery()
    const today = moment().format('YYYY-MM-DD')

    const currentPage = parseInt(page ? page : 1)

    const [requests, setRequests] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(null);
    const [pagination, setPagination] = useState([]);
    const [showAdvSearch, setShowAdvSearch] = useState(query.get('showAdvSearch') ? parseBool(query.get('showAdvSearch')) : false);
    const [searchQuery, setSearchQuery] = useState(query.get('searchQuery') || '');

    const [includeOpen, setIncludeOpen] = useState(query.get('includeOpen') ? parseBool(query.get('includeOpen')) : true);
    const [includeReview, setIncludeReview] = useState(query.get('includeReview') ? parseBool(query.get('includeReview')) : true);
    const [includeClosed, setIncludeClosed] = useState(query.get('includeClosed') ? parseBool(query.get('includeClosed')) : true);
    const [includeUploaded, setIncludeUploaded] = useState(query.get('includeUploaded') ? parseBool(query.get('includeUploaded')) : true);
    const [includeUploadFailed, setIncludeUploadFailed] = useState(query.get('includeUploadFailed') ? parseBool(query.get('includeUploadFailed')) : true);
    const [includeCancelled, setIncludeCancelled] = useState(query.get('includeCancelled') ? parseBool(query.get('includeCancelled')) : true);
    const [fromDate, setFromDate] = useState(query.get('fromDate') || '');
    const [toDate, setToDate] = useState(query.get('toDate') || '');
    const [searchCaliCust, setSearchCaliCust] = useState(query.get('searchCaliCust') || '');
    const [searchTicketType, setSearchTicketType] = useState(query.get('searchTicketType') || '');
    const [searchRequestContains, setSearchRequestContains] = useState(query.get('searchRequestContains') || '');
    const [searchResponseContains, setSearchResponseContains] = useState(query.get('searchResponseContains') || '');
    const [searchRequestor, setSearchRequestor] = useState(query.get('searchRequestor') || '');

    const [processing, setProcessing] = useState(false);

    const [toggleSwitch, setToggleSwitch] = useState(false);
    const [displayErrMsg, setDisplayErrMsg] = useState(false);

    useEffect(() => {
        if (authToken !== null) {
            // Get the ticket list
            searchRequests(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authToken, currentPage]);

    useEffect(() => {
        const pages = []
        for (let index = 0; index < totalPages; index++) {
            if (index + 1 === currentPage) {
                pages.push(
                    <li className="page-item disabled" key={index}><button className="page-link">{index + 1}</button></li>
                )
            } else {
                pages.push(
                    <Link to={`/page/${index + 1}`} key={index}>
                        <li className="page-item">
                            <button className="page-link">{index + 1}</button>
                        </li>
                    </Link>
                )
            }
        }

        setPagination(pages)
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[totalPages, currentPage])

    const searchRequests = (e, download_csv = false) => {
        if (e) {
            history.push('/')
            e.preventDefault()
        }
        if (download_csv) { setProcessing(true) }

        axios.get(`ticket/search`, {
            headers: {
                Authorization: authToken
            },
            params: {
                id: searchQuery.trim(),
                from_date: fromDate,
                to_date: toDate,
                include_open: includeOpen,
                include_under_review: includeReview,
                include_closed: includeClosed,
                include_uploaded: includeUploaded,
                include_upload_failed: includeUploadFailed,
                include_cancelled: includeCancelled,
                cali_customer: searchCaliCust,
                ticket_type: searchTicketType,
                requestor: searchRequestor,
                request_contains: searchRequestContains.trim(),
                response_contains: searchResponseContains.trim(),
                download_csv: download_csv,
                page: currentPage - 1,
            }
        }).then(res => {
            if (res.status === 204) {
                console.log('No search records found')
                setProcessing(false)
                setRequests([])
                setDisplayErrMsg(true)
                const feedback = document.getElementById(AdvanceSearchErrorDomId)
                if (feedback && download_csv === true) {
                    feedback.innerHTML = "No search records found"
                    removeFeedback(AdvanceSearchErrorDomId)
                }
            }
            else if (download_csv === true) {
                if (res.data.email_sent === "1") {
                    setProcessing(false);
                    const feedback = document.getElementById(AdvanceSearchErrorDomId)
                    if (feedback) {
                        feedback.innerHTML = res.data.message
                        removeFeedback(AdvanceSearchErrorDomId)
                    }
                } else if (res.data.includes("/ADVANCED_DOWNLOAD/")) {
                    const link = document.createElement('a');
                    link.href = res.data;
                    document.body.appendChild(link);
                    link.click();
                    link.remove()
                    setProcessing(false)
                }
                else {
                    // Don't update the state if download_csv is set to true
                    // Download the csv file
                    const csv = Papa.unparse(res.data)
                    createBlobAndDownload(csv)
                    setProcessing(false)
                }

            } else {
                window.scrollTo(0, 0)
                if (res.data[0]) {
                    setDisplayErrMsg(false)
                    setTotalPages(Math.ceil(res.data[0].total_results / 20))
                    setTotalResults(res.data[0].total_results)
                    setToggleSwitch(!toggleSwitch)
                } else {
                    setDisplayErrMsg(true)
                }
                setRequests(res.data)

                // Don't update the URL if we are redirecting to another tab
                if (tab !== "requests") { return }

                // Save the search query to the history, so the user can press the back button
                const queryParams = {}
                const allParams = {
                    searchQuery, fromDate, toDate, includeOpen, includeReview, includeClosed, showAdvSearch,
                    includeUploaded, includeUploadFailed, includeCancelled,
                    searchCaliCust, searchTicketType, searchRequestContains, searchResponseContains, searchRequestor
                }
                for (const [key, value] of Object.entries(allParams)) {
                    if (queryDefaults[key] !== value) { queryParams[key] = value }
                }
                history.push(`/page/${currentPage}?${querystring.stringify(queryParams)}`)
            }
        }).catch(err => {
            // Handle errors fetching data here
            console.log('There was an error fetching the data!', err)
            setProcessing(false)

            const feedback = document.getElementById(AdvanceSearchErrorDomId)
            if (download_csv && feedback) {
                feedback.innerHTML = "Error occured in fetching search records."
                removeFeedback(AdvanceSearchErrorDomId)
            }
        })
    }

    const createBlobAndDownload = (csv) => {
        const downloadLink = document.createElement("a");
        const blob = new Blob(["\ufeff", csv])
        const url = URL.createObjectURL(blob)
        downloadLink.href = url
        const timestamp = moment().format("YYYYMMDDHHmmss")
        downloadLink.download = `Search_Results_${timestamp}.csv`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
    }

    const setSimpleSearch = () => {
        setShowAdvSearch(false)
        setIncludeOpen(true)
        setIncludeReview(true)
        setIncludeClosed(true)
        setIncludeUploaded(true)
        setIncludeUploadFailed(true)
        setIncludeCancelled(true)
        setSearchRequestContains("")
        setSearchResponseContains("")
    }

    return (
        <div className="tab-pane show active" id="requests" role="tabpanel" aria-labelledby="requests-tab">
            <form className="mt-4" onSubmit={searchRequests}>
                <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search by DSAR ID..." value={searchQuery}
                        onChange={(e) => { setSearchQuery(e.target.value) }}
                    />
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary py-0" type="submit">
                            <i className="material-icons align-middle">search</i>
                        </button>
                    </div>
                </div>

                {showAdvSearch ?
                    <div className="bg-light pb-3 text-center">

                        <div className="text-right">
                            <button className="btn btn-link btn-sm text-secondary" onClick={setSimpleSearch}>
                                Simple Search
                            </button>
                        </div>

                        <div className="row mx-5">
                            <div className="form-group col-6">
                                <label htmlFor="CaliCust">California Customer</label>
                                <select className="form-control" id="CaliCust" value={searchCaliCust} onChange={e => setSearchCaliCust(e.target.value)}>
                                    <option value="">All</option>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </select>
                            </div>

                            <div className="form-group col-6">
                                <label htmlFor="ticketType">Request Type</label>
                                <select className="form-control" id="ticketType" value={searchTicketType} onChange={e => setSearchTicketType(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="Right-to-Correct">Right to Correct</option>
                                    <option value="Right-to-Delete">Right to Delete</option>
                                    <option value="Right-to-Know-Detailed">Right to Know</option>
                                    <option value="Right-to-OptOut">Right to Opt-Out</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mx-5">
                            <div className="form-group col-6">
                                <label htmlFor="searchRequestor">Requestor</label>
                                <select className="form-control" id="searchRequestor" value={searchRequestor} onChange={e => setSearchRequestor(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="B2B">B2B</option>
                                    <option value="Customer">Customer</option>
                                    <option value="Employee">Employee</option>
                                    <option value={RequestorMapping.TCICUSTOMER}>{RequestorMapping.TCICUSTOMER}</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mx-5">
                            <div className="form-group col">
                                <label htmlFor="requestContains" className="ml-1">Request Contains</label>
                                <input className="form-control" type="text" id="requestContains" maxLength="50"
                                    value={searchRequestContains} onChange={e => setSearchRequestContains(e.target.value)}
                                />
                            </div>
                            <div className="form-group col">
                                <label htmlFor="requestContains" className="ml-1">Response Contains</label>
                                <input className="form-control" type="text" id="responseContains" maxLength="50"
                                    value={searchResponseContains} onChange={e => setSearchResponseContains(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="row mx-5">
                            <div className="form-group col-6">
                                <label htmlFor="fromDate">From Date</label>
                                <input type="date" className="form-control" id="fromDate" min="2020-01-01" max={today}
                                    value={fromDate} onChange={e => setFromDate(e.target.value)}
                                />
                            </div>
                            <div className="form-group col-6">
                                <label htmlFor="toDate">To Date</label>
                                <input type="date" className="form-control" id="toDate" min={fromDate} max={today}
                                    value={toDate} onChange={e => setToDate(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group mt-3 mb-4">
                            Request Status:
                            <div className="form-check form-check-inline ml-3">
                                <input className="form-check-input" type="checkbox" id="includeOpen"
                                    checked={includeOpen} onChange={e => setIncludeOpen(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="includeOpen">Open</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="includeReview"
                                    checked={includeReview} onChange={e => setIncludeReview(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="includeReview">Review</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="includeClosed"
                                    checked={includeClosed} onChange={e => setIncludeClosed(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="includeClosed">Closed</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="includeUploaded"
                                    checked={includeUploaded} onChange={e => setIncludeUploaded(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="includeUploaded">Uploaded</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="includeUploadFailed"
                                    checked={includeUploadFailed} onChange={e => setIncludeUploadFailed(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="includeUploadFailed">Upload Failed</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="checkbox" id="includeCancelled"
                                    checked={includeCancelled} onChange={e => setIncludeCancelled(e.target.checked)}
                                />
                                <label className="form-check-label" htmlFor="includeCancelled">Cancelled</label>
                            </div>
                        </div>

                        <button className="btn btn-primary mr-2" type="submit">Search</button>
                        <button className="btn btn-primary" type="button" disabled={processing} onClick={e => searchRequests(e, true)} >
                            {processing ? <span className="spinner-border spinner-border-sm mr-2"></span> : null}
                            {processing ? "Processing" : "Download Results"}
                        </button>

                        <div className="mt-2 text-center text-danger small" id={AdvanceSearchErrorDomId}></div>

                    </div>
                    :
                    <div className="text-right">
                        <button className="btn btn-link btn-sm text-secondary" onClick={() => setShowAdvSearch(true)}>
                            Advanced Search
                        </button>
                    </div>
                }
            </form>

            <div>
                {requests.length > 0 ?
                    <div className="mb-4">
                        <p className="mb-2 mt-1">
                            <small>Total Results: {totalResults}</small>
                        </p>
                        <SwitchTransition>
                            <CSSTransition key={toggleSwitch} timeout={250} classNames="swipeR" appear={true}>
                                <div>
                                    {requests.map(data =>
                                        <Request key={data.dsar_id} data={data.dsar}
                                            history={history} status={data.status}
                                        />
                                    )}
                                </div>
                            </CSSTransition>
                        </SwitchTransition>
                        <nav>
                            <ul className="pagination justify-content-center">
                                {(totalPages > 1 && totalPages <= 5) ?
                                    pagination.map(element => element)
                                    :
                                    null
                                }
                                {totalPages > 5 ?
                                    <ul className="pagination justify-content-center">
                                        <Link to={`/page/1`}>
                                            <li className="page-item mr-2">
                                                <button className="page-link">First</button>
                                            </li>
                                        </Link>
                                        {pagination.map((element, index) => {
                                            if (currentPage > 3 && currentPage <= totalPages - 3) {
                                                if (index >= currentPage - 3 && index <= currentPage + 1) {
                                                    return element
                                                }
                                            } else if (currentPage <= 3 && index <= 4) {
                                                return element
                                            } else if (currentPage >= totalPages - 2 && index >= totalPages - 5) {
                                                return element
                                            }
                                            return null;
                                        })}
                                        <Link to={`/page/${totalPages}`}>
                                            <li className="page-item ml-2">
                                                <button className="page-link">Last</button>
                                            </li>
                                        </Link>
                                    </ul>
                                    : null
                                }
                            </ul>
                        </nav>
                    </div>
                    :
                    null
                }
                {displayErrMsg ?
                    <CSSTransition in={displayErrMsg} timeout={125} classNames="swipeD" appear={true}>
                        <div>
                            <p className="h3 text-center mt-4" id="ticketNotFound">No requests found!</p>
                            <p className="text-center text-muted">Please refine your search criteria for better results.</p>
                        </div>
                    </CSSTransition>
                    :
                    null
                }
            </div>
        </div>
    )
}

export default RequestsTab;
