import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { removeFeedback } from '../../../../common/commons';
import axios from '../../../../common/AxiosConfig';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UncontrolledTooltip } from 'reactstrap';

const errorFeedBackDomId = 'error-feedback';
function ReconciliationReport(props) {
    const { authToken } = props
    const [lastGenerated, setLastGenerated] = useState(null)
    const [reconReport, setReconReport] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (authToken) {
            axios.get(`reports/reconciliation`, {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setLastGenerated(res.data.last_modified)
            }).catch(err => {
                console.log(err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken])

    useEffect(() => {
        if (authToken) {
            setLoading(true);
            axios.get('reports/reconciliation/get_tips_reconciliation_reports', {
                headers: {
                    Authorization: authToken
                }
            }).then(res => {
                setLoading(false);
                setReconReport(res.data.tips_reconciliation_files)
            }).catch(err => {
                setLoading(false);
                console.log(err)
            })
        }
  // eslint-disable-next-line react-hooks/exhaustive-deps
},[authToken])

    const download = () => {
        const error = document.getElementById(errorFeedBackDomId)

        axios.get(`reports/reconciliation/download`, {
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            const link = document.createElement('a');
            link.href = res.data.presignedGet;
            link.setAttribute('download', `${res.data.filename}`);
            document.body.appendChild(link);
            link.click();
            link.remove()
        }).catch(err => {
            error.innerHTML = "Could not download file!"
        }).finally(removeFeedback(errorFeedBackDomId))
    }

    const downloadFile = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${url}`);
        document.body.appendChild(link);
        link.click();
        link.remove()
    }

    const regenerate = () => {
        const success = document.getElementById("success-feedback")
        const error = document.getElementById(errorFeedBackDomId)

        axios.post(`reports/reconciliation/regenerate`, {}, {
            headers: { Authorization: authToken }
        }).then(res => {
            setLastGenerated(moment())
            success.innerHTML = res.data.message
        }).catch(err => {
            error.innerHTML = "Could not regenerate file!"
        }).finally(() => {
            removeFeedback(errorFeedBackDomId)
            removeFeedback("success-feedback")
        })
    }
    if (loading) {
        return (<div class="d-flex justify-content-center">
            <span className="loading-spin material-icons"> rotate_right</span>
        </div>)
    }
    return (
        <div className="mb-5">

            <h4 className="text-center mt-4">TIPS Reconciliation Report</h4>

            <table className="table table-striped table-sm">
                <thead>
                    <tr className="text-center">
                        <th scope="col">#</th>
                        <th scope="col">File Name</th>
                    </tr>
                </thead>

                <CSSTransition in={reconReport !== 0} appear={true} timeout={125} classNames="swipeD">
                    <tbody className="text-center">
                        {reconReport.map((request, i) => (
                            <tr key={request.file_name}>
                                <td>{i + 1}</td>
                                <td><Link onClick={() => downloadFile(request.file_url)} download>{request.file_name}</Link></td>
                            </tr>
                        ))}
                    </tbody>
                </CSSTransition>

            </table>

            <h4 className="text-center mt-4">CPS Reconciliation Report</h4>

            <CSSTransition in={lastGenerated !== null} appear={true} timeout={125} classNames="swipeD">
                {lastGenerated ?
                    <p className="text-center">
                        Generated On:&nbsp;
                        <span>
                            <span id="reconciliation-date">
                                {moment(lastGenerated).utc().format("MMM Do, YYYY HH:mm:ss (UTC)")}
                            </span>
                            <UncontrolledTooltip placement="bottom" target="reconciliation-date">
                                {moment(lastGenerated).local().format('MMM Do, YYYY HH:mm:ss [(local)]')}
                            </UncontrolledTooltip>
                        </span>
                    </p>
                    :
                    <p>&nbsp;</p>
                }
            </CSSTransition>

            <p className="text-center">
                <button type="button" className="btn btn-primary mr-3" onClick={download}>
                    Download
                </button>
                <button type="button" className="btn btn-primary" onClick={regenerate}>
                    Regenerate
                </button>
            </p>

            <p className="text-center small text-danger" id={errorFeedBackDomId}></p>
            <p className="text-center small text-success" id="success-feedback"></p>
        </div>
    );
}

export default ReconciliationReport;
