/**
 * Error Report Main index file which is refered in reports Page
 */
import { Snackbar, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from '../../../../RoutesWithAuth';
import moment from 'moment';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { v2UIRoutePath } from '../../../common/commons';
import { LoadingIndicator, Tab, Tabs } from './reconcilationreport/MuiStyled';
import DataGrid from '../grid';
import axios from '../../../common/AxiosConfig';
import ReportPopup from './ReportCommonPopup';
import { Alert } from '@material-ui/lab';
import Papa from 'papaparse';
import Dialog from '../Dialog';

// Custom Cell Render which displays the link when there is any value.
const customDownLoadRenderCell = (params) => {
    // return Clickable button when there is any value available.
    return params.value > 0 ?
        <button className="btngridcel btn btn-link p-0">{params.value}</button>
        : params.value
};
// Common columns which are used in both the Downstream and Upstream tabs.
const COLUMNS = [{
    name: "App Name",
    mapping: "name",
    width: 200,
    disableColumnMenu: true
}, {
    name: 'ISR',
    mapping: "isr",
    ticketTypeMapping: "ISR",
    disableColumnMenu: true,
    columnMappingIndex: 0,
    width: 140,
    renderCell: customDownLoadRenderCell
},{
    name: 'Right to Correct',
    mapping: "rtc",
    ticketTypeMapping: "Right-to-Correct",
    disableColumnMenu: true,
    columnMappingIndex: 1,
    width: 140,
    renderCell: customDownLoadRenderCell
},{
    name: 'Right to Create',
    mapping: "rtcr",
    ticketTypeMapping: "Right-to-Create",
    disableColumnMenu: true,
    columnMappingIndex: 2,
    width: 140,
    renderCell: customDownLoadRenderCell
}, {
    name: 'Right to Delete',
    mapping: "rtd",
    ticketTypeMapping: "Right-to-Delete",
    disableColumnMenu: true,
    columnMappingIndex: 3,
    width: 140,
    renderCell: customDownLoadRenderCell
}, {
    name: 'Right to Know',
    mapping: "rtk",
    ticketTypeMapping: "Right-to-Know-Detailed",
    disableColumnMenu: true,
    columnMappingIndex: 4,
    width: 140,
    renderCell: customDownLoadRenderCell
}, {
    name: "Right to Opt-Out",
    mapping: "rtoo",
    ticketTypeMapping: "Right-to-OptOut",
    disableColumnMenu: true,
    columnMappingIndex: 5,
    width: 140,
    renderCell: customDownLoadRenderCell
}, {
    name: 'Right to Terminate',
    mapping: "rtt",
    ticketTypeMapping: "Right-to-Terminate",
    disableColumnMenu: true,
    columnMappingIndex: 6,
    width: 140,
    renderCell: customDownLoadRenderCell
},{
    name: "Totals",
    mapping: "total",
    ticketTypeMapping: "all",
    disableColumnMenu: true,
    columnMappingIndex: 7,
    width: 100,
    renderCell: customDownLoadRenderCell
}]
// Custom page Level Styling
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        height: '100%'
    },
    accordianTitle: {
        fontWeight: 'bold'
    },
    dialogWrapper: {
        width: '860px'
    },
    dialogheader: {
        textAlign: 'center',
        fontSize: '0.75rem',
        '& span': {
            fontWeight: 'bold'
        },
        '& .modelheader': {
            paddingBottom: '5px',
            fontWeight: 'bold'
        }
    }
}));
const requestTabs = ["", "upstream"];

const ErrorReport = ({ tabActiveParam }) => {
    const classes = useStyles();
    // State to handle the Main Tab Information
    const [activeTab, setActiveTab] = useState(0);
    // State to handle the Model Popup status
    const [openModel, setOpenModel] = useState(false);
    const [errorMetrics, setErrorMetrics] = useState([]);
    // State to handle the Model Popup grid Data
    const [modelData, setModelData] = useState([]);
    const [dialogAppName, setDialogAppName] = useState("");
    const [displayErrorMsg, setDisplayErrorMsg] = useState("");
    // State to handle the Total Count in the UI.
    const [totalCount,setTotalCount] = useState("");
    const history = useHistory();
    const location = useLocation();
    // State to handle the Progress status
    const [isInProgress, setIsInProgress] = useState(false);

    const context = useContext(AppContext)
    const authToken = context.authToken.get

    useEffect(() => {
        // Activating the Tab based on the tab param in the route url
        let activeTabIndex = requestTabs.indexOf(tabActiveParam);
        // activating the first tab when the tab name does not match the list
        if (activeTabIndex < 0) {
            activeTabIndex = 0;
        }
        setActiveTab(activeTabIndex);
        fetchData(activeTabIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabActiveParam, location?.state?.fromredirection])

    const fetchData = (fallbackIndex) => {
        setIsInProgress(true)
        let activeTabIndex = activeTab;
        if (fallbackIndex) {
            activeTabIndex = fallbackIndex
        }
        axios.get("/reports/error", {
            params: {
                is_upstream: activeTabIndex === 1
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            let error_report = res.data.error_report;
            error_report.sort(function (a, b) {
                const x = a.name.toLowerCase();
                const y = b.name.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            });
            let totalErrorReports = 0;
            // Formating the report based on the Grid Required format
            error_report = error_report?.map((_error_report, index) => {
                totalErrorReports += _error_report.data[7];
                return {
                    ..._error_report,
                    isr: _error_report.data[0],
                    rtc: _error_report.data[1],
                    rtcr: _error_report.data[2],
                    rtd: _error_report.data[3],
                    rtk: _error_report.data[4],
                    rtoo: _error_report.data[5],
                    rtt: _error_report.data[6],
                    total: _error_report.data[7],
                    id: index + 1
                }
            })

            setErrorMetrics(error_report)
            setTotalCount(totalErrorReports);
            setIsInProgress(false)
        }).catch(err => {
            setIsInProgress(false)
            setTotalCount(0);
        })
    }
    // Method calls the API and creates the csv downlodable format
    const handleDownloadGrid = () => {
        setIsInProgress(true)
        axios.get('/reports/error/download', {
            headers: {
                Authorization: authToken
            },
            params: {
                is_upstream: activeTab === 1
            }
        }).then(res => {
            setIsInProgress(false);
            if (res.data.error_dsar_ids.length) {
                // Creating the CSV related file format
              
                
                const downloadLink = document.createElement("a");
                const csv = Papa.unparse(res.data.error_dsar_ids)
                const blob = new Blob(["\ufeff", csv])
                const url = URL.createObjectURL(blob)
                downloadLink.href = url
                // Formating the CSV File Name
                const timestamp = moment().format("YYYYMMDDHHmm")
                let tab_name = activeTab === 1 ? "Upstream" : "Downstream";
                downloadLink.download = `CPS_${tab_name}_Error_Report_${timestamp}.csv`
                document.body.appendChild(downloadLink)
                downloadLink.click()
                document.body.removeChild(downloadLink)
            } else {
                // Displaying the Error Message when there is no Data from the API
                setDisplayErrorMsg('There are no DSAR Request IDs!')
            }
        }).catch(err => {
            setIsInProgress(false);
            setDisplayErrorMsg(err.response.data.message)
        })
    }
    // Method is called when user clicks on the column link.
    // Triggers the API based on the triggered column and stores the information in state
    const downloadPopModelData = (row, ticketTypeMapping) => {
        setIsInProgress(true)
        axios.get("/reports/error/view_detailed", {
            params: {
                is_upstream: activeTab === 1,
                app_name: row.name,
                ticket_type: ticketTypeMapping
            },
            headers: {
                Authorization: authToken
            }
        }).then(res => {
            setOpenModel(true);
            setModelData(res?.data?.message);
            setDialogAppName(row?.name);
            setIsInProgress(false)
        }).catch(err => {
            setIsInProgress(false)
        })
    }
    const handleClose = () => {
        setDisplayErrorMsg("");
    }
    return (
        <div className={classes.root}>
            {isInProgress && (<>
                <LoadingIndicator />
            </>)}
            <Snackbar open={!!displayErrorMsg} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {displayErrorMsg}
                </Alert>
            </Snackbar>

            <Typography variant="h6" className={'tabHeader'}>
                <Link color="primary" to={() => {
                    // Generating a Random Id and passing it as part of state 
                    // Every click on the Header will generate a unique Id
                    const autoId = Math.round(Math.random() * 1e5);
                    let subTab = "";
                    // Maintaining the active Tab State.
                    if (activeTab === 1) {
                        subTab = "upstream"
                    }
                    return {
                        pathname: v2UIRoutePath + "reports/errorreport/" + subTab,
                        state: { fromredirection: autoId }
                    }
                }}
                >
                    ERROR REPORT
                </Link>
            </Typography>
            <Tabs
                variant="scrollable"
                aria-label="tabs"
                value={activeTab}
                onChange={(_event, newValue) => {
                    setActiveTab(newValue);
                    // redirecting to the selected tab url so that refresh will retain in same page
                    history.push(`${v2UIRoutePath}reports/errorreport/${requestTabs[newValue]}`)

                }}
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                <Tab label="Downstreams" />
                <Tab label="Upstreams" />
            </Tabs>
            <DataGrid
                columns={COLUMNS}
                onCellClick={(cellparams) => {
                    if (cellparams.colDef.columnMappingIndex >= 0 && cellparams.formattedValue > 0) {
                        downloadPopModelData(cellparams.row, cellparams.colDef.ticketTypeMapping)
                    }
                }}
                rows={errorMetrics || []}
                toolbarConfig={{
                    downloadLabel: "Download",
                    handleDownloadGrid,
                    leftContent : ()=>{
                        return `Total Count : ${totalCount}`
                    }
                }}
            />
            <>
            {openModel && <Dialog
            isOpen={true}
            updateDialogStatus={() => {
                setOpenModel(false);
            }}
            dialogContent={<ReportPopup modelData={modelData} />}
            title={(
                <div className={classes.dialogheader}>
                <div className='modelheader'> Error Requests</div>
                <>
                    <span> {activeTab === 1 ? "Upstream" : "Downstream"} </span> : {dialogAppName}
                </>
            </div>
            )}
        />}
               
            </>
        </div>
    )
};

export default ErrorReport;